/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RequestState } from '../../domain/request'
// eslint-disable-next-line import/no-cycle
import { AppThunkConfig } from '../../store'
import { purge } from '../../store/action'
import AuthenticatedApi from '../services/authenticatedApi'
import { TenantStatusEnum } from '../services/api'

type EditTenantsState = Omit<RequestState, 'status'> & {
  patchStatus: RequestState['status']
  postStatus: RequestState['status']
}

export const initialState: EditTenantsState = {
  patchStatus: 'idle',
  postStatus: 'idle',
}

export type CreateTenant = {
  name: string
  code: string
  status: TenantStatusEnum
}

export type PostTenantsParam = {
  orgCode: string
  storeCode: string
  tenants: CreateTenant[]
}

export const postTenants = createAsyncThunk<
  void,
  PostTenantsParam,
  AppThunkConfig
>('editTenants/postTenants', async (params, { getState }) => {
  const { auth } = getState()
  await new AuthenticatedApi(auth.token).postTenants(
    params.orgCode,
    params.storeCode,
    params.tenants
  )
})

export type UpdateTenant = {
  id: string
  name?: string
  code?: string
  status?: TenantStatusEnum
  is_ocr_required?: boolean
  is_qr_printed?: boolean
}

export type PatchTenantsParam = {
  orgCode: string
  tenants: UpdateTenant[]
}

export const patchTenants = createAsyncThunk<
  void,
  PatchTenantsParam,
  AppThunkConfig
>('editTenants/patchTenants', async (params, { getState }) => {
  const { auth } = getState()
  await new AuthenticatedApi(
    auth.token
  ).patchOrganizationsOrganizationCodeTenants(params.orgCode, params.tenants)
})

const slice = createSlice({
  name: 'editTenants',
  initialState,
  reducers: {
    clearEditTenants: (): EditTenantsState => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(postTenants.pending, (state) => {
      return {
        ...state,
        postStatus: 'loading',
      }
    })
    builder.addCase(postTenants.fulfilled, (state) => {
      return {
        ...state,
        postStatus: 'succeeded',
      }
    })
    builder.addCase(postTenants.rejected, (state) => {
      return {
        ...state,
        postStatus: 'failed',
      }
    })
    builder.addCase(patchTenants.pending, (state) => {
      return {
        ...state,
        patchStatus: 'loading',
      }
    })
    builder.addCase(patchTenants.fulfilled, (state) => {
      return {
        ...state,
        patchStatus: 'succeeded',
      }
    })
    builder.addCase(patchTenants.rejected, (state) => {
      return {
        ...state,
        patchStatus: 'failed',
      }
    })
  },
})

export const { clearEditTenants } = slice.actions
export default slice.reducer
