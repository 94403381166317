import React from 'react'
import { UsePaginationItem } from '@mui/lab'
import {
  Button,
  Pagination as MaterialPagination,
  PaginationItem,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import colors from '../../../styles/color'

export type PaginationProps = {
  currentPage: number
  pageCount: number
  disabled?: boolean
  onClick: (page: number) => void
} & React.StyledProps

const useStyles = makeStyles({
  ul: {
    justifyContent: 'center',
  },
  previous: {
    minWidth: 'auto',
    padding: '5px 10px',
    marginRight: 16,
    '&:disabled': {
      borderColor: colors.lightGray300,
      color: colors.black300,
    },
  },
  next: {
    minWidth: 'auto',
    padding: '5px 10px',
    marginLeft: 16,
    '&:disabled': {
      borderColor: colors.lightGray300,
      color: colors.black300,
    },
  },
  buttonLabel: {
    borderColor: colors.black300,
    color: colors.black,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '14px',
    '&:hover': {
      borderColor: colors.black300,
    },
  },
  page: {
    minWidth: 'auto',
    padding: '5px 8px',
    margin: '0 4px',
  },
})

const Pagination: React.StyledFC<PaginationProps> = ({
  className,
  currentPage,
  pageCount,
  disabled,
  onClick,
}: PaginationProps) => {
  const classes = useStyles()

  const renderPaginationItem = (item: UsePaginationItem) => {
    switch (item.type) {
      case 'next':
      case 'previous':
        return (
          <Button
            data-testid={item.type}
            classes={{ root: clsx(classes[item.type], classes.buttonLabel) }}
            type="button"
            variant="outlined"
            disabled={item.disabled || disabled}
            onClick={item.onClick}
          >
            {item.type === 'next' ? '次へ' : '前へ'}
          </Button>
        )
      case 'page':
        return (
          <Button
            data-testid={`page-${item.page}`}
            classes={{
              root: clsx(classes.page, classes.buttonLabel),
            }}
            type="button"
            variant={item.selected ? 'text' : 'outlined'}
            disabled={disabled}
            onClick={item.onClick}
          >
            {item.page}
          </Button>
        )
      default:
        return <PaginationItem type={item.type} />
    }
  }

  return (
    <MaterialPagination
      classes={{ root: className, ul: classes.ul }}
      count={pageCount}
      page={currentPage}
      renderItem={(item) => renderPaginationItem(item)}
      boundaryCount={2}
      siblingCount={1}
      onChange={(_, next) => onClick(next)}
    />
  )
}

export default Pagination
