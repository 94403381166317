import { fullCharacterIncludes } from 'src/utils/string'
import { ConsistencyEnum } from 'src/domain/consistency'
import {
  ReportStatusEnum,
  TenantSalesReportSummary,
} from '../../slices/services/api'
import { CheckStatusList, FilterParamType } from './type'

type Payload = {
  allSummaries?: TenantSalesReportSummary[]
  filterParam?: FilterParamType
  checkedTenantCode?: string
}

export type Action = {
  type:
    | 'UPDATE_SUMMARIES'
    | 'UPDATE_FILTER_PARAM'
    | 'UPDATE_CHECK_STATUS'
    | 'UPDATE_ALL_CHECK_STATUS'
    | 'RESET_SUMMARIES'
  payload?: Payload
}

export type TenantSalesReportSummariesState = {
  allSummaries: TenantSalesReportSummary[]
  filterParam: FilterParamType
  filteredSummaries: TenantSalesReportSummary[]
  isFilterRemovedAll: boolean
  isAllChecked: boolean
  checkStatusList: CheckStatusList
  checkCount: number
  checkSalesDateIds: string[]
  syncCount: number
  firstCheckedTenantCode: string | null
}

export const INITIAL_STATE: TenantSalesReportSummariesState = {
  allSummaries: [],
  filterParam: {
    reportStatus: [],
    searchText: '',
    memo: [],
    consistency: [],
  },
  filteredSummaries: [],
  isFilterRemovedAll: false,
  isAllChecked: false,
  checkStatusList: {},
  checkCount: 0,
  checkSalesDateIds: [], // 売上報告日(salesDate)のid
  syncCount: 0,
  firstCheckedTenantCode: null,
}

const getCount = (checkStatusList: CheckStatusList) => {
  return Object.values(checkStatusList).filter((val) => val).length
}

const getSyncCount = (
  checkStatusList: CheckStatusList,
  summaries: TenantSalesReportSummary[]
) => {
  let syncCount = 0
  Object.keys(checkStatusList).forEach((statusCode) => {
    if (checkStatusList[statusCode]) {
      const foundSalesDate = summaries.find(
        (summary) => summary.tenant.code === statusCode
      )
      if (
        foundSalesDate?.reportStatus ===
        ReportStatusEnum.FinalizedReportSubmitted
      ) {
        syncCount += 1
      }
    }
  })
  return syncCount
}

const getCheckSalesDateIds = (
  checkStatusList: CheckStatusList,
  summaries: TenantSalesReportSummary[]
) => {
  const checkSalesDateIds: string[] = []
  Object.entries(checkStatusList).forEach(([tenantCode, isChecked]) => {
    const foundSalesDate = summaries.find(
      (summary) => summary.tenant.code === tenantCode && isChecked
    )
    if (foundSalesDate !== undefined) {
      checkSalesDateIds.push(foundSalesDate.id)
    }
  })
  return checkSalesDateIds
}

export const reducerFunc = (
  state: TenantSalesReportSummariesState,
  action: Action
): TenantSalesReportSummariesState => {
  let newState: TenantSalesReportSummariesState

  switch (action.type) {
    case 'UPDATE_SUMMARIES':
      newState = {
        ...state,
        allSummaries: action.payload?.allSummaries ?? [],
        filteredSummaries: [],
      } // filterParam は新しいデータが降ってきても変更しない。
      break
    case 'RESET_SUMMARIES':
      newState = {
        ...state,
        allSummaries: [],
        filteredSummaries: [],
        isAllChecked: false,
        checkStatusList: {},
        checkCount: 0,
        checkSalesDateIds: [],
        syncCount: 0,
      }
      break
    case 'UPDATE_FILTER_PARAM':
      newState = {
        ...state,
        filterParam: action.payload?.filterParam || INITIAL_STATE.filterParam,
        filteredSummaries: [],
      }
      break
    case 'UPDATE_CHECK_STATUS':
      if (action.payload?.checkedTenantCode) {
        const checkStatusList = {
          ...state.checkStatusList,
          [action.payload.checkedTenantCode]:
            !state.checkStatusList[action.payload.checkedTenantCode],
        }

        newState = {
          ...state,
          isAllChecked: false,
          checkStatusList,
          checkCount: getCount(checkStatusList),
          checkSalesDateIds: getCheckSalesDateIds(
            checkStatusList,
            state.allSummaries
          ),
          syncCount: getSyncCount(checkStatusList, state.allSummaries),
          filteredSummaries: [],
        }
      } else {
        newState = { ...state, filteredSummaries: [] }
      }
      break
    case 'UPDATE_ALL_CHECK_STATUS': {
      const newIsCheckAll = !state.isAllChecked
      const newAddedCheckStatusList: CheckStatusList = {}
      const excludeStatuses = [
        ReportStatusEnum.Waiting,
        ReportStatusEnum.LeftHalfway,
      ]
      state.filteredSummaries.forEach((summary) => {
        if (!excludeStatuses.includes(summary.reportStatus)) {
          newAddedCheckStatusList[summary.tenant.code] = newIsCheckAll
        }
      })
      const checkStatusList = {
        ...state.checkStatusList,
        ...newAddedCheckStatusList,
      }
      newState = {
        ...state,
        isAllChecked: newIsCheckAll,
        checkStatusList,
        checkCount: getCount(checkStatusList),
        checkSalesDateIds: getCheckSalesDateIds(
          checkStatusList,
          state.allSummaries
        ),
        syncCount: getSyncCount(checkStatusList, state.allSummaries),
        filteredSummaries: [],
      }
      break
    }
    default:
      newState = {
        ...state,
        filteredSummaries: [],
      }
  }

  // filterはどのActionでも実行するので、case文の外で実行する。
  newState.allSummaries.forEach((summary) => {
    if (!newState.filterParam.searchText) {
      newState.filteredSummaries.push(summary)
    } else if (
      fullCharacterIncludes(
        newState.filterParam.searchText,
        summary.tenant.code,
        summary.tenant.name
      )
    ) {
      newState.filteredSummaries.push(summary)
    }
  })

  if (newState.filterParam.reportStatus.length > 0) {
    newState.filteredSummaries = newState.filteredSummaries.filter((summary) =>
      newState.filterParam.reportStatus.includes(summary.reportStatus)
    )
  }
  if (newState.filterParam.memo.length > 0) {
    newState.filteredSummaries = newState.filteredSummaries.filter(
      (summary) => {
        if (
          newState.filterParam.memo.includes('reportMemo') &&
          Boolean(summary.reportMessage)
        ) {
          return true
        }
        if (
          newState.filterParam.memo.includes('manageMemo') &&
          Boolean(summary.manageMessage)
        ) {
          return true
        }
        return false
      }
    )
  }

  if (newState.filterParam.consistency.length > 0) {
    newState.filteredSummaries = newState.filteredSummaries.filter(
      (summary) => {
        // NOTE: 報告待ちの場合は表示しない
        if (summary.reportStatus === ReportStatusEnum.Waiting) {
          return false
        }

        // NOTE: consistency不一致
        if (
          newState.filterParam.consistency.includes(ConsistencyEnum.misMatch) &&
          !summary.extractedConsistencyResult
        ) {
          return true
        }

        // NOTE: consistency一致(すべて0含まない)
        if (
          newState.filterParam.consistency.includes(
            ConsistencyEnum.allMatchExcludingAllZero
          ) &&
          summary.extractedConsistencyResult &&
          !summary.isAllManualInputValuesZero
        ) {
          return true
        }

        // NOTE: consistency一致(すべて0含む)
        if (
          newState.filterParam.consistency.includes(
            ConsistencyEnum.allMatchIncludingAllZero
          ) &&
          summary.extractedConsistencyResult &&
          summary.isAllManualInputValuesZero
        ) {
          return true
        }
        return false
      }
    )
  }

  const firstTenantCode =
    newState.allSummaries
      .filter((summary) => newState.checkStatusList[summary.tenant.code])
      .map((summary) => summary.tenant.code)[0] ?? null

  newState.firstCheckedTenantCode = firstTenantCode

  return newState
}
