import { Path as BreadcrumbsPath } from '../../components/molecules/Breadcrumbs'
import { TabOption } from '../../components/molecules/Tabs'
import Path from '../../routes/path'
import { RoleEnum } from '../../slices/services/api'
import {
  AdminAdminSalesReportManagerEditorSalesReportManagerEditorStoreManagerInOperationCenterRoles,
  isOneOfRoles,
} from '../../domain/role'

interface StorePresenter {
  breadcrumbs(orgCode: string, storeName: string | undefined): BreadcrumbsPath[]
  tabList(orgCode: string, role?: RoleEnum): TabOption[]
}

const Presenter: StorePresenter = {
  breadcrumbs(
    orgCode: string,
    storeName: string | undefined
  ): BreadcrumbsPath[] {
    const root = {
      index: 0,
      title: '店舗一覧',
      to: Path.stores(orgCode),
    }
    const paths = [root]
    if (storeName) {
      paths.push({
        index: 1,
        title: storeName,
        to: '', // リンクを作成しないために空文字
      })
    }
    return paths
  },
  tabList(orgCode: string, role?: RoleEnum): TabOption[] {
    if (
      isOneOfRoles(
        AdminAdminSalesReportManagerEditorSalesReportManagerEditorStoreManagerInOperationCenterRoles,
        role
      )
    ) {
      const tabs = [
        { title: 'テナント一覧', index: 0 },
        { title: '売上報告', index: 1 },
      ]
      return tabs
    }
    return []
  },
}

export default Presenter
