import React from 'react'
import { TableCell } from '@mui/material'
import clsx from 'clsx'

import CheckBox from '../../../atoms/CheckBox'
import cellStyles from '../styles.module.scss'

export type CheckBoxCellProps = {
  isChecked: boolean
} & React.StyledProps

export const CheckBoxCell = React.memo<CheckBoxCellProps>(
  ({ className, style, isChecked }: CheckBoxCellProps) => {
    return (
      <TableCell className={clsx(className, cellStyles.cell)} style={style}>
        <CheckBox
          isChecked={isChecked}
          shape="rectangle"
          disableRipple
          disabled
        />
      </TableCell>
    )
  }
)

CheckBoxCell.displayName = 'CheckBoxCell'
