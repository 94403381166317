import React from 'react'
import { Box, Divider, List, ListSubheader, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import color from '../../../styles/color'
import TenantListItem, { TenantListItemProps } from '../TenantListItem'

export type TenantListProps = {
  tenants: Tenant[]
  useStoreName: boolean
} & React.StyledProps

export type Tenant = {
  name: string
  code: string
  status: TenantListItemProps['status']
  storeName?: string
  trainedAt?: string
  to: string
}

const useStyles = makeStyles({
  header: {
    display: 'grid',
    justifyContent: 'space-between',
    padding: '0 14px 0 62px',
    color: color.black400,
    gridTemplateColumns: '2fr 3fr',
    lineHeight: '19px',
  },
  leftColumn: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  text: {
    textAlign: 'left',
  },
  divider: {
    marginLeft: 55,
  },
})

const TenantList: React.StyledFC<TenantListProps> = ({
  className,
  useStoreName,
  tenants,
}: TenantListProps) => {
  const classes = useStyles()

  return (
    <List
      className={className}
      subheader={
        <ListSubheader className={classes.header} disableSticky>
          <Typography variant="h6" component="span">
            テナント名称
          </Typography>
          <Box className={classes.leftColumn}>
            <Typography className={classes.text} variant="h6" component="span">
              {useStoreName ? '店舗' : ''}
            </Typography>
            <Typography className={classes.text} variant="h6" component="span">
              練習モード最終実施日時
            </Typography>
          </Box>
        </ListSubheader>
      }
    >
      {tenants.map((item) => {
        return (
          <React.Fragment key={item.code}>
            <TenantListItem
              tenantName={item.name}
              tenantCode={item.code}
              status={item.status}
              storeName={
                useStoreName && item.storeName ? item.storeName : undefined
              }
              trainedAt={item.trainedAt}
              to={item.to}
            />
            <Divider
              className={classes.divider}
              variant="inset"
              component="li"
            />
          </React.Fragment>
        )
      })}
    </List>
  )
}

export default TenantList
