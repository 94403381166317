import { TenantRow as Tenant } from '../components/organisms/TenantUpdateTable'
import { TenantStatusEnum } from '../slices/services/api'

type Payload = {
  allTenants?: Tenant[]
  tenant?: Tenant
  searchParamText?: string
  filterStatus?: TenantStatusEnum[]
}

type Action = {
  type: 'UPDATE_TENANTS' | 'CLEAR_FILTER' | 'UPDATE_FILTERED_TENANTS'
  payload: Payload
}

type TenantState = {
  count: number
  allTenants: Tenant[]
  searchParam: {
    text: string
    filterStatus: TenantStatusEnum[]
  }
  filteredTenants: Tenant[]
  loaded: boolean
}

export const INITIAL_STATE: TenantState = {
  count: 0,
  allTenants: [],
  searchParam: {
    text: '',
    filterStatus: [],
  },
  filteredTenants: [],
  loaded: false,
}

export const InitialTenantState = (tenants: Tenant[]): TenantState => {
  return {
    ...INITIAL_STATE,
    allTenants: tenants,
  }
}

export const reducerFunc = (
  state: TenantState,
  action: Action
): TenantState => {
  let newState: TenantState

  switch (action.type) {
    case 'UPDATE_TENANTS': {
      newState = {
        ...state,
        allTenants: action.payload.allTenants ?? [],
        filteredTenants: [],
      }
      break
    }
    case 'UPDATE_FILTERED_TENANTS':
      newState = {
        ...state,
        searchParam: {
          ...state.searchParam,
          text: action.payload.searchParamText ?? '',
          filterStatus: action.payload.filterStatus ?? [],
        },
        filteredTenants: [],
      }
      break
    default:
      newState = {
        ...state,
        filteredTenants: [],
      }
  }

  const { text, filterStatus } = newState.searchParam
  newState.filteredTenants = newState.allTenants.filter((tenant) => {
    const textMatch = tenant.name.includes(text) || tenant.code.includes(text)
    if (filterStatus.length === 0) {
      return textMatch
    }
    return textMatch && filterStatus.includes(tenant.status)
  })

  newState.count = newState.filteredTenants.length
  newState.loaded = true
  return newState
}
