import {
  BadRequestErrorCodeEnum,
  ErrorCodeEnum,
  ForbiddenErrorCodeEnum,
  UnauthorizedErrorCodeEnum,
} from './api'

const messages: Record<string, string> = {
  unknown: 'エラーが発生しました。画面をリロードしてください。',
  network: 'ネットワークの接続を確認して、画面をリロードしてください。',
  [BadRequestErrorCodeEnum.Parameter]: 'パラメータが不正です。',
  [ErrorCodeEnum.NotFound]: 'データが見つかりませんでした。',
  [ErrorCodeEnum.InternalServerError]:
    'エラーが発生しました。画面をリロードしてください。',
  [ForbiddenErrorCodeEnum.NotAllowed]: '権限がありません。',
  [UnauthorizedErrorCodeEnum.InvalidToken]:
    '認証情報が間違っています。もう一度ログインしてください。',
  [UnauthorizedErrorCodeEnum.ExpiredToken]:
    '認証情報の有効期限が切れました。もう一度ログインしてください。',
  [UnauthorizedErrorCodeEnum.ResourceNotExist]:
    '入力されたアドレスはユーザー登録されておりません。',
}

export default function errorMessage(code: string): string {
  return messages[code] ?? messages.unknown
}
