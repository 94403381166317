import { ConsistencyEnum } from 'src/domain/consistency'
import {
  ReportStatusEnum,
  TenantDetail,
  TenantResentRegister,
} from 'src/slices/services/api'

export enum FormNameEnum {
  consistency = 'consistency',
  reportStatus = 'reportStatus',
  selectSalesDate = 'selectSalesDate',
  tenantCodeOrName = 'tenantCodeOrName',
  filterForMismatch = 'filterForMismatch',
}

export type ConsistencyResult = {
  name: string
  result: boolean
}

export type Cell = {
  key: string
  title: string
}

export type ChildRow = {
  key: string
  cells: Cell[]
}

export type Row = {
  key: string
  cells: Cell[]
  childRows: ChildRow[]
  tooltipText?: string
  error?: boolean
}

export type FormTypesSalesReportTab = {
  [FormNameEnum.selectSalesDate]: string
}

export type SalesReportType = {
  id: string
  rows: Row[]
  name: string
  code: string
  updatedAt: string
  registerIndex: number
  reportStatus: ReportStatusEnum
  resentRegisters: TenantResentRegister[]
  salesReportDate: string
  isAllManualInputValuesZero: boolean
  extractedConsistencyResult: boolean
  tenantDetail?: TenantDetail
  reportMessage?: string
  manageMessage?: string
  resized?: string
  isPdf?: boolean
  concatOriginal?: string
  separatedOriginal?: string[]
}

export type FilterOptions = {
  reportStatus: ReportStatusEnum[]
  consistency: ConsistencyEnum[]
  tenantCodeOrName: string
}

export type DefaultFilterValues = {
  searchText: string
  startDate: string
  endDate: string
  filterForMismatch: boolean
  reportStatus: ReportStatusEnum[]
  consistencyEnums: ConsistencyEnum[]
}

export type DateRange = {
  start: string
  end: string
}

export type CheckboxState<T> = {
  checkListValues: T
  allChecked: boolean
}

export type FormType = {
  tenantCodeOrName: string
  reportStatus: CheckboxState<ReportStatusEnum[]>
  consistency: CheckboxState<ConsistencyEnum[]>
}
