import React, { useCallback, useEffect, useState } from 'react'

import SelectButton, {
  Option,
} from '../../../../components/molecules/SelectButton'
import {
  AdminAdminSalesReportManagerRoles,
  isOneOfRoles,
} from '../../../../domain/role'
import { ENABLE_SYNC_ORG_CODES } from '../../../../domain/org'
import { RoleEnum } from '../../../../slices/services/api'

export type FinalizeReportButtonProps = {
  onClickStartFinalization?: () => void
  onClickSend?: () => void
  syncCount: number
  checkCount: number
  orgCode: string
  role?: RoleEnum
} & React.StyledProps

const FinalizeReportButton: React.StyledFC<FinalizeReportButtonProps> = ({
  className,
  onClickStartFinalization,
  onClickSend,
  syncCount,
  checkCount,
  orgCode,
  role,
}: FinalizeReportButtonProps) => {
  const [selectOptions, setSelectOptions] = useState<Option[]>([
    {
      icon: 'check',
      title: '確認開始',
      count: 0,
      onClick: onClickStartFinalization,
    },
  ])

  const isCheckOrSyncAvailable = useCallback(() => {
    return syncCount > 0 || checkCount > 0
  }, [syncCount, checkCount])

  useEffect(() => {
    const startCheck: Option = {
      icon: 'check',
      title: '確認開始',
      count: checkCount,
      onClick: onClickStartFinalization,
    }
    const startSend: Option = {
      icon: 'send',
      title: 'データ連携',
      count: syncCount,
      disabled: syncCount === 0,
      onClick: onClickSend,
    }

    if (
      isOneOfRoles(AdminAdminSalesReportManagerRoles, role) &&
      ENABLE_SYNC_ORG_CODES.includes(orgCode)
    ) {
      setSelectOptions([startCheck, startSend])
      return
    }
    setSelectOptions([startCheck])
  }, [
    syncCount,
    checkCount,
    orgCode,
    role,
    onClickStartFinalization,
    onClickSend,
  ])

  return (
    <SelectButton
      className={className}
      options={selectOptions}
      disabled={!isCheckOrSyncAvailable()}
    />
  )
}

export default FinalizeReportButton
