type RowsState = {
  keys: number[]
  nextKey: number
}

export const InitialRowsState = (len: number): RowsState => {
  return {
    keys: [...Array(len)].map((_, i) => i),
    nextKey: len,
  }
}

type Action = {
  type: 'ADD_ROW' | 'REMOVE_ROW'
  key?: number
}

export const reducerFunc = (state: RowsState, action: Action): RowsState => {
  switch (action.type) {
    case 'ADD_ROW':
      return {
        ...state,
        nextKey: state.nextKey + 1,
        keys: [...state.keys, state.nextKey],
      }
    case 'REMOVE_ROW':
      return {
        ...state,
        keys: state.keys.filter((k) => k !== action.key),
      }
    default:
      return { ...state }
  }
}
