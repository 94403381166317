import React from 'react'
import { Box, Typography } from '@mui/material'
import style from './styles.module.scss'
import Badge from '../../../../components/atoms/Badge'
import {
  getReportStatusTitle,
  getReportStatusBadgeColor,
  getReportStatusFillTypeColor,
} from '../../../../domain/reportStatus'
import { ReportStatusEnum } from '../../../../slices/services/api'
import { UpdateStatusType } from '../../type'

export type UpdateStatusConfirmDescriptionProps = {
  selectedUpdateStatusType?: UpdateStatusType
} & React.StyledProps

const UpdateStatusConfirmDescription: React.StyledFC<
  UpdateStatusConfirmDescriptionProps
> = ({ selectedUpdateStatusType }: UpdateStatusConfirmDescriptionProps) => {
  return (
    <div>
      {selectedUpdateStatusType === 'revertReported' ? (
        <Box className={style.badge}>
          <Badge
            label={getReportStatusTitle(ReportStatusEnum.Reported)}
            color={getReportStatusBadgeColor(ReportStatusEnum.Reported)}
            fillType={getReportStatusFillTypeColor(ReportStatusEnum.Reported)}
            ellipse
          />
          <Typography className={style.obliqueLine}>/</Typography>
          <Badge
            label={getReportStatusTitle(ReportStatusEnum.ImageResent)}
            color={getReportStatusBadgeColor(ReportStatusEnum.ImageResent)}
            fillType={getReportStatusFillTypeColor(
              ReportStatusEnum.ImageResent
            )}
            ellipse
          />
        </Box>
      ) : (
        <Box className={style.badge}>
          <Badge
            label={getReportStatusTitle(
              ReportStatusEnum.FinalizedReportSubmitted
            )}
            color={getReportStatusBadgeColor(
              ReportStatusEnum.FinalizedReportSubmitted
            )}
            fillType={getReportStatusFillTypeColor(
              ReportStatusEnum.FinalizedReportSubmitted
            )}
            ellipse
          />
        </Box>
      )}
      <Typography className={style.change}>に変更します</Typography>
    </div>
  )
}

export default UpdateStatusConfirmDescription
