import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginSentEmailTemplate from '../../components/templates/LoginSentEmail'
import useAppTitle from '../../hooks/useAppTitle'
import Path from '../../routes/path'

const LoginSentEmail = (): ReactElement => {
  useAppTitle('ログイン')
  const navigate = useNavigate()
  const handleClickBack = () => {
    navigate(Path.login)
  }

  return (
    <LoginSentEmailTemplate
      guideMessage="ログインリンクを
メールに送信しました"
      guideDescription="送信されたメールを確認し、手順に従ってログインを進めてください。"
      onClickBack={handleClickBack}
    />
  )
}

export default LoginSentEmail
