import React from 'react'
import clsx from 'clsx'
import Icon, { IconSize } from '../Icon'
import styles from './styles.module.scss'

export type CancelButtonProps = {
  fontSize?: IconSize
  onClick?: () => void
} & React.StyledProps

const CancelButton: React.StyledFC<CancelButtonProps> = ({
  className,
  fontSize,
  onClick,
}: CancelButtonProps) => {
  return (
    <button
      title="Clear"
      type="button"
      className={clsx(styles.button, className)}
      onClick={onClick}
      data-testid="cancel-button"
      tabIndex={-1}
    >
      <Icon icon="delete" fontSize={fontSize} />
    </button>
  )
}

export default CancelButton
