import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useBeforeUnload from '../../hooks/useBeforeUnload'
import TenantUpdateTemplate from '../../components/templates/TenantUpdate'
import useAppTitle from '../../hooks/useAppTitle'
import Path, { StorePathParams } from '../../routes/path'
import { useAppDispatch, useAppSelector } from '../../store'
import { Presenter } from './presenter'
import {
  clearStoreTenants,
  getStoreTenants,
  selectTenantStateByParams,
  selectTenantsByParams,
} from '../../slices/tenants/tenantsSlice'
import {
  clearEditTenants,
  patchTenants,
} from '../../slices/editTenants/editTenantsSlice'
import {
  selectStoreByCode,
  selectStoresRequestStateByParams,
  getStore,
} from '../../slices/stores/storesSlice'
import { TenantFormType } from '../../components/organisms/TenantUpdateTable'
import { ToastTriggerContext } from '../../context/toast.context'
import { STORE_TENANTS_PER_PAGE } from '../../constants'

const EditTenants: React.FC = (): ReactElement => {
  useAppTitle('テナント情報編集')
  useBeforeUnload()
  const { orgCode, storeCode } = useParams() as StorePathParams
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const toastContext = useContext(ToastTriggerContext)

  // API Request
  const params = useMemo(() => {
    return {
      orgCode,
      storeCode,
      perPage: 10000,
    }
  }, [orgCode, storeCode])
  const tenants = useAppSelector(selectTenantsByParams(params))
  const tableRows = useMemo(() => {
    return tenants ? Presenter.tableRows(tenants) : []
  }, [tenants])

  useAppSelector((state) => {
    if (selectTenantStateByParams(params)(state).status === 'idle') {
      dispatch(getStoreTenants(params))
    }
  })

  const store = useAppSelector(selectStoreByCode({ orgCode, storeCode }))
  const storeRequestState = useAppSelector(
    selectStoresRequestStateByParams({ orgCode, storeCode })
  )
  useEffect(() => {
    if (store === undefined && storeRequestState.status === 'idle') {
      dispatch(getStore({ orgCode, storeCode }))
    }
  }, [dispatch, orgCode, store, storeCode, storeRequestState.status])

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (tableRows.length > 0) {
      setLoading(false)
    }
  }, [tableRows.length])

  const storedParams = useAppSelector((state) => state.query.storeTenantsParams)
  const storeTenantsParams = {
    orgCode,
    storeCode,
    page: storedParams.page,
    q: storedParams.q,
    status: storedParams.status,
    trained: storedParams.trained,
    perPage: STORE_TENANTS_PER_PAGE,
  }

  const handleSubmit = async (values: TenantFormType) => {
    const updatedTenants = Presenter.requestBody(values, tenants || [])

    if (updatedTenants.length === 0) {
      toastContext.sendToast({
        variant: 'error',
        title: '更新するテナントがありません',
      })
      return
    }

    try {
      await dispatch(
        patchTenants({ orgCode, tenants: updatedTenants })
      ).unwrap()
      dispatch(clearEditTenants())
      dispatch(clearStoreTenants(storeTenantsParams))
      navigate(Path.store(orgCode, storeCode))
      toastContext.sendToast({
        variant: 'success',
        title: '更新に成功しました',
      })
    } catch (error) {
      toastContext.sendToast({
        variant: 'error',
        title: '更新が失敗しました',
      })
    }
  }

  return (
    <TenantUpdateTemplate
      tenants={tableRows}
      orgCode={orgCode}
      storeName={store?.name || ''}
      storeCode={store?.code || ''}
      onClickBack={() => navigate(Path.store(orgCode, storeCode))}
      onSubmit={handleSubmit}
      loading={loading}
    />
  )
}

export default EditTenants
