import React, { ReactElement, useContext } from 'react'
import ProfileTemplate from '../../templates/Profile'
import { postAuth } from '../../../../slices/auth/authSlice'
import { useAppDispatch, useAppSelector } from '../../../../store'
import useAppTitle from '../../../../hooks/useAppTitle'
import { AuthContext } from '../../../../context/auth.context'
import { getRoleTitle } from '../../type'
import {
  patchParam,
  patchProfile,
} from '../../../../slices/profile/profileSlice'
import { ToastTriggerContext } from '../../../../context/toast.context'

const Profile: React.FC = (): ReactElement => {
  useAppTitle('プロフィール')
  const { currentAuthorization } = useAppSelector((state) => state.auth)
  const authContext = useContext(AuthContext)
  const email = authContext.getUserEmail()
  const toastContext = useContext(ToastTriggerContext)

  const dispatch = useAppDispatch()

  const handleClickUpdate = async (name: string) => {
    if (!currentAuthorization) return

    const params: patchParam = {
      orgCode: currentAuthorization.organization.code,
      patchMemberName: { name },
    }

    try {
      await dispatch(patchProfile(params)).unwrap()
      // NOTE: プロフィール画面表示で使用しているAuth StateをPatch成功後更新するため、PostAuthを叩く
      await dispatch(postAuth()).unwrap()
      toastContext.sendToast({
        variant: 'success',
        title: '更新に成功しました',
      })
    } catch (error) {
      toastContext.sendToast({
        variant: 'error',
        title: '更新が失敗しました',
      })
    }
  }

  return (
    <ProfileTemplate
      email={email}
      memberRole={getRoleTitle(currentAuthorization?.role)}
      title="プロフィール"
      name={currentAuthorization?.memberName ?? ''}
      breadcrumbs={[{ to: 'profile', index: 0, title: 'プロフィール' }]}
      onClickUpdate={handleClickUpdate}
    />
  )
}

export default Profile
