import React from 'react'
import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import styles from './styles.module.scss'

export type SalesDateProps = {
  date?: string
} & React.StyledProps

const SalesDate: React.StyledFC<SalesDateProps> = ({
  className,
  date,
}: SalesDateProps) => {
  return (
    <Box className={clsx(className, styles.root)}>
      <Box className={styles.eachCategory}>
        <Typography variant="body2">
          <span className={styles.category}>売上日</span>
          <span className={styles.content}>{date}</span>
        </Typography>
      </Box>
    </Box>
  )
}

export default SalesDate
