import React, { useState } from 'react'
import { ArrowForwardIosSharp } from '@mui/icons-material'
import {
  Box,
  Collapse,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import clsx from 'clsx'
import Icon from 'src/components/atoms/Icon'
import { isNumber } from 'src/utils/number'
import { Cell, ChildRow } from '../../type'
import styles from './styles.module.scss'

export type SalesReportTableRowProps = {
  cells: Cell[]
  childRows: ChildRow[]
  tooltipText?: string
  error?: boolean
} & React.StyledProps

const SalesReportTableRow: React.StyledFC<SalesReportTableRowProps> = ({
  className,
  cells,
  childRows,
  tooltipText,
  error,
}: SalesReportTableRowProps) => {
  const [expanded, setExpanded] = useState<boolean | undefined>(error)
  const handleClick = () => {
    setExpanded(!expanded)
  }

  const cellText = (title: string, isBold: boolean) => {
    return (
      <Typography
        className={clsx(
          styles.typography,
          isBold && styles.parentTitle,
          error && styles.errorText
        )}
      >
        {title}
      </Typography>
    )
  }

  const align = (value: string, isFirst: boolean, isLast: boolean) => {
    if (isFirst) return 'left'
    if (isLast) return 'center'
    if (isNumber(value) || value === '-') return 'right'
    return 'center'
  }

  return (
    <>
      <TableRow
        className={clsx(className, styles.row, styles.parentRow)}
        onClick={handleClick}
      >
        {cells.map((cell, index) => {
          const isFirst = index === 0
          const noChild = childRows.length === 0
          const isLast = index === 3

          return (
            <TableCell
              key={cell.key}
              className={clsx(
                styles.cell,
                isFirst && styles.firstCell,
                !noChild && isFirst && styles.haveChildCell
              )}
              align={align(cell.title, isFirst, isLast)}
            >
              {isFirst ? (
                <Box className={styles.wrapper}>
                  <Box className={styles.iconAndTypography}>
                    {!noChild && (
                      <ArrowForwardIosSharp
                        className={clsx(
                          styles.expandIcon,
                          expanded && styles.expanded
                        )}
                      />
                    )}
                    {cellText(cell.title, true)}
                  </Box>
                  {tooltipText && (
                    <Tooltip
                      className={styles.iconTooltip}
                      title={tooltipText}
                      placement="top"
                    >
                      <div className={styles.iconWrapper}>
                        <Icon icon="tips" />
                      </div>
                    </Tooltip>
                  )}
                </Box>
              ) : (
                cellText(cell.title, isFirst)
              )}
            </TableCell>
          )
        })}
      </TableRow>

      {childRows.map((child) => (
        <TableRow
          key={child.key}
          className={clsx(
            className,
            styles.row,
            styles.childRow,
            expanded && styles.expandedChildRow
          )}
        >
          {child.cells.map((cell, index) => {
            const isFirst = index === 0
            const isLast = index === 3

            return (
              <TableCell
                key={cell.key}
                className={clsx(
                  styles.cell,
                  isFirst && styles.firstCell,
                  !expanded && styles.hideCell
                )}
                align={align(cell.title, isFirst, isLast)}
              >
                <Collapse key={cell.key} in={expanded}>
                  {cellText(cell.title, false)}
                </Collapse>
              </TableCell>
            )
          })}
        </TableRow>
      ))}
    </>
  )
}

export default SalesReportTableRow
