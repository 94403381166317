import React, { useEffect, useState } from 'react'
import { Box, Table, TableBody } from '@mui/material'
import clsx from 'clsx'
import { AppTableHead } from '../../../../components/tables/AppTableHead'
import SmallGuide from '../../../../components/molecules/SmallGuide'
import SalesReportTableRow from '../SalesReportTableRow'
import styles from './styles.module.scss'
import { Row } from '../../type'
import { columns } from '../../constants'

export type SalesReportTableProps = {
  rows: Row[]
  filterForMismatch: boolean
} & React.StyledProps

const SalesReportTable: React.StyledFC<SalesReportTableProps> = ({
  className,
  rows,
  filterForMismatch,
}: SalesReportTableProps) => {
  const [allFiltered, setAllFiltered] = useState<boolean>(false)

  useEffect(() => {
    let allRowsFiltered = false
    if (filterForMismatch) {
      const filteredRows = rows.filter((row) => Boolean(row.error))
      if (filteredRows.length === 0 && rows.length > 0) {
        allRowsFiltered = true
      }
    }
    setAllFiltered(allRowsFiltered)
  }, [filterForMismatch, rows])

  return (
    <Box className={clsx(className, styles.root)}>
      {allFiltered ? (
        <Box className={styles.allFiltered}>
          <SmallGuide
            icon="kirakira"
            className={styles.guide}
            message="不一致の項目はありません"
            description="報告結果を確認する場合は、フィルタを外して下さい"
          />
        </Box>
      ) : (
        <Table className={styles.table} stickyHeader>
          <AppTableHead columns={columns} />
          <TableBody>
            {rows.map((row) => (
              <SalesReportTableRow
                key={row.key}
                className={
                  !Boolean(row.error) && filterForMismatch
                    ? styles.collapseRow
                    : ''
                }
                cells={row.cells}
                tooltipText={row.tooltipText}
                childRows={row.childRows}
                error={row.error}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  )
}

export default SalesReportTable
