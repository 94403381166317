import React, { useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import clsx from 'clsx'
import LazyLoadImage from '../../atoms/LazyLoadImage'
import styles from './styles.module.scss'

export type ZoomableReceiptImageProps = {
  concatOriginal?: string
  separatedOriginal?: Array<string>
  resized?: string
  onLoaded?: () => void
} & React.StyledProps

const ZoomableReceiptImage: React.StyledFC<ZoomableReceiptImageProps> = ({
  concatOriginal,
  separatedOriginal,
  resized,
  className,
  onLoaded,
}: ZoomableReceiptImageProps) => {
  const [dragging, setDragging] = useState(false)

  const onClickLink = () => {
    if (concatOriginal) {
      window.open(concatOriginal, '_blank')
    }
  }

  return (
    <div className={styles.a} onClick={onClickLink} role="presentation">
      <ScrollContainer
        className={clsx(
          styles.root,
          className,
          dragging ? styles.grabbing : styles.grab
        )}
        onStartScroll={() => setDragging(true)}
        onEndScroll={() => setDragging(false)}
        hideScrollbars={false}
      >
        {separatedOriginal && resized && (
          <LazyLoadImage
            className={styles.img}
            sources={separatedOriginal}
            placeholder={resized}
            alt="レシート"
            onLoaded={onLoaded}
          />
        )}
      </ScrollContainer>
    </div>
  )
}

export default ZoomableReceiptImage
