// FIXME: CheckBox componentがformと連動するとエラーが多いため、暫定的に重複したcomponentをformで利用する形で作成
import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import makeStyles from '@mui/styles/makeStyles'
import { Controller, useFormContext } from 'react-hook-form'
import clsx from 'clsx'
import color from '../../../styles/color'
import Icon from '../Icon'

export type CheckBoxWithFormControlProps = {
  name: string
  isChecked?: boolean
  shape?: 'circle' | 'rectangle'
  disableRipple?: boolean
  disabled?: boolean
} & React.StyledProps

const useStyles = makeStyles({
  root: {
    '&:active $icon': {
      background: color.lightGrayHover,
    },
    '&:active $checkedIcon': {
      background: color.primaryHover,
    },
  },
  icon: {
    border: `1px solid ${color.lightGray}`,
    backgroundColor: color.white,
  },
  checkedIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color.primary,
  },
  rectangle: {
    width: 16,
    height: 16,
    boxSizing: 'border-box',
    borderRadius: 3,
  },
  circle: {
    width: 24,
    height: 24,
    borderRadius: '50%',
  },
})

const CheckBoxWithFormControl: React.StyledFC<CheckBoxWithFormControlProps> = ({
  className,
  name,
  shape = 'circle',
  isChecked,
  disabled = false,
  disableRipple = false,
}: CheckBoxWithFormControlProps) => {
  const classes = useStyles()
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Checkbox
          {...field}
          name={name}
          className={clsx(classes.root, className)}
          color="default"
          icon={<span className={clsx(classes.icon, classes[shape])} />}
          checkedIcon={
            <span
              className={clsx(
                classes.icon,
                classes.checkedIcon,
                classes[shape]
              )}
            >
              <Icon icon="checked" />
            </span>
          }
          checked={isChecked}
          disabled={disabled}
          disableRipple={disableRipple}
        />
      )}
    />
  )
}

export default CheckBoxWithFormControl
