import { Color } from 'src/AppTypes'
import { Column } from '../components/tables/AppTableHead'
import { TenantStatusEnum } from '../slices/services/api'

// FIXME: memberからも参照されているので別のファイルに作る
export type Option = {
  value: string
  title: string
  color?: Color
  disabled?: boolean
}

export const TABLE_COLUMNS: Column[] = [
  {
    title: 'テナントコード',
    labeledMessage: '必須',
    message: '※ 重複不可',
  },
  {
    title: 'テナント名称',
    labeledMessage: '必須',
  },
]
export const TABLE_UPDATE_COLUMNS: Column[] = [
  {
    title: '利用ステータス',
  },
  ...TABLE_COLUMNS,
]
export const HIDE_CELL_WIDTH = 72

export const STATUS_PLACEHOLDER = '利用開始前'
export const STATUS_OPTIONS: Option[] = [
  {
    title: '利用中',
    value: TenantStatusEnum.Ready,
    color: 'green',
  },
  {
    title: '利用停止',
    value: TenantStatusEnum.Closed,
    color: 'red',
  },
]

export const NAME_PLACEHOLDER = 'テナント名'
export const CODE_PLACEHOLDER = 'テナントコード'
