import React, { useCallback, useMemo } from 'react'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'

import {
  CalculationMethod,
  CalculationRoundingTypeEnum,
  RoleEnum,
} from '../../../slices/services/api'
import tabContentStyle from '../../../pages/TenantDetail/tabContentStyle'
import Button from '../../atoms/Button'
import CenteringLoading from '../../molecules/CenteringLoading'
import SmallGuide from '../../molecules/SmallGuide'
import CalculationTable, {
  CalculationTableProps,
} from '../../organisms/CalculationTable'
import {
  AdminAdminSalesReportManagerEditorSalesReportManagerEditorStoreManagerInOperationCenterRoles,
  isOneOfRoles,
} from '../../../domain/role'
import { Option } from '../../molecules/SelectWithOption'
import { formatDateTimeWithDayOfWeek } from '../../../utils/date'
import EditCalculationMethod from '../../molecules/EditCalculationMethod'

export type CalculationTabProps = {
  role?: RoleEnum
  lastUpdatedAt?: string
  isLoading: boolean
  calculationMethod?: CalculationMethod
  roundingTypeOptions: Option[]
  precisionOptions: Option[]
  onClickEditButton: () => void
  onClickEditCalculationMethod: (
    roundingType: CalculationRoundingTypeEnum,
    precision: number
  ) => void
} & Partial<Pick<CalculationTableProps, 'className' | 'columns' | 'records'>>

const CalculationTab: React.StyledFC<CalculationTabProps> = ({
  className,
  isLoading,
  calculationMethod,
  roundingTypeOptions,
  precisionOptions,
  role,
  lastUpdatedAt,
  onClickEditButton,
  onClickEditCalculationMethod,
  columns = [],
  records = [],
}: CalculationTabProps) => {
  const tabClasses = tabContentStyle()

  const showSwitchByRole = useCallback((): boolean => {
    return isOneOfRoles(
      AdminAdminSalesReportManagerEditorSalesReportManagerEditorStoreManagerInOperationCenterRoles,
      role
    )
  }, [role])

  const formattedLastUpdatedAt = useMemo(() => {
    return lastUpdatedAt ? formatDateTimeWithDayOfWeek(lastUpdatedAt) : ''
  }, [lastUpdatedAt])

  const renderContent = (renderRecords: CalculationTableProps['records']) => {
    return renderRecords.length > 0 ? (
      <>
        <Box
          className={clsx(className, tabClasses.calcMethodWithLastUpdatedAt)}
        >
          <EditCalculationMethod
            calculationMethod={calculationMethod}
            roundingTypeOptions={roundingTypeOptions}
            precisionOptions={precisionOptions}
            onClickEditCalculationMethod={onClickEditCalculationMethod}
          />
          {lastUpdatedAt && (
            <Box className={tabClasses.lastUpdatedAt}>
              <Typography variant="subtitle2">最終更新日時</Typography>
              <Typography>{formattedLastUpdatedAt.toLocaleString()}</Typography>
            </Box>
          )}
        </Box>
        <CalculationTable
          className={tabClasses.table}
          columns={columns}
          records={renderRecords}
        />
        {showSwitchByRole() && (
          <Button
            className={tabClasses.editButton}
            title="算出ロジックを編集"
            color="primary"
            size="large"
            onClick={onClickEditButton}
          />
        )}
      </>
    ) : (
      <SmallGuide
        icon="pray"
        message="利用準備がされていません"
        description="まずは登録して利用開始の準備をしましょう" // TODO: view の場合のテキストへの変更
        buttonTitle={!showSwitchByRole() ? '' : '設定'}
        onClick={onClickEditButton}
      />
    )
  }

  return (
    <Box
      className={clsx(
        tabClasses.root,
        records.length === 0 && tabClasses.guideRoot
      )}
    >
      {isLoading ? <CenteringLoading /> : renderContent(records)}
    </Box>
  )
}

export default CalculationTab
