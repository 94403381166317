import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConsistencyEnum } from 'src/domain/consistency'
import { DEFAULT_PAGE } from '../../constants'
import { ReportStatusEnum, TenantStatusEnum } from '../services/api'

type TabParams = {
  tab?: string | null
}

type BaseParams = {
  page: number
  q?: string
  pathname?: string
}

type StoresParams = BaseParams

type TenantsParams = {
  status?: TenantStatusEnum
  ocr?: boolean
  trained?: boolean
} & BaseParams

type StoreTenantsParams = TenantsParams & TabParams

type StoreTrialResultsParam = {
  status?: TenantStatusEnum
} & BaseParams &
  TabParams

type StoreSalesReportsParams = BaseParams &
  TabParams & {
    startDate?: string
    endDate?: string
    reportStatus?: ReportStatusEnum[]
    aggregatedResult?: ConsistencyEnum[]
  }

type SalesReportSummariesParams = Omit<BaseParams, 'page'> & {
  date?: string
}

type TenantSalesReportSummariesParams = SalesReportSummariesParams & {
  haveMessage?: boolean
  reportStatus?: ReportStatusEnum | string
  consistencyResult?: boolean
}

type TenantSalesReportParams = SalesReportSummariesParams

type State = {
  salesReportSummariesParams: SalesReportSummariesParams
  storesParams: StoresParams
  storeParams: TabParams
  storeSalesReportsParams: StoreSalesReportsParams
  storeTenantsParams: StoreTenantsParams
  storeTrialResultsParams: StoreTrialResultsParam
  orgTenantsParams: TenantsParams
  tenantParams: TabParams
  tenantSalesReportParams: TenantSalesReportParams
  tenantSalesReportSummariesParams: TenantSalesReportSummariesParams
}

export const initialState: State = {
  salesReportSummariesParams: {},
  storesParams: { page: DEFAULT_PAGE },
  storeParams: {},
  storeSalesReportsParams: { page: DEFAULT_PAGE },
  storeTenantsParams: { page: DEFAULT_PAGE },
  storeTrialResultsParams: { page: DEFAULT_PAGE },
  orgTenantsParams: { page: DEFAULT_PAGE },
  tenantParams: {},
  tenantSalesReportParams: {},
  tenantSalesReportSummariesParams: {},
}

const slice = createSlice({
  name: 'query',
  initialState,
  reducers: {
    setSalesReportSummariesParams: (
      state,
      { payload }: PayloadAction<SalesReportSummariesParams>
    ): State => {
      return {
        ...state,
        salesReportSummariesParams: payload,
      }
    },
    setStoresParams: (
      state,
      { payload }: PayloadAction<StoresParams>
    ): State => {
      return {
        ...state,
        storesParams: payload,
      }
    },
    setStoreParams: (state, { payload }: PayloadAction<TabParams>): State => {
      return {
        ...state,
        storeParams: payload,
      }
    },
    setStoreSalesReportsParams: (
      state,
      { payload }: PayloadAction<StoreSalesReportsParams>
    ): State => {
      return {
        ...state,
        storeSalesReportsParams: payload,
      }
    },
    setStoreTenantsParams: (
      state,
      { payload }: PayloadAction<StoreTenantsParams>
    ): State => {
      return {
        ...state,
        storeTenantsParams: payload,
      }
    },
    setStoreTrialResultsParams: (
      state,
      { payload }: PayloadAction<StoreTrialResultsParam>
    ): State => {
      return {
        ...state,
        storeTrialResultsParams: payload,
      }
    },
    setOrgTenantsParams: (
      state,
      { payload }: PayloadAction<TenantsParams>
    ): State => {
      return {
        ...state,
        orgTenantsParams: payload,
      }
    },
    setTenantParams: (state, { payload }: PayloadAction<TabParams>): State => {
      return {
        ...state,
        tenantParams: payload,
      }
    },
    setTenantSalesReportSummariesParams: (
      state,
      { payload }: PayloadAction<TenantSalesReportSummariesParams>
    ): State => {
      return {
        ...state,
        tenantSalesReportSummariesParams: payload,
      }
    },
  },
})

export const {
  setSalesReportSummariesParams,
  setStoresParams,
  setStoreParams,
  setStoreSalesReportsParams,
  setStoreTenantsParams,
  setStoreTrialResultsParams,
  setOrgTenantsParams,
  setTenantParams,
  setTenantSalesReportSummariesParams,
} = slice.actions

export default slice.reducer
