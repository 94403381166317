import React, { memo, useReducer, useRef } from 'react'
import { Box, Table, TableBody } from '@mui/material'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { AppTableHead, AppTableRow, IconCell, InputCell } from '../../tables'
import tenantUpdateStyles from '../TenantUpdateTable/styles.module.scss'
import {
  CODE_PLACEHOLDER,
  TABLE_COLUMNS,
  HIDE_CELL_WIDTH,
  NAME_PLACEHOLDER,
} from '../../../domain/tenant'
import { InitialRowsState, reducerFunc } from '../../../utils/tableReducer'
import AppTableAddRowButton from '../../tables/AppTableAddRowButton'
import {
  TenantCodeRegex,
  TenantCodeRegexErrorMessage,
} from '../../../domain/tenantCode'

export type TenantRow = {
  defaultValue: {
    name: string
    code: string
  }
}

export type TenantFormType = {
  tenants: {
    name: string
    code: string
  }[]
}

export type TenantCreateTableProps = {
  orgCode?: string
} & React.StyledProps

const TenantCreateTable: React.StyledFC<TenantCreateTableProps> = memo(
  ({ className, style, orgCode }: TenantCreateTableProps) => {
    const scrollBottomRef = useRef<HTMLTableRowElement>(null)
    const [rowState, dispatch] = useReducer(
      reducerFunc,
      InitialRowsState(1),
      (init) => init
    )

    const formMethods = useFormContext<TenantFormType>()

    const addRow = () => {
      dispatch({ type: 'ADD_ROW' })
      setTimeout(() => {
        scrollBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    }

    const removeRow = (key: number) => {
      dispatch({ type: 'REMOVE_ROW', key })
      formMethods.unregister(`tenants.${key}`)
    }

    return (
      <Box className={clsx(className, tenantUpdateStyles.root)} style={style}>
        <Box className={tenantUpdateStyles.tableWrapper}>
          <Table stickyHeader className={tenantUpdateStyles.table}>
            <AppTableHead
              columns={TABLE_COLUMNS}
              hideCellWidth={HIDE_CELL_WIDTH}
            />
            <TableBody>
              {rowState.keys.map((key) => (
                <AppTableRow key={key}>
                  <IconCell icon="minus" onClick={() => removeRow(key)} />
                  <InputCell
                    name={`tenants.${key}.code`}
                    placeholder={CODE_PLACEHOLDER}
                    width={180}
                    required
                    validations={[
                      {
                        regex: TenantCodeRegex(orgCode),
                        message: TenantCodeRegexErrorMessage(orgCode),
                      },
                    ]}
                  />
                  <InputCell
                    name={`tenants.${key}.name`}
                    placeholder={NAME_PLACEHOLDER}
                    width={260}
                    required
                  />
                </AppTableRow>
              ))}
              <tr ref={scrollBottomRef} />
            </TableBody>
          </Table>
        </Box>
        <AppTableAddRowButton onClick={addRow} />
      </Box>
    )
  }
)

TenantCreateTable.displayName = 'TenantCreateTable'
export default TenantCreateTable
