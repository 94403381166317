import React, { memo, ReactElement } from 'react'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import {
  defaultLayoutPlugin,
  ToolbarProps,
  ToolbarSlot,
} from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

export type PdfViewerProps = {
  separatedOriginal?: Array<string>
  concatOriginal?: string
} & React.StyledProps

const renderToolbar = (
  Toolbar: (props: ToolbarProps) => ReactElement
): ReactElement => (
  <Toolbar>
    {(slots: ToolbarSlot) => {
      const {
        CurrentPageInput,
        GoToNextPage,
        GoToPreviousPage,
        NumberOfPages,
        ShowSearchPopover,
        Zoom,
        ZoomIn,
        ZoomOut,
      } = slots
      // FIXME: scssファイルに移行する
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ padding: '0px 2px', width: '130px' }}>
            <ShowSearchPopover />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ padding: '0px 2px' }}>
              <ZoomOut />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <Zoom />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <ZoomIn />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <GoToPreviousPage />
            </div>
            <div style={{ padding: '0px 2px', width: '32px' }}>
              <CurrentPageInput />
            </div>
            <div style={{ padding: '0px 8px' }}>/</div>
            <div style={{ padding: '0px 2px' }}>
              <NumberOfPages />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <GoToNextPage />
            </div>
          </div>
        </div>
      )
    }}
  </Toolbar>
)

const PdfViewer: React.StyledFC<PdfViewerProps> = memo(
  ({ className, separatedOriginal, concatOriginal }: PdfViewerProps) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
      sidebarTabs() {
        return []
      },
      renderToolbar,
    })

    return (
      <div className={className}>
        {/* NOTE: 検索時のPop Upのlabelを見えないようにする */}
        <style>
          {`
        #rpv-core__popover-body-search .rpv-search__popover-label {
          display: none;
        }
      `}
        </style>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
          {concatOriginal && (
            <Viewer
              fileUrl={concatOriginal}
              defaultScale={1}
              plugins={[defaultLayoutPluginInstance]}
            />
          )}
          {!concatOriginal &&
            separatedOriginal &&
            separatedOriginal.map((url) => (
              <Viewer
                key={`${url}`}
                fileUrl={url}
                defaultScale={1}
                plugins={[defaultLayoutPluginInstance]}
              />
            ))}
        </Worker>
      </div>
    )
  }
)

PdfViewer.displayName = 'PdfViewer'
export default PdfViewer
