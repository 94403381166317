import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { TenantPathParams } from '../routes/path'
import { useAppSelector } from '../store'
import { selectTenantByCode } from '../slices/tenants/tenantsSlice'
import { selectTenantSalesReportByParams } from '../slices/tenantSalesReports/tenantSalesReportsSlice'

export type EventType = 'start' | 'skip' | 'next' | 'back' | 'finish'

const useBigQuery = (): {
  sendReportCheckEvent: (type: EventType) => void
} => {
  const { orgCode, storeCode, tenantCode } =
    useParams<TenantPathParams>() as TenantPathParams
  const tenant = useAppSelector(
    selectTenantByCode({ orgCode, storeCode, tenantCode })
  )
  const { selectedTenantIndex, selectedTenantCodes, date } = useAppSelector(
    (state) => state.forms.confirmSalesReport
  )

  const tenantSalesReport = useAppSelector(
    selectTenantSalesReportByParams({
      orgCode,
      storeCode,
      tenantCode,
      date,
    })
  )

  const sendReportCheckEvent = (type: EventType) => {
    const url =
      process.env.REACT_APP_ENV === 'production'
        ? 'https://sendreportevent-33p5c7iy7q-uc.a.run.app'
        : 'https://sendreportevent-wrbw6qzaqa-uc.a.run.app'

    const isStart = type === 'start'

    let transactionId = window.localStorage.getItem('transaction')
    if (transactionId === null) {
      transactionId = uuidv4()
      window.localStorage.setItem('transaction', transactionId)
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: transactionId,
        salesDate: date,
        tenantId: tenant?.id,
        orgCode,
        storeCode,
        tenantCode,
        registerCount: tenantSalesReport?.registers.length,
        stepBottom: selectedTenantCodes.length,
        stepTop: isStart ? 0 : selectedTenantIndex + 1,
        eventType: type,
      }),
    })

    if (type === 'finish') {
      window.localStorage.removeItem('transaction')
    }
  }

  return { sendReportCheckEvent }
}

export { useBigQuery }
