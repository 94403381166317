// NOTE: org ごとに tenantCode の桁数が変わる
// FIXME: 本来は DB で org ごとの桁数を管理すべきだが、間に合わないためクライアント側で一時的に管理する
export const TenantCodeDigit = (orgCode?: string): number | undefined => {
  switch (orgCode) {
    // OIOI
    case '001':
      return 4
    // 近鉄R
    case '002':
      return 5
    // OPA
    case '003':
      return 3
    // AEON
    case '004':
      return 7
    // 三井不動産
    case '005':
    case '988':
      return 5
    default:
      return undefined
  }
}

export const TenantCodeRegex = (orgCode?: string): RegExp => {
  const digit = TenantCodeDigit(orgCode)
  return new RegExp(`^[A-Za-z0-9_-]${digit ? `{${digit}}` : '*'}$`)
}

export const TenantCodeRegexErrorMessage = (orgCode?: string): string => {
  const digit = TenantCodeDigit(orgCode)
  return `半角${digit ? `${digit}文字` : ''}で入力してください`
}
