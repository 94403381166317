import React, { ReactElement, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { Path as BreadcrumbsPath } from '../../components/molecules/Breadcrumbs'
import StoreTemplate from '../../components/templates/Store'
import {
  getStore,
  selectStoreByCode,
  selectStoresRequestStateByParams,
} from '../../slices/stores/storesSlice'
import useAppTitle from '../../hooks/useAppTitle'
import { StorePathParams } from '../../routes/path'
import { useAppDispatch, useAppSelector } from '../../store'
import SalesReportsTab from '../../features/salesReport/pages/SalesReportsTab'
import StoreTenantsTab from '../StoreTenantsTab'
import Presenter from './presenter'

const tabContents = [
  {
    name: 'tenants',
    node: <StoreTenantsTab key={0} />,
  },
  {
    name: 'salesReports',
    node: <SalesReportsTab key={1} />,
  },
]

const Store = (): ReactElement => {
  useAppTitle('店舗詳細')
  const location = useLocation()
  const params = useParams<StorePathParams>() as StorePathParams
  const [query, setQuery] = useQueryParams({
    tab: withDefault(StringParam, 'tenants'),
  })
  const { tab } = query
  const [breadcrumbPaths, setBreadcrumbPaths] = useState<BreadcrumbsPath[]>([])
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)

  // Redux
  const dispatch = useAppDispatch()
  const store = useAppSelector(selectStoreByCode(params))
  const requestState = useAppSelector(selectStoresRequestStateByParams(params))
  const role = useAppSelector((state) => state.auth.currentAuthorization?.role)

  useEffect(() => {
    if (requestState.status === 'idle') {
      dispatch(getStore(params))
    }
  }, [dispatch, requestState, params])

  // Breadcrumbs
  useEffect(() => {
    setBreadcrumbPaths(Presenter.breadcrumbs(params.orgCode, store?.name))
  }, [params.orgCode, store])

  // Tab
  useEffect(() => {
    if (tab) {
      const index = tabContents.findIndex((content) => content.name === tab)
      setSelectedTabIndex(index)
    }
  }, [setQuery, tab])
  const onSelectTab = (index: number) => {
    setQuery({ tab: tabContents[index].name })
  }

  return (
    <StoreTemplate
      breadCrumbPaths={breadcrumbPaths}
      queryState={location.search}
      tabOptions={Presenter.tabList(params.orgCode, role)}
      tabContents={tabContents.map((content) => content.node)}
      tabIndex={selectedTabIndex}
      onSelectTab={onSelectTab}
      store={store?.name ?? ''}
      storeCode={params.storeCode}
      isLoading={requestState.status === 'loading'}
    />
  )
}

export default Store
