import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'
import {
  configureStore,
  getDefaultMiddleware,
  AsyncThunk,
} from '@reduxjs/toolkit'
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import AppError, { PostCalculationDSLError } from './domain/error'
// eslint-disable-next-line import/no-cycle
import persistedReducer from './store/reducer'

const store = configureStore({
  reducer: persistedReducer,
  // Non-Serializable Dataのチェックを回避するため
  // 公式ドキュメント https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})

export default store

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>

// Dispatch
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()

// Selector
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export type AppThunkConfig = {
  state: RootState
  // dispatch: AppDispatch
  // extra: unknown
  // rejectValue?: unknown
  serializedErrorType: AppError
}

export type PostCalculationDSLThunkConfig = {
  state: RootState
  // dispatch: AppDispatch
  // extra: unknown
  // rejectValue?: unknown
  serializedErrorType: PostCalculationDSLError
}

export type GenericAsyncThunk = AsyncThunk<
  unknown,
  unknown,
  AppThunkConfig | PostCalculationDSLThunkConfig
>
export type PendingAction = ReturnType<GenericAsyncThunk['pending']>
export type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
