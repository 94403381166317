import {
  TenantFormType,
  TenantRow,
} from '../../components/organisms/TenantUpdateTable'
import { NormalizedTenant } from '../../domain/schema'
import { UpdateTenant } from '../../slices/editTenants/editTenantsSlice'
import { fullToHalf } from '../../utils/string'

interface EditTenantPresenter {
  tableRows(tenants: NormalizedTenant[]): TenantRow[]
  requestBody(
    formValue: TenantFormType,
    tenants: NormalizedTenant[]
  ): UpdateTenant[]
}

export const Presenter: EditTenantPresenter = {
  tableRows(tenants) {
    return tenants.map((tenant, index) => {
      return {
        index,
        name: tenant.name,
        code: tenant.code,
        status: tenant.status,
      }
    })
  },
  requestBody(formValue, tenants) {
    return formValue.tenants
      .map((value, index) => {
        const originalTenant = tenants[index]
        const changed =
          value.name !== originalTenant.name ||
          value.code !== originalTenant.code ||
          value.status !== originalTenant.status
        return {
          id: changed ? originalTenant.id : null,
          name: fullToHalf(value.name),
          code: fullToHalf(value.code),
          status: value.status,
        }
      })
      .filter((tenant) => tenant.id) as UpdateTenant[]
  },
}
