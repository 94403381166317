/* eslint-disable no-param-reassign */
import { Selector } from 'react-redux'
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit'
import { denormalize, normalize } from 'normalizr'
import { GetRequestState } from '../../domain/request'
import {
  calculationGroupEntity,
  NormalizedCalculationGroup,
} from '../../domain/schema'
// eslint-disable-next-line import/no-cycle
import { AppThunkConfig, RootState } from '../../store'
import { purge } from '../../store/action'
import toKey from '../../utils/key'
import { CalculationGroup, CalculationItem } from '../services/api'
import serializeError from '../services/error'
import AuthenticatedApi from '../services/authenticatedApi'

interface CalculationGroupState
  extends EntityState<NormalizedCalculationGroup> {
  query: {
    [code: string]: GetRequestState
  }
}

const calculationGroupsAdapter =
  createEntityAdapter<NormalizedCalculationGroup>()

export const initialState: CalculationGroupState =
  calculationGroupsAdapter.getInitialState({
    query: {},
  })

export type GetParams = {
  orgCode: string
  storeCode: string
}

interface NormalizedEntities {
  calculationGroups: Record<string, NormalizedCalculationGroup>
  calculationItems: Record<string, CalculationItem>
}

export const getCalculationGroup = createAsyncThunk<
  NormalizedEntities,
  GetParams,
  AppThunkConfig
>(
  'calculationGroups/getCalculationGroup',
  async (params, { getState }) => {
    const { auth } = getState()
    const response = await new AuthenticatedApi(
      auth.token
    ).getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
      params.orgCode,
      params.storeCode
    )

    const normalized = normalize<
      NormalizedCalculationGroup,
      NormalizedEntities
    >(response.data, calculationGroupEntity)
    return normalized.entities
  },
  { serializeError }
)

const slice = createSlice({
  name: 'calculationGroups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(getCalculationGroup.pending, (state, { meta }) => {
      return {
        ...state,
        query: {
          ...state.query,
          [toKey(meta.arg)]: {
            status: 'loading',
          },
        },
      }
    })
    builder.addCase(
      getCalculationGroup.fulfilled,
      (state, { meta, payload }) => {
        if (payload.calculationGroups) {
          calculationGroupsAdapter.upsertMany(state, payload.calculationGroups)
        }
        state.query[toKey(meta.arg)] = {
          status: 'succeeded',
          ids: Object.keys(payload.calculationGroups ?? {}),
        }
      }
    )
    builder.addCase(getCalculationGroup.rejected, (state, { meta, error }) => {
      return {
        ...state,
        query: {
          ...state.query,
          [toKey(meta.arg)]: {
            status: 'failed',
            error,
          },
        },
      }
    })
  },
})

export default slice.reducer

// Selector
export const {
  selectById: selectCalculationGroupById,
  selectEntities: selectCalculationGroupEntities,
  selectAll: selectAllCalculationGroups,
} = calculationGroupsAdapter.getSelectors<CalculationGroupState>(
  (state) => state
)

export const selectByParams = (
  params: GetParams
): Selector<RootState, NormalizedCalculationGroup | undefined> => {
  return createSelector(
    [(state) => state.entities.calculationGroups],
    (state) => {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      return state.query[toKey(params)]?.ids
        ?.map((calculationGroupId: any) => state.entities[calculationGroupId])
        .filter(
          (
            calculationGroup: any
          ): calculationGroup is NormalizedCalculationGroup =>
            calculationGroup !== undefined
        )
        .shift()
      /* eslint-enable */
    }
  )
}

export const selectCalculationGroupStateByParams = (
  params: GetParams
): Selector<RootState, GetRequestState> => {
  return createSelector(
    [(state) => state.entities.calculationGroups],
    (state) => {
      return (
        state.query[toKey(params)] ?? {
          status: 'idle',
        }
      )
    }
  )
}

export const selectDenormalizedByParams = (
  params: GetParams
): Selector<RootState, CalculationGroup | undefined> => {
  return createSelector(
    [
      (state) => state.entities.calculationGroups,
      (state) => state.entities.calculationItems.entities,
    ],
    (state, calculationItemEntities) => {
      const calculationGroupIds = state.query[toKey(params)]?.ids
      if (!calculationGroupIds) {
        return undefined
      }
      const groups = denormalize(
        calculationGroupIds,
        [calculationGroupEntity],
        {
          calculationGroups: state.entities,
          calculationItems: calculationItemEntities,
        }
      )

      if (groups.length < 1) {
        return undefined
      }
      return groups[0]
    }
  )
}
