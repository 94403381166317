export type FormValidation = {
  regex: RegExp
  message: string
}

export function createFormValidation(validations: FormValidation[]) {
  return Object.fromEntries(
    validations.map(({ regex, message }) => [
      message,
      (value: string) => regex.test(value) || message,
    ])
  )
}
