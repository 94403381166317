import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMount } from 'react-use'

import useBeforeUnload from '../../../../hooks/useBeforeUnload'
import TemplatesMembersUpdate from '../../templates/Update'
import Loading from '../../../../components/templates/Loading'
import useAppTitle from '../../../../hooks/useAppTitle'
import { useApiMember } from '../../../../hooks/useApiMember'
import Path, { OrgPathParams } from '../../../../routes/path'
import {
  Member,
  RoleEnum,
  Store as EntityStore,
} from '../../../../slices/services/api'
import { ToastTriggerContext } from '../../../../context/toast.context'
import { useAppDispatch, useAppSelector } from '../../../../store'
import {
  getStores,
  selectStoresByParams,
  selectStoresRequestStateByParams,
} from '../../../../slices/stores/storesSlice'
import { AuthContext } from '../../../../context/auth.context'
import { adjustMembers } from './presenter'
import {
  AdminAdminStoreManagerRoles,
  isOneOfRoles,
} from '../../../../domain/role'

const PagesMembersUpdate: React.FC = (): ReactElement => {
  useAppTitle('ユーザー管理')
  useBeforeUnload()
  const authContext = useContext(AuthContext)
  const { getMembers, patchMembers } = useApiMember()
  const toastContex = useContext(ToastTriggerContext)
  const [members, setMembers] = useState<Member[]>([])
  const [isLoaded, setLoaded] = useState<boolean>(false)
  const { orgCode } = useParams<OrgPathParams>() as OrgPathParams
  const navigate = useNavigate()

  const param = useMemo(() => {
    return { orgCode, page: 1, perPage: 1000 }
  }, [orgCode])

  const dispatch = useAppDispatch()
  const stores: EntityStore[] =
    useAppSelector((state) => selectStoresByParams(param)(state)) ?? []

  const { status } = useAppSelector((state) =>
    selectStoresRequestStateByParams(param)(state)
  )

  const selfRole: RoleEnum | undefined = useAppSelector(
    (state) => state.auth.currentAuthorization?.role
  )

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getStores(param))
    }
  }, [dispatch, status, param])

  const handleUpdateMember = async (data: Member[]) => {
    const result = await patchMembers(orgCode, data)
    if (!result.success) {
      toastContex.sendToast({
        variant: 'error',
        title: `ユーザーの更新に失敗しました: ${result.message}`,
      })
      return
    }
    toastContex.sendToast({
      variant: 'success',
      title: `ユーザーの更新に成功しました`,
    })
    navigate(Path.members(orgCode))
  }

  const onClickConfirmClose = () => {
    navigate(Path.members(orgCode))
  }

  useMount(async () => {
    const result = await getMembers(orgCode)
    if (!result.success) {
      toastContex.sendToast({
        variant: 'error',
        title: `ユーザーの取得に失敗しました: ${result.message}`,
      })
      return
    }
    const selfEmail = authContext.getUserEmail()
    if (!selfRole || !selfEmail) {
      return
    }
    const adjustedMembers = adjustMembers(
      result.data || [],
      selfEmail,
      selfRole
    )
    if (!adjustedMembers) {
      toastContex.sendToast({
        variant: 'error',
        title: `再度ログインしなおしてからお試しください`,
      })
    }
    setMembers(adjustedMembers || [])
    setLoaded(true)
  })

  const showSwitchByRole = useCallback((): boolean => {
    return isOneOfRoles(AdminAdminStoreManagerRoles, selfRole)
  }, [selfRole])

  return (
    <div>
      {!showSwitchByRole() && <div>権限がありません</div>}

      {isLoaded && status === 'succeeded' && selfRole && showSwitchByRole() ? (
        <TemplatesMembersUpdate
          stores={stores}
          members={members}
          selfRole={selfRole}
          orgCode={orgCode}
          onUpdateMember={handleUpdateMember}
          onClickConfirmClose={onClickConfirmClose}
        />
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default PagesMembersUpdate
