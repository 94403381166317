import React from 'react'
import { Link } from 'react-router-dom'
import { ListItem, ListItemText, ListItemIcon, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Icon from '../../atoms/Icon'

export type StoreListItemProps = {
  storeName: string
  storeCode: string
  tenantCount: number
  to: string
} & React.StyledProps

const useStyles = makeStyles({
  listItem: {
    height: 72,
    padding: 0,
  },
  storeCode: {
    marginRight: 20,
  },
  storeIcon: {
    minWidth: 0,
    marginRight: 24,
    marginLeft: 12,
  },
  tenantCount: {
    marginRight: 5,
  },
  activeCount: {
    whiteSpace: 'pre',
  },
})

const StoreListItem: React.StyledFC<StoreListItemProps> = ({
  className,
  storeName,
  storeCode,
  tenantCount,
  to,
}: StoreListItemProps) => {
  const classes = useStyles()
  return (
    <ListItem
      button
      component={Link}
      classes={{ root: classes.listItem }}
      className={className}
      to={to}
    >
      <ListItemIcon classes={{ root: classes.storeIcon }}>
        <Icon icon="store" />
      </ListItemIcon>
      <ListItemText
        secondary={
          <Typography variant="body2">
            <Typography
              classes={{ root: classes.tenantCount }}
              component="span"
              variant="inherit"
              display="inline"
            >{`${tenantCount} テナント`}</Typography>
          </Typography>
        }
        disableTypography
      >
        <Typography
          classes={{ root: classes.storeCode }}
          variant="subtitle1"
          color="textSecondary"
          display="inline"
        >
          {storeCode}
        </Typography>
        <Typography variant="subtitle1" display="inline">
          {storeName}
        </Typography>
      </ListItemText>
    </ListItem>
  )
}

export default StoreListItem
