import { ReportStatusEnum } from '../slices/services/api'

export type ReportStatusTitle =
  | '報告待ち'
  | '報告中断'
  | '報告済み'
  | '再送済み'
  | '確定済み'
  | '連携済み'

export type BadgeColor =
  | 'red'
  | 'green'
  | 'green04'
  | 'lightGreen'
  | 'yellow'
  | 'lightYellow'
  | 'gray'
  | 'vermilion'

export type FillTypeColor = 'color' | 'white' | 'transparent'

export type ReportStatusMap = {
  [key in ReportStatusEnum]: {
    badgeColor: BadgeColor
    fillTypeColor: FillTypeColor
    title: ReportStatusTitle
  }
}

export const reportStatusMap: ReportStatusMap = {
  [ReportStatusEnum.Waiting]: {
    badgeColor: 'vermilion',
    fillTypeColor: 'color',
    title: '報告待ち',
  },
  [ReportStatusEnum.LeftHalfway]: {
    badgeColor: 'vermilion',
    fillTypeColor: 'color',
    title: '報告中断',
  },
  [ReportStatusEnum.Reported]: {
    badgeColor: 'green04',
    fillTypeColor: 'color',
    title: '報告済み',
  },
  [ReportStatusEnum.ImageResent]: {
    badgeColor: 'lightGreen',
    fillTypeColor: 'color',
    title: '再送済み',
  },
  [ReportStatusEnum.FinalizedReportSubmitted]: {
    badgeColor: 'lightYellow',
    fillTypeColor: 'color',
    title: '確定済み',
  },
  [ReportStatusEnum.FinalizedReportSynced]: {
    badgeColor: 'gray',
    fillTypeColor: 'color',
    title: '連携済み',
  },
}

export const getReportStatusTitle = (role?: ReportStatusEnum): string => {
  return role ? reportStatusMap[role].title ?? '' : ''
}

export const getReportStatusBadgeColor = (
  status?: ReportStatusEnum
): BadgeColor => {
  return status ? reportStatusMap[status].badgeColor ?? 'gray' : 'gray'
}

export const getReportStatusFillTypeColor = (
  status?: ReportStatusEnum
): FillTypeColor => {
  return status
    ? reportStatusMap[status].fillTypeColor ?? 'transparent'
    : 'transparent'
}

export const statusCheckOptions: {
  label: string
  value: ReportStatusEnum
}[] = [
  {
    label: getReportStatusTitle(ReportStatusEnum.Waiting),
    value: ReportStatusEnum.Waiting,
  },
  {
    label: getReportStatusTitle(ReportStatusEnum.LeftHalfway),
    value: ReportStatusEnum.LeftHalfway,
  },
  {
    label: getReportStatusTitle(ReportStatusEnum.Reported),
    value: ReportStatusEnum.Reported,
  },
  {
    label: getReportStatusTitle(ReportStatusEnum.ImageResent),
    value: ReportStatusEnum.ImageResent,
  },
  {
    label: getReportStatusTitle(ReportStatusEnum.FinalizedReportSubmitted),
    value: ReportStatusEnum.FinalizedReportSubmitted,
  },
  {
    label: getReportStatusTitle(ReportStatusEnum.FinalizedReportSynced),
    value: ReportStatusEnum.FinalizedReportSynced,
  },
]
