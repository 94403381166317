import React from 'react'
import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { Tenant } from '../../../../slices/services/api'

export type TenantNameProps = {
  tenant?: Tenant
} & React.StyledProps

const TenantName: React.StyledFC<TenantNameProps> = ({
  className,
  tenant,
}: TenantNameProps) => {
  return (
    <Box className={clsx(className, styles.tenantTypography)}>
      <Typography className={styles.code}>{tenant?.code}</Typography>
      <Typography className={styles.name}>{tenant?.name}</Typography>
    </Box>
  )
}

export default TenantName
