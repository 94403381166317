import React, { ReactElement, useContext, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CalculationTabTemplate from '../../components/templates/CalculationTab'
import {
  getCalculationDSLs,
  PathCalculationMethodsParams,
  selectCalculationDSLsRequestStateByParams,
  selectDenormalizedCalculationDSLsByParams,
  updateCalculationMethods,
} from '../../slices/calculationDSLs/calculationDSLsSlice'
import Path, { TenantPathParams } from '../../routes/path'
import { useAppDispatch, useAppSelector } from '../../store'
import Presenter, {
  calculationRoundingTypeOptions,
  precisionOptions,
} from './presenter'
import { ToastTriggerContext } from '../../context/toast.context'
import {
  CalculationMethodPatchRequest,
  CalculationRoundingTypeEnum,
} from '../../slices/services/api'

const CalculationTab: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const toastContext = useContext(ToastTriggerContext)

  const params = useParams<TenantPathParams>() as TenantPathParams

  const role = useAppSelector((state) => state.auth.currentAuthorization?.role)
  const dsls = useAppSelector(selectDenormalizedCalculationDSLsByParams(params))
  const { status, lastUpdatedAt, calculationMethod } = useAppSelector(
    selectCalculationDSLsRequestStateByParams(params)
  )

  // API Request
  useEffect(() => {
    if (status === 'idle') {
      dispatch(getCalculationDSLs(params))
    }
  }, [status, params, dispatch])

  const handleEditCalculationMethod = async (
    roundingTypeText: CalculationRoundingTypeEnum,
    precision: number
  ) => {
    if (!calculationMethod) {
      return
    }

    const calculationMethods: CalculationMethodPatchRequest[] = [
      {
        id: calculationMethod?.id,
        tenantId: calculationMethod?.tenantId,
        roundingType: roundingTypeText,
        precision,
      },
    ]
    const updateCalculationMethodsParam: PathCalculationMethodsParams = {
      orgCode: params.orgCode,
      storeCode: params.storeCode,
      tenantCode: params.tenantCode,
      calculationMethods,
    }

    try {
      await dispatch(
        updateCalculationMethods(updateCalculationMethodsParam)
      ).unwrap()

      toastContext.sendToast({
        variant: 'success',
        title: '端数処理の更新をしました。',
      })
    } catch (error) {
      toastContext.sendToast({
        variant: 'error',
        title: '端数処理の更新に失敗しました。',
      })
    }
  }

  const tableData = useMemo(() => {
    return Presenter.convertToTableData(dsls ?? [])
  }, [dsls])

  const handleClickButton = () => {
    navigate(
      Path.startEditCalculation(
        params.orgCode,
        params.storeCode,
        params.tenantCode
      )
    )
  }

  return (
    <CalculationTabTemplate
      onClickEditButton={handleClickButton}
      onClickEditCalculationMethod={handleEditCalculationMethod}
      isLoading={status === 'loading'}
      columns={tableData.columns}
      records={tableData.records}
      role={role}
      calculationMethod={calculationMethod}
      roundingTypeOptions={calculationRoundingTypeOptions}
      precisionOptions={precisionOptions}
      lastUpdatedAt={lastUpdatedAt}
    />
  )
}

export default CalculationTab
