import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import { RejectedAction } from '../../store'

interface SnackbarState {
  message: string | null
  variant: 'success' | 'error' | null
}

export const initialState: SnackbarState = {
  message: null,
  variant: null,
}

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    success: (_, { payload }: PayloadAction<string>) => {
      return {
        message: payload,
        variant: 'success',
      }
    },
    error: (_, { payload }: PayloadAction<string>) => {
      return {
        message: payload,
        variant: 'error',
      }
    },
    clear: () => {
      return {
        message: null,
        variant: null,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher<RejectedAction>(
      (action) => action.type.endsWith('/rejected'),
      (state, { error }) => {
        if (error.showSnackbar) {
          return {
            message: error.message,
            variant: 'error',
          }
        }
        return state
      }
    )
  },
})

export const { success, error, clear } = snackbarSlice.actions
export default snackbarSlice.reducer
