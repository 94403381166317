import React, { memo } from 'react'
import { TableCell } from '@mui/material'
import clsx from 'clsx'
import cellStyles from '../styles.module.scss'

export type ChildCellProps = React.StyledProps & React.ChildrenProps

export const ChildCell: React.StyledFC<ChildCellProps> = memo(
  ({ className, children }: ChildCellProps) => {
    return (
      <TableCell className={clsx(className, cellStyles.cell)}>
        {children}
      </TableCell>
    )
  }
)

ChildCell.displayName = 'ChildCell'
