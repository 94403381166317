/* eslint-disable no-param-reassign */
import { Selector } from 'react-redux'
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { denormalize, normalize } from 'normalizr'
import { PaginatedRequestState } from '../../domain/request'
import {
  NormalizedStoreSliceReportSummary,
  storeSalesReportSummaryEntity,
} from '../../domain/schema'
// eslint-disable-next-line import/no-cycle
import { AppThunkConfig, RootState } from '../../store'
import { purge } from '../../store/action'
import toKey from '../../utils/key'
import { Store, StoreSalesReportSummary } from '../services/api'
import serializeError from '../services/error'
import AuthenticatedApi from '../services/authenticatedApi'

interface StoreSalesReportSummariesState
  extends EntityState<NormalizedStoreSliceReportSummary> {
  query: {
    [code: string]: PaginatedRequestState
  }
}

const storeSalesReportSummariesAdapter =
  createEntityAdapter<NormalizedStoreSliceReportSummary>({
    selectId: (summary) => summary.storeIdDate,
  })

export const initialState: StoreSalesReportSummariesState =
  storeSalesReportSummariesAdapter.getInitialState({
    query: {},
  })

type GetStoreSalesReportSummariesParams = {
  orgCode: string
  date: string
  q?: string
}

interface GetStoreSalesReportSummariesEntities {
  storeSalesReportSummaries: Record<string, NormalizedStoreSliceReportSummary>
  stores: Record<string, Store>
}

interface GetStoreSalesReportSummariesReturned {
  entities: GetStoreSalesReportSummariesEntities
}

export const getStoreSalesReportSummaries = createAsyncThunk<
  GetStoreSalesReportSummariesReturned,
  GetStoreSalesReportSummariesParams,
  AppThunkConfig
>(
  'storeSalesReportSummaries/getStoreSalesReportSummaries',
  async (params, { getState }) => {
    const { auth } = getState()
    const response = await new AuthenticatedApi(
      auth.token
    ).getOrganizationsOrganizationCodeStoreSalesReportSummaries(
      params.orgCode,
      params.date,
      params.date,
      params.q
    )

    const mappedResponse = response.data.map((data) => {
      const date = dayjs(data.date).format('YYYY-MM-DD')
      return {
        ...data,
        storeIdDate: `${data.store.id}-${date}`,
      }
    })

    const normalized = normalize<
      NormalizedStoreSliceReportSummary,
      GetStoreSalesReportSummariesEntities
    >(mappedResponse, [storeSalesReportSummaryEntity])

    return {
      entities: normalized.entities,
    }
  },
  { serializeError }
)

const slice = createSlice({
  name: 'storeSalesReportSummaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(getStoreSalesReportSummaries.pending, (state, { meta }) => {
      return {
        ...state,
        query: {
          ...state.query,
          [toKey(meta.arg)]: {
            status: 'loading',
          },
        },
      }
    })
    builder.addCase(
      getStoreSalesReportSummaries.fulfilled,
      (state, { payload, meta }) => {
        const { entities } = payload
        const key = toKey(meta.arg)

        if (entities.storeSalesReportSummaries === undefined) {
          state.query[key] = {
            status: 'succeeded',
          }
          return
        }

        storeSalesReportSummariesAdapter.upsertMany(
          state,
          entities.storeSalesReportSummaries ?? {}
        )

        state.query[key] = {
          status: 'succeeded',
          ids: Object.keys(entities.storeSalesReportSummaries),
        }
      }
    )
    builder.addCase(
      getStoreSalesReportSummaries.rejected,
      (state, { error, meta }) => {
        return {
          ...state,
          query: {
            ...state.query,
            [toKey(meta.arg)]: {
              status: 'failed',
              error,
            },
          },
        }
      }
    )
  },
})

export default slice.reducer

export const {
  selectById: selectStoreSalesReportSummaryById,
  selectEntities: selectStoreSalesReportSummaryEntities,
  selectAll: selectAllStoreSalesReportSummaries,
} = storeSalesReportSummariesAdapter.getSelectors<StoreSalesReportSummariesState>(
  (state) => state
)

export const selectStoreSalesReportSummariesByParams = (
  params: GetStoreSalesReportSummariesParams
): Selector<RootState, NormalizedStoreSliceReportSummary[] | undefined> => {
  const key = toKey(params)
  return createSelector(
    [(state) => state.entities.storeSalesReportSummaries],
    (state) => {
      if (!state.query[key]) {
        return undefined
      }
      const entities = selectStoreSalesReportSummaryEntities(state)
      /* eslint-disable @typescript-eslint/no-explicit-any */
      return state.query[key].ids
        ?.map((id: any) => entities[id])
        .filter(
          (entity: any): entity is NormalizedStoreSliceReportSummary =>
            entity !== undefined
        )
      /* eslint-enable */
    }
  )
}

export const selectStoreSalesReportSummariesRequestStateByParams = (
  params: GetStoreSalesReportSummariesParams
): Selector<RootState, PaginatedRequestState> => {
  return createSelector(
    [(state) => state.entities.storeSalesReportSummaries],
    (state) => {
      return (
        state.query[toKey(params)] ?? {
          status: 'idle',
        }
      )
    }
  )
}

export const selectDenormalizedStoreSalesReportSummariesByParams = (
  params: GetStoreSalesReportSummariesParams
): Selector<RootState, StoreSalesReportSummary[] | undefined> => {
  return createSelector(
    [
      (state) => state.entities.storeSalesReportSummaries,
      (state) => state.entities.stores,
    ],
    (state, stores) => {
      const ids = state.query[toKey(params)]?.ids
      if (!ids) {
        return undefined
      }
      return denormalize(ids, [storeSalesReportSummaryEntity], {
        storeSalesReportSummaries: state.entities,
        stores: stores.entities,
      })
    }
  )
}
