import React, { useCallback } from 'react'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'
import {
  CalculationMethod,
  CalculationRoundingTypeEnum,
  RoleEnum,
} from '../../../slices/services/api'
import tabContentStyle from '../../../pages/TenantDetail/tabContentStyle'
import Button from '../../atoms/Button'
import CenteringLoading from '../../molecules/CenteringLoading'
import OCRFormatTable, {
  OCRFormatTableProps,
} from '../../molecules/OCRFormatTable'
import SmallGuide from '../../molecules/SmallGuide'
import {
  AdminAdminSalesReportManagerEditorSalesReportManagerEditorStoreManagerInOperationCenterRoles,
  isOneOfRoles,
} from '../../../domain/role'
import EditCalculationMethod from '../../molecules/EditCalculationMethod'
import { Option } from '../../molecules/SelectWithOption'

export type OCRFormatTabProps = {
  role?: RoleEnum
  calculationMethod?: CalculationMethod
  roundingTypeOptions: Option[]
  precisionOptions: Option[]
  lastUpdatedAt?: string
  isLoading: boolean
  onClickEditButton: () => void
  onClickEditCalculationMethod: (
    roundingType: CalculationRoundingTypeEnum,
    precision: number
  ) => void
} & Partial<Pick<OCRFormatTableProps, 'className' | 'columns' | 'records'>>

const OCRFormatTab: React.StyledFC<OCRFormatTabProps> = ({
  className,
  isLoading,
  calculationMethod,
  roundingTypeOptions,
  precisionOptions,
  role,
  lastUpdatedAt,
  onClickEditButton,
  onClickEditCalculationMethod,
  columns = [],
  records = [],
}: OCRFormatTabProps) => {
  const tabClasses = tabContentStyle()
  const isViewer = role === RoleEnum.Viewer

  const showSwitchByRole = useCallback((): boolean => {
    return isOneOfRoles(
      AdminAdminSalesReportManagerEditorSalesReportManagerEditorStoreManagerInOperationCenterRoles,
      role
    )
  }, [role])

  const renderContent = (data: OCRFormatTableProps['records']) => {
    return data.length > 0 ? (
      <>
        <Box
          className={clsx(className, tabClasses.calcMethodWithLastUpdatedAt)}
        >
          <EditCalculationMethod
            calculationMethod={calculationMethod}
            roundingTypeOptions={roundingTypeOptions}
            precisionOptions={precisionOptions}
            onClickEditCalculationMethod={onClickEditCalculationMethod}
          />
          {lastUpdatedAt && (
            <Box className={tabClasses.lastUpdatedAt}>
              <Typography variant="subtitle2">最終更新日時</Typography>
              <Typography>{lastUpdatedAt.toLocaleString()}</Typography>
            </Box>
          )}
        </Box>
        <OCRFormatTable
          className={tabClasses.table}
          columns={columns}
          records={data}
        />
        {showSwitchByRole() && (
          <Button
            className={tabClasses.editButton}
            title="対応表を編集"
            color="primary"
            size="large"
            onClick={onClickEditButton}
          />
        )}
      </>
    ) : (
      <SmallGuide
        icon="pray"
        message="利用準備がされていません"
        description="まずは登録して利用開始の準備をしましょう" // TODO: view の場合のテキストへの変更
        buttonTitle={isViewer ? '' : '設定'}
        onClick={onClickEditButton}
      />
    )
  }

  return (
    <Box
      className={clsx(
        tabClasses.root,
        records.length === 0 && tabClasses.guideRoot
      )}
    >
      {isLoading ? <CenteringLoading /> : renderContent(records)}
    </Box>
  )
}

export default OCRFormatTab
