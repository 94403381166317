import React, { useEffect, useState } from 'react'
import { Box, Table, TableBody } from '@mui/material'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { AppTableHead, Column } from '../../../../components/tables'
import SmallGuide from '../../../../components/molecules/SmallGuide'

import TenantSalesReportTableRow from '../TenantSalesReportTableRow'
import styles from './styles.module.scss'
import { FormNameEnum, RowWithSum } from '../../type'

export type TenantSalesReportTableProps = {
  columns: Column[]
  registerIndex: number
  rows: RowWithSum[]
  onClickValue: (value: string, index: number, childIndex?: number) => void
} & React.StyledProps

const TenantSalesReportTable: React.StyledFC<TenantSalesReportTableProps> = ({
  className,
  columns,
  registerIndex,
  rows,
  onClickValue,
}: TenantSalesReportTableProps) => {
  const { watch } = useFormContext()
  const [allFiltered, setAllFiltered] = useState(false)
  const filterForMismatch = watch(FormNameEnum.filterForMismatch)

  useEffect(() => {
    let allRowsFiltered = false
    if (filterForMismatch) {
      const filteredRows = rows.filter((row) => Boolean(row.error))
      if (filteredRows.length === 0 && rows.length > 0) {
        allRowsFiltered = true
      }
    }
    setAllFiltered(allRowsFiltered)
  }, [filterForMismatch, rows])

  return (
    <Box className={clsx(className, styles.root)}>
      {allFiltered ? (
        <Box className={styles.allFiltered}>
          <SmallGuide
            icon="kirakira"
            className={styles.guide}
            message="不一致の項目はありません"
            description="報告結果を確認する場合は、フィルタを外して下さい"
          />
        </Box>
      ) : (
        <Table className={styles.table} stickyHeader>
          <AppTableHead columns={columns} />
          <TableBody>
            {rows.map((row, index) => (
              <TenantSalesReportTableRow
                key={row.key}
                className={
                  !Boolean(row.error) && filterForMismatch
                    ? styles.collapseRow
                    : ''
                }
                cells={row.cells}
                inputCell={row.inputCell}
                sumCell={row.sumCell}
                tooltipText={row.tooltipText}
                rowIndex={index}
                registerIndex={registerIndex}
                onClickValue={(value, childIndex) =>
                  onClickValue(value, index, childIndex)
                }
                childRows={row.childRows}
                error={row.error}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  )
}

export default TenantSalesReportTable
