import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { MenuItem, Select, TableCell } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import clsx from 'clsx'
import { Option } from 'src/domain/tenant'
import { Color } from 'src/AppTypes'
import cellStyles from '../styles.module.scss'
import styles from './styles.module.scss'
import Icon from '../../../atoms/Icon'

export type SelectCellProps = {
  name: string
  options: Option[]
  defaultValue?: string
  placeholder?: string
  width?: number
  disabled?: boolean
} & React.StyledProps

export const SelectCell = React.memo<SelectCellProps>(
  ({
    className,
    style,
    name,
    options,
    defaultValue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    placeholder,
    width,
    disabled,
  }: SelectCellProps) => {
    const { control, register, setValue } = useFormContext()

    const handleChange = (event: SelectChangeEvent) => {
      setValue(name, event.target.value)
    }

    // FIXME: 色を変える処理は Rendering に関わるので、実装方法を変更する
    const [color, setColor] = React.useState<Color>(() => {
      if (!defaultValue) return 'primary'
      const option = options.find((o) => o.value === defaultValue)
      return option?.color || 'primary'
    })

    return (
      <TableCell
        className={clsx(className, cellStyles.cell)}
        style={{ ...style }}
      >
        <Controller
          control={control}
          name={name}
          disabled={disabled}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Select
              {...field}
              {...register(name)}
              style={{ width }}
              className={clsx(styles.select, styles[color])}
              classes={{
                disabled: styles.disabled,
              }}
              sx={{
                '& .MuiSelect-select .notranslate::after': placeholder
                  ? {
                      content: `"${placeholder}"`,
                    }
                  : {},
              }}
              defaultValue={defaultValue}
              inputProps={{
                'aria-label': 'Without label',
                className: styles.input,
                classes: {
                  outlined: styles.input,
                },
              }}
              displayEmpty
              MenuProps={{
                classes: {
                  paper: styles.menu,
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              // eslint-disable-next-line react/no-unstable-nested-components
              IconComponent={(props) => (
                <Icon
                  className={clsx(
                    styles.icon,
                    styles[color],
                    `material-icons ${props.className}`
                  )}
                  icon="arrowDropDown"
                />
              )}
              onChange={handleChange}
            >
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  className={styles.option}
                  classes={{
                    selected: styles.selected,
                  }}
                  onClick={() => setColor(option.color || 'primary')}
                  disabled={option.disabled}
                >
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </TableCell>
    )
  }
)

SelectCell.displayName = 'SelectCell'
