import TokenDisplay from '.'

export default class FirstCharacterDisplay implements TokenDisplay {
  str: string

  constructor(str: string) {
    this.str = str
  }

  display(): string {
    return this.str.substr(0, 1)
  }
}
