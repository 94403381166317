interface ColorsType {
  [key: string]: string
}

const hoverColor = (baseColor: string): string => {
  return `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${baseColor}`
}

const baseColors: ColorsType = {
  primary: '#121f98',
  lightBlue: '#d3daff',
  red: '#ff0000',
  red40: 'rgba(255, 0, 0, 0.04)',
  vermilion: '#e3594a',
  vermilion02: '#fb3c3c',
  lightOrange: '#fadccc',
  green: '#16ce8c',
  green02: '#4ae3ac',
  green03: '#effcf7',
  green04: '#00bc79',
  darkGreen: '#008f5c',
  lightGreen: '#c2f0d5',
  yellow: '#ffd896',
  lightYellow: '#fff6e1',
  darkYellow: '#c17503',
  gray01: '#f8f5f1',
  gray02: '#eeeae5',
  gray03: '#b7b7b7',
  gray04: '#5e5e5e',
  grayHover: '#cccccc',
  brown: '#7b2c00',
  black: '#000000',
  black50: 'rgba(0, 0, 0, 0.05)',
  black100: 'rgba(0, 0, 0, 0.1)',
  black200: 'rgba(0, 0, 0, 0.2)',
  black300: 'rgba(0, 0, 0, 0.3)',
  black430: 'rgba(0, 0, 0, 0.43)',
  black400: 'rgba(0, 0, 0, 0.4)',
  black500: 'rgba(0, 0, 0, 0.5)',
  white: '#ffffff',
  lightGray: '#e1e1e1',
  lightGray02: '#f1f3f4',
  lightGray03: '#f9f9f9',
  lightGray04: '#eeeeee',
  lightGray00: '#f8f9fc',
  lightGray300: 'rgba(225, 225, 225, 0.3)',
  error: '#ce0000',
  unavailable: '#969696',
}

const hoverColors: ColorsType = Object.keys(baseColors).reduce(
  (accumulator, current) => {
    return {
      ...accumulator,
      [`${current}Hover`]: hoverColor(baseColors[current]),
    }
  },
  {}
)

export default { ...baseColors, ...hoverColors }
