import React from 'react'
import clsx from 'clsx'
import { TableRow } from '@mui/material'
import styles from './styles.module.scss'

export type AppTableRowProps = {
  disabled?: boolean
  onClick?: () => void
} & React.StyledProps &
  React.ChildrenProps

export const AppTableRow: React.StyledFC<AppTableRowProps> = ({
  className,
  children,
  disabled,
  onClick,
}: AppTableRowProps) => {
  return (
    <TableRow
      className={clsx(className, styles.row, disabled && styles.disabled)}
      onClick={() => {
        if (!disabled && onClick) onClick()
      }}
    >
      {children}
    </TableRow>
  )
}
