import React, { ChildrenProps } from 'react'
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material'
import clsx from 'clsx'

import CircularProgress from '../../atoms/CircularProgress'
import style from './styles.module.scss'

export type LoadingModalProps = {
  title: string
  content: React.ReactNode
  open: boolean
} & React.StyledProps &
  ChildrenProps

const LoadingModal: React.StyledFC<LoadingModalProps> = ({
  className,
  title,
  content,
  open,
}: LoadingModalProps) => {
  return (
    <Dialog
      classes={{
        paper: clsx(style.dialog, className),
      }}
      open={open}
      data-testid="modal"
    >
      <DialogTitle className={style.title}>{title}</DialogTitle>
      <DialogContent className={style.content}>{content}</DialogContent>
      <Box className={style.progress}>
        <CircularProgress />
      </Box>
    </Dialog>
  )
}

export default LoadingModal
