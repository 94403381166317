import React from 'react'
import { TableCell } from '@mui/material'
import clsx from 'clsx'
import cellStyles from '../styles.module.scss'
import { IconName } from '../../../atoms/Icon'

import CircleWithIcon, { Color } from '../../../molecules/CircleWithIcon'

export type CircleWithIconCellProps = {
  icon: IconName
  size: number
  color: Color
} & React.StyledProps

export const CircleWithIconCell = React.memo(
  ({ className, style, icon, size, color }: CircleWithIconCellProps) => {
    return (
      <TableCell className={clsx(className, cellStyles.cell)} style={style}>
        <CircleWithIcon icon={icon} size={size} color={color} />
      </TableCell>
    )
  }
)

CircleWithIconCell.displayName = 'CircleWithIconCell'
