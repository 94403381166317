import { CalculationItem, ReadItem } from './calculation_dsl'
import Token, { EOFToken, ActualToken } from './token'
import FirstCharacterDisplay from './token_display/first_character_display'
import ReplaceWithIdDisplay from './token_display/replace_with_id_display'
import SimpleDisplay from './token_display/simple_display'
import TokenType from './token_type'

export default class TokenFactory {
  readItems: ReadItem[]

  calculationItems: CalculationItem[]

  constructor(readItems: ReadItem[], calcItems: CalculationItem[]) {
    this.readItems = readItems
    this.calculationItems = calcItems
  }

  createToken(type: TokenType, raw: string): Token {
    switch (type) {
      case TokenType.EOF:
        return new EOFToken()
      case TokenType.Operation:
      case TokenType.Number:
        return new ActualToken(type, raw, new SimpleDisplay(raw))
      case TokenType.LeftParenthesis:
      case TokenType.RightParenthesis:
        return new ActualToken(type, raw, new FirstCharacterDisplay(raw))
      case TokenType.ReadItem: {
        const display = new ReplaceWithIdDisplay(raw, this.readItems)
        return new ActualToken(type, raw, display)
      }
      case TokenType.CalculationItem: {
        const display = new ReplaceWithIdDisplay(raw, this.calculationItems)
        return new ActualToken(type, raw, display)
      }
      default:
        throw new Error('Invalid token type')
    }
  }
}
