import React from 'react'
import { Box, TableCell, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import color from '../../../styles/color'
import { TABLE_DIVIDER_THICKNESS } from '../../../styles/constants'
import Icon from '../Icon'
import Tooltip from '../Tooltip'

export type CalculationTableHeaderCellProps = {
  text: string
  size: CellSize
  tooltipText?: string
  colSpan?: number
} & React.StyledProps

export type CellSize = 'small' | 'medium' | 'large'

const useStyles = makeStyles({
  cell: {
    height: 40 + TABLE_DIVIDER_THICKNESS,
    boxSizing: 'border-box',
    borderBottom: `${TABLE_DIVIDER_THICKNESS}px solid ${color.lightGray02}`,
    backgroundColor: color.lightBlue,
    whiteSpace: 'nowrap',
  },
  cellContent: {
    display: 'flex',
    alignItems: 'center',
  },
  small: {
    width: 24,
    minWidth: 24,
  },
  medium: {
    width: 40,
    minWidth: 40,
    padding: '0 10px',
  },
  large: {
    width: 120,
    minWidth: 120,
    padding: '0 8px',
  },
  iconWrapper: {
    height: 19,
    marginLeft: 4,
  },
})

const CalculationTableHeaderCell: React.StyledFC<
  CalculationTableHeaderCellProps
> = ({
  text,
  size,
  tooltipText,
  colSpan,
  className,
}: CalculationTableHeaderCellProps) => {
  const classes = useStyles()

  return (
    <TableCell
      className={clsx(className, classes.cell, classes[size])}
      padding="none"
      colSpan={colSpan}
    >
      <Box className={classes.cellContent}>
        <Typography component="span" variant="h6">
          {text}
        </Typography>

        {tooltipText && (
          <Tooltip title={tooltipText}>
            <div className={classes.iconWrapper}>
              <Icon icon="help" />
            </div>
          </Tooltip>
        )}
      </Box>
    </TableCell>
  )
}

export default CalculationTableHeaderCell
