import React, { ReactElement, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useBeforeUnload from '../../hooks/useBeforeUnload'
import TenantCreateTemplate from '../../components/templates/TenantCreate'
import useAppTitle from '../../hooks/useAppTitle'
import Path, { StorePathParams } from '../../routes/path'
import { useAppDispatch, useAppSelector } from '../../store'
import { Presenter } from './presenter'
import {
  clearEditTenants,
  postTenants,
} from '../../slices/editTenants/editTenantsSlice'
import {
  selectStoreByCode,
  selectStoresRequestStateByParams,
  getStore,
} from '../../slices/stores/storesSlice'
import { TenantFormType } from '../../components/organisms/TenantCreateTable'
import { ToastTriggerContext } from '../../context/toast.context'
import { STORE_TENANTS_PER_PAGE } from '../../constants'
import { clearStoreTenants } from '../../slices/tenants/tenantsSlice'

const CreateTenants: React.FC = (): ReactElement => {
  useAppTitle('新規テナント作成')
  useBeforeUnload()
  const { orgCode, storeCode } = useParams() as StorePathParams
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const toastContext = useContext(ToastTriggerContext)

  const storedParams = useAppSelector((state) => state.query.storeTenantsParams)
  const storeTenantsParams = {
    orgCode,
    storeCode,
    page: storedParams.page,
    q: storedParams.q,
    status: storedParams.status,
    trained: storedParams.trained,
    perPage: STORE_TENANTS_PER_PAGE,
  }
  const store = useAppSelector(selectStoreByCode({ orgCode, storeCode }))
  const storeRequestState = useAppSelector(
    selectStoresRequestStateByParams({ orgCode, storeCode })
  )
  useEffect(() => {
    if (store === undefined && storeRequestState.status === 'idle') {
      dispatch(getStore({ orgCode, storeCode }))
    }
  }, [dispatch, orgCode, store, storeCode, storeRequestState.status])

  const handleSubmit = async (values: TenantFormType) => {
    try {
      const createdTenants = Presenter.requestBody(values)
      await dispatch(
        postTenants({ orgCode, storeCode, tenants: createdTenants })
      ).unwrap()
      dispatch(clearEditTenants())
      dispatch(clearStoreTenants(storeTenantsParams))
      navigate(Path.store(orgCode, storeCode))
      toastContext.sendToast({
        variant: 'success',
        title: '作成に成功しました',
      })
    } catch (error) {
      toastContext.sendToast({
        variant: 'error',
        title: '作成が失敗しました',
      })
    }
  }

  return (
    <TenantCreateTemplate
      orgCode={orgCode}
      title="新規テナント追加"
      descriptions={[
        '「利用停止」に変更した場合、変更完了した時点からテナントのアプリが利用できなくなります',
        '同一店舗の対して、同一のテナントコードのテナントを追加することができません',
      ]}
      storeName={store?.name || ''}
      storeCode={store?.code || ''}
      submitButtonText="追加する"
      onClickBack={() => navigate(Path.store(orgCode, storeCode))}
      onSubmit={handleSubmit}
    />
  )
}

export default CreateTenants
