import axios, { AxiosInstance } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'

export const axiosInstance: AxiosInstance = (() => {
  const instance = axios.create({
    headers: { 'Content-Type': 'application/json' },
  })
  instance.interceptors.request.use((config) => {
    const result = { ...config }
    if (config.data) {
      result.data = decamelizeKeys(JSON.parse(config.data))
    }
    return result
  })
  instance.interceptors.response.use((response) => {
    if (response.data) {
      response.data = camelizeKeys(response.data)
    }
    return response
  })
  return instance
})()
