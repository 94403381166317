import React from 'react'
import { Breadcrumbs, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import colors from '../../../styles/color'

export type BreadcrumbsPipeProps = {
  paths: Path[]
} & React.StyledProps

export type Path = {
  index: number
  title: string
}

const useStyles = makeStyles({
  ol: {
    height: 14,
    lineHeight: '14px',
  },
  separator: {
    width: 12,
    margin: '0 10px',
    color: colors.black,
  },
  typography: {
    lineHeight: '14px',
  },
})

const BreadcrumbsPipe: React.StyledFC<BreadcrumbsPipeProps> = ({
  paths,
  className,
}: BreadcrumbsPipeProps) => {
  const classes = useStyles()

  return (
    <Breadcrumbs
      className={className}
      aria-label="breadcrumb"
      classes={{ ol: classes.ol, separator: classes.separator }}
      separator={
        <Typography variant="body2" component="span">
          ｜
        </Typography>
      }
    >
      {paths.map((path) => (
        <Typography
          color="textPrimary"
          component="span"
          variant="body2"
          key={path.index}
          className={classes.typography}
        >
          {path.title}
        </Typography>
      ))}
    </Breadcrumbs>
  )
}

export default BreadcrumbsPipe
