import { schema } from 'normalizr'
import {
  CalculationDSL,
  CalculationGroup,
  CalculationItem,
  OCRFormat,
  SalesReportWithTenant,
  Store,
  StoreSalesReportSummary as StoreSalesReportSummaryResponse,
  Tenant,
  TenantSalesReportSummary,
} from '../slices/services/api'

export interface NormalizedEntities<E> {
  [key: string]: {
    [key: string]: E
  }
}

export const storeEntity = new schema.Entity<Store>('stores')

export const calculationItemEntity = new schema.Entity<CalculationItem>(
  'calculationItems'
)

export const ocrFormatEntity = new schema.Entity<OCRFormat>('ocrFormats')

export type NormalizedCalculationDSL = Omit<
  CalculationDSL,
  'ocrFormats' | 'calculationItem'
> & {
  ocrFormats: string[]
  calculationItem: string
}

export const calculationDSLEntity = new schema.Entity<CalculationDSL>(
  'calculationDSLs',
  {
    ocrFormats: [ocrFormatEntity],
    calculationItem: calculationItemEntity,
  }
)

export type NormalizedCalculationGroup = Omit<
  CalculationGroup,
  'calculationItems'
> & {
  calculationItems: string[]
}

export const calculationGroupEntity = new schema.Entity<CalculationGroup>(
  'calculationGroups',
  {
    calculationItems: [calculationItemEntity],
  }
)

export type NormalizedTenant = Tenant & {
  store?: string
}

export const tenantEntity = new schema.Entity<Tenant>('tenants', {
  store: storeEntity,
})

export type NormalizedSalesReportEntity = Omit<
  SalesReportWithTenant,
  'tenant'
> & {
  tenant: string
}

export const salesReportEntity = new schema.Entity<SalesReportWithTenant>(
  'salesReports',
  {
    tenant: tenantEntity,
  }
)

// NOTE: StoreSalesReportSummary に id がないため、storeId と日付のペアを ID とする
// `${uuid}-${date}`
export type StoreSalesReportSummary = StoreSalesReportSummaryResponse & {
  storeIdDate: string
}

export type NormalizedStoreSliceReportSummary = Omit<
  StoreSalesReportSummaryResponse,
  'store'
> & {
  storeIdDate: string
}

export const storeSalesReportSummaryEntity =
  new schema.Entity<StoreSalesReportSummary>(
    'storeSalesReportSummaries',
    {
      store: storeEntity,
    },
    {
      idAttribute: 'storeIdDate',
    }
  )

export type NormalizedTenantSalesReportSummary = Omit<
  TenantSalesReportSummary,
  'tenant'
> & {
  tenant: string
}

export const tenantSalesReportSummaryEntity =
  new schema.Entity<TenantSalesReportSummary>('tenantSalesReportSummaries', {
    tenant: tenantEntity,
  })
