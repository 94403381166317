import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RequestState } from '../../domain/request'
// eslint-disable-next-line import/no-cycle
import { AppThunkConfig } from '../../store'
import { purge } from '../../store/action'
import AuthenticatedApi from '../services/authenticatedApi'
import serializeError from '../services/error'

export const initialState: RequestState = {
  status: 'idle',
}

export type PatchMemberName = {
  name: string
}

export type patchParam = {
  orgCode: string
  patchMemberName: PatchMemberName
}

export const patchProfile = createAsyncThunk<void, patchParam, AppThunkConfig>(
  'profile/patchMemberName',
  async (params, { getState }) => {
    const { auth } = getState()
    await new AuthenticatedApi(auth.token).patchProfile(
      params.orgCode,
      params.patchMemberName
    )
  },
  {
    serializeError,
  }
)

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    clearProfileStatus: (): RequestState => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(patchProfile.pending, (state) => {
      return {
        ...state,
        status: 'loading',
      }
    })
    builder.addCase(patchProfile.fulfilled, () => {
      return {
        ...initialState,
        status: 'succeeded',
      }
    })
    builder.addCase(patchProfile.rejected, (state, { error }) => {
      return {
        ...state,
        status: 'failed',
        error,
      }
    })
  },
})

export const { clearProfileStatus } = slice.actions
export default slice.reducer
