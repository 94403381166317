import React, { ReactElement, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMount } from 'react-use'
import useBeforeUnload from 'src/hooks/useBeforeUnload'
import { trimEmptyString } from '../../../../utils/object'
import TenantDetailTabTemplate from '../../templates/TenantDetailTab'
import EditTenantDetailTemplate from '../../templates/EditTenantDetail'
import { TenantDetail } from '../../../../slices/services/api'
import { TenantPathParams } from '../../../../routes/path'
import { useApiTenantDetail } from '../../../../hooks/useApiTenantDetail'
import { ToastTriggerContext } from '../../../../context/toast.context'

const TenantDetailTab: React.FC = (): ReactElement => {
  const [tenantDetail, setTenantDetail] = useState<TenantDetail>()
  const [isLoaded, setLoaded] = useState<boolean>(false)
  const { orgCode, storeCode, tenantCode } =
    useParams<TenantPathParams>() as TenantPathParams
  const { getTenantDetail, postTenantDetail } = useApiTenantDetail()
  const toastContext = useContext(ToastTriggerContext)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const fetchTenantDetail = async () => {
    const result = await getTenantDetail(orgCode, storeCode, tenantCode)
    if (!result.success) {
      toastContext.sendToast({
        variant: 'error',
        title: result.message,
      })
      return
    }
    setTenantDetail(result.data)
    setLoaded(true)
  }

  useMount(fetchTenantDetail)

  const handlePost = async (formData: TenantDetail) => {
    const updatedTenantDetail = trimEmptyString(formData)
    const result = await postTenantDetail(
      orgCode,
      storeCode,
      tenantCode,
      updatedTenantDetail
    )
    if (!result.success) {
      toastContext.sendToast({
        variant: 'error',
        title: result.message,
      })
      return
    }
    toastContext.sendToast({
      variant: 'success',
      title: `テナント詳細情報の更新が完了しました`,
    })
    setIsEditing(false)
    setTenantDetail(formData)
  }

  useBeforeUnload(isEditing)

  return isEditing ? (
    <EditTenantDetailTemplate
      tenantDetail={tenantDetail}
      orgCode={orgCode}
      onCancelEdit={() => setIsEditing(false)}
      onSubmit={handlePost}
    />
  ) : (
    <TenantDetailTabTemplate
      tenantDetail={tenantDetail}
      isLoaded={isLoaded}
      orgCode={orgCode}
      onStartEdit={() => setIsEditing(true)}
    />
  )
}

export default TenantDetailTab
