import React, {
  ReactElement,
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useBeforeUnload } from 'react-use'
import { toAscii, toHalfwidthKana } from 'japanese-string-utils'
import { Path as BreadcrumbPath } from '../../components/molecules/BreadcrumbsPipe'
import { Option } from '../../components/molecules/SelectWithGroupOption'
import { BodyRow } from '../../components/organisms/EditCalculationDSLTable'
import EditCalculationDSLTemplate from '../../components/templates/EditCalculationDSL'
import {
  getCalculationDSLs,
  selectDenormalizedCalculationDSLsByParams,
  selectCalculationDSLsRequestStateByParams,
} from '../../slices/calculationDSLs/calculationDSLsSlice'
import {
  getCalculationGroup,
  selectCalculationGroupStateByParams,
} from '../../slices/calculationGroups/calculationGroupsSlice'
import { selectCalculationItemsByParams } from '../../slices/calculationItems/calculationItemsSlice'
import {
  clearEditCalculation,
  EditCalculation,
  postTenantsIdCalculationDsl,
} from '../../slices/editCalculations/editCalculationsSlice'
import { OCRFormat } from '../../slices/services/api'
import {
  getStore,
  selectStoresRequestStateByParams,
  selectStoreByCode,
} from '../../slices/stores/storesSlice'
import {
  getTenant,
  selectTenantStateByCode,
  selectTenantByCode,
} from '../../slices/tenants/tenantsSlice'
import useAppTitle from '../../hooks/useAppTitle'
import Path, { TenantPathParams } from '../../routes/path'
import { useAppDispatch, useAppSelector } from '../../store'
import { isEqual } from '../../utils/array'
import { isNumber } from '../../utils/number'
import Presenter, {
  CELL_SET_COUNT,
  generateCellSet,
  RowFieldValue,
} from './presenter'
import { ToastTriggerContext } from '../../context/toast.context'

const EditCalculationDSL: React.FC = (): ReactElement => {
  useAppTitle('利用設定 ステップ3')
  const toastContext = useContext(ToastTriggerContext)
  const { orgCode, storeCode, tenantCode } =
    useParams<TenantPathParams>() as TenantPathParams
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isLoadingRef = useRef<boolean>(true)
  const formMethods = useForm<{
    rows: RowFieldValue[][]
    inputNumber: string
  }>()

  const [initialValues, setInitialValues] = useState<
    (string | number | undefined)[]
  >([])
  useEffect(() => {
    if (!isLoadingRef.current) {
      const values = formMethods
        .getValues()
        .rows?.flatMap((rows) =>
          rows.flatMap((cellSet) => Object.values(cellSet))
        )
      setInitialValues(values)
    }
  }, [formMethods, formMethods.getValues])

  useBeforeUnload(() => {
    const currentValues = formMethods
      .getValues()
      .rows?.flatMap((rows) =>
        rows.flatMap((cellSet) => Object.values(cellSet))
      )
    return !isEqual(initialValues, currentValues)
    // FIXME: 入力した文字列は表示されないけど、文字列は入れないと表示されないので、入れてる
  }, 'Alert')

  const inputName = 'inputNumber'
  // NOTE: selectBox が展開された時に、その値を inputModal へ渡す
  const [selectedInputNumberValue, setSelectedInputNumberValue] = useState<
    number | undefined
  >()
  const [inputCellPosition, setInputCellPosition] = useState<{
    rowIndex: number
    columnSetIndex: number
  }>({ rowIndex: 0, columnSetIndex: 0 })
  const store = useAppSelector(selectStoreByCode({ orgCode, storeCode }))
  const tenant = useAppSelector(
    selectTenantByCode({ orgCode, storeCode, tenantCode })
  )
  const calculationItems = useAppSelector(
    selectCalculationItemsByParams({ orgCode, storeCode })
  )
  const calculationItemsState = useAppSelector(
    (state) => state.entities.calculationItems
  )

  const submitStatus = useAppSelector(
    (state) => state.forms.editCalculations.status
  )

  const { editOCRFormats } = useAppSelector(
    (state) => state.forms.editCalculations
  )

  const dsls = useAppSelector(
    selectDenormalizedCalculationDSLsByParams({
      orgCode,
      storeCode,
      tenantCode,
    })
  )

  const [showWarningModal, setShowWarningModal] = useState(false)
  const [showInputModal, setShowInputModal] = useState(false)
  // NOTE: 初期の header column から、いくつ cell を増やすかを管理
  const [addedCellSetCount, setAddedCellSetCount] = useState(0)
  const [rows, setRows] = useState<BodyRow[]>([])
  const tenantRequestState = useAppSelector(
    selectTenantStateByCode({ orgCode, storeCode, tenantCode })
  )
  const storeRequestState = useAppSelector(
    selectStoresRequestStateByParams({ orgCode, storeCode })
  )
  const calculationGroupRequestState = useAppSelector(
    selectCalculationGroupStateByParams({ orgCode, storeCode })
  )
  const { status } = useAppSelector(
    selectCalculationDSLsRequestStateByParams({
      orgCode,
      storeCode,
      tenantCode,
    })
  )

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbPath[]>([])
  useEffect(() => {
    setBreadcrumbs(Presenter.breadcrumbs(store?.name, tenant?.name))
  }, [store?.name, tenant?.name])

  // utils
  const getInputNumberName =
    useCallback((): `rows.${number}.${number}.value` => {
      return `rows.${inputCellPosition.rowIndex}.${inputCellPosition.columnSetIndex}.value`
    }, [inputCellPosition])

  const convertOCRFormats = (calculationId: string): OCRFormat[] => {
    return editOCRFormats
      .filter((editOCRFormat) => editOCRFormat.calculationId === calculationId)
      .map((editOCRFormat) => {
        return {
          id: editOCRFormat.id,
          readItem: toAscii(
            toHalfwidthKana(
              editOCRFormat.readItem ? editOCRFormat.readItem.trim() : ''
            )
          ),
          isDuplicated: editOCRFormat.isDuplicated ?? false,
          sectionName: editOCRFormat.sectionName,
          suffix: editOCRFormat.suffix,
          prefix: editOCRFormat.prefix,
          readTargetNumberColumn: editOCRFormat.readTargetNumberColumn,
          readTargetNumberLine: editOCRFormat.readTargetNumberLine,
        }
      })
  }

  const getEditCalculations = (): EditCalculation[] => {
    if (!calculationItems) {
      return []
    }
    const convertedDSLs = Presenter.convertFormValuesToDSLs(
      formMethods.getValues()
    )
    return convertedDSLs.map((editDSL, index) => {
      const calculationId = calculationItems[index].id
      const { fixedDsl } = calculationItems[index]
      return {
        calculationId,
        // NOTE: 空の時にデフォルトを入れる
        dsl: editDSL || fixedDsl || '0',
        ocrFormats: convertOCRFormats(calculationId),
      }
    })
  }

  useEffect(() => {
    if (!dsls || !calculationItems || !isLoadingRef.current) return

    const restoredEditCalculationDSLs = Presenter.convertEditDataToFormValues(
      dsls,
      calculationItems,
      editOCRFormats
    )

    const tableData = Presenter.tableData(
      calculationItems,
      editOCRFormats,
      restoredEditCalculationDSLs
    )

    setRows(tableData.rows)
    setAddedCellSetCount(tableData.maxCellSetCount - 1)
    isLoadingRef.current = false
  }, [calculationItems, dsls, editOCRFormats, rows.length])

  // GET API
  useEffect(() => {
    if (tenantRequestState.status === 'idle') {
      dispatch(getTenant({ orgCode, storeCode, tenantCode }))
    }
  }, [dispatch, orgCode, storeCode, tenantCode, tenantRequestState.status])
  useEffect(() => {
    if (store === undefined && storeRequestState.status === 'idle') {
      dispatch(getStore({ orgCode, storeCode }))
    }
  }, [dispatch, orgCode, store, storeCode, storeRequestState.status])
  useEffect(() => {
    if (calculationGroupRequestState.status === 'idle') {
      dispatch(getCalculationGroup({ orgCode, storeCode }))
    }
  }, [dispatch, orgCode, storeCode, calculationGroupRequestState.status])
  useEffect(() => {
    if (status === 'idle') {
      dispatch(getCalculationDSLs({ orgCode, storeCode, tenantCode }))
    }
  }, [status, dispatch, orgCode, storeCode, tenantCode])

  // event handler
  const handleClickClosePage = () => {
    setShowWarningModal(true)
  }

  const handleClickGoForward = async () => {
    if (!tenant) {
      return
    }

    const params = {
      tenantId: tenant.id,
      editCalculations: getEditCalculations(),
    }

    try {
      await dispatch(postTenantsIdCalculationDsl(params)).unwrap()
      navigate(Path.completeEditCalculation(orgCode, storeCode, tenantCode))
    } catch (e) {
      const errorMessage = Presenter.convertErrorToMessage(
        Presenter.convertErrorType(e),
        calculationItemsState
      )
      toastContext.sendToast({
        variant: 'error',
        title: errorMessage,
      })
    }
  }

  const handleClickGoBack = () => {
    navigate(-1)
  }

  const handleClickAddCell = (rowIndex: number) => {
    if (!calculationItems) {
      return
    }

    const { cells } = rows[rowIndex]

    if (cells.length - CELL_SET_COUNT === addedCellSetCount * CELL_SET_COUNT) {
      setAddedCellSetCount(addedCellSetCount + 1)
    }

    const cellIndex = rows[rowIndex].cells.length

    const calculationId = calculationItems[rowIndex].id

    const updatedRow: BodyRow = {
      ...rows[rowIndex],
      cells: [
        ...rows[rowIndex].cells,
        ...generateCellSet(
          rowIndex,
          cellIndex,
          calculationItems ?? [],
          editOCRFormats,
          calculationId
        ),
      ],
      disabledAddButton: true,
    }

    const result = rows.slice()
    result.splice(rowIndex, 1, updatedRow)

    setRows(result)
  }

  const handleClickConfirmClose = () => {
    dispatch(clearEditCalculation())
    navigate(Path.tenantCalculationTab(orgCode, storeCode, tenantCode))
  }

  const handleClickCancelClose = () => {
    setShowWarningModal(false)
  }

  const handleClickConfirmInput = () => {
    // @ts-ignore
    formMethods.setValue(getInputNumberName(), formMethods.getValues(inputName))
    setShowInputModal(false)
  }

  const handleClickCloseInput = () => {
    // @ts-ignore
    formMethods.setValue(getInputNumberName(), selectedInputNumberValue)
    setShowInputModal(false)
  }

  const handleOpenSelect = (rowIndex: number, columnIndex: number) => {
    // セレクトボックスを展開した際に、数値が入っていた場合、その値を保持する
    setInputCellPosition({
      rowIndex,
      columnSetIndex: Math.floor(columnIndex / CELL_SET_COUNT),
    })
  }

  const handleChangeSelect = (
    option: Option,
    rowIndex: number,
    cellIndex: number
  ) => {
    const formValue = formMethods.getValues(getInputNumberName())
    if (isNumber(formValue)) {
      setSelectedInputNumberValue(Number(formValue))
    } else {
      setSelectedInputNumberValue(undefined)
    }
    if (option.value === 'inputNumber') {
      setShowInputModal(true)
    }
    // NOTE: 最後のセル(＝四則演算子)が空の場合は、次の項目を選択することがないので、プラスボタンを非活性
    const isLastCell = rows[rowIndex].cells.length - 1 === cellIndex
    if (isLastCell) {
      const updatedRow = {
        ...rows[rowIndex],
        disabledAddButton: !option.value,
      }

      const result = rows.slice()
      result.splice(rowIndex, 1, updatedRow)

      setRows(result)
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form>
        <EditCalculationDSLTemplate
          columns={Presenter.columns(addedCellSetCount)}
          rows={rows}
          breadcrumbs={breadcrumbs}
          isLoading={isLoadingRef.current}
          isSubmitting={submitStatus === 'loading'}
          inputName={inputName}
          defaultValue={selectedInputNumberValue}
          showWarningModal={showWarningModal}
          showInputModal={showInputModal}
          onClickClosePage={handleClickClosePage}
          onClickGoForward={handleClickGoForward}
          onClickGoBack={handleClickGoBack}
          onClickAddCell={handleClickAddCell}
          onClickConfirmClose={handleClickConfirmClose}
          onClickCancelClose={handleClickCancelClose}
          onClickConfirmInput={handleClickConfirmInput}
          onClickCloseInput={handleClickCloseInput}
          onClickClearInput={() => formMethods.setValue(inputName, '')}
          onOpenSelect={handleOpenSelect}
          onChangeSelect={handleChangeSelect}
          valueToRenderEmpty={inputName}
        />
      </form>
    </FormProvider>
  )
}

export default EditCalculationDSL
