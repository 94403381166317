import React, { useContext } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import clsx from 'clsx'
import { useForm, FormProvider } from 'react-hook-form'
import Icon from '../../atoms/Icon'
import { AuthContext } from '../../../context/auth.context'
import style from './styles.module.scss'

import SelectWithOption, { Option } from '../SelectWithOption'

export type AccountInformationProps = {
  organizations: Organization[]
  currentOrganizationName: string
  memberName: string
  selectName: string
  onChange: (option: Option) => void
  onClickLogout: () => void
} & React.StyledProps

export type Organization = {
  id: string
  name: string
}

const AccountInformation: React.StyledFC<AccountInformationProps> = ({
  className,
  organizations,
  currentOrganizationName,
  memberName,
  selectName,
  onChange,
  onClickLogout,
}: AccountInformationProps) => {
  const isMultiOrganization = organizations.length > 1
  const formMethods = useForm()
  const authContext = useContext(AuthContext)
  const email = authContext.getUserEmail()

  const options: Option[] = organizations.map((organization) => ({
    value: organization.id,
    title: organization.name,
  }))

  return (
    <FormProvider {...formMethods}>
      <form style={{ width: '100%' }}>
        <Box className={clsx(style.root, className)}>
          {isMultiOrganization ? (
            <SelectWithOption
              className={style.organization}
              variant="standard"
              name={selectName}
              options={options}
              placeholder={currentOrganizationName}
              onChange={onChange}
            />
          ) : (
            <Typography
              className={clsx(style.typography, style.organization)}
              variant="subtitle2"
              component="span"
            >
              {currentOrganizationName}
            </Typography>
          )}
          <Box className={style.bottom}>
            <Box className={style.typoWrapper}>
              {memberName && (
                <Typography className={style.typography} variant="subtitle2">
                  {memberName}
                </Typography>
              )}
              <Typography className={style.typography} variant="subtitle2">
                {email}
              </Typography>
            </Box>
            <IconButton className={style.button} onClick={onClickLogout}>
              <Icon icon="logout" />
            </IconButton>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}

export default AccountInformation
