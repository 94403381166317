import React, { memo } from 'react'
import clsx from 'clsx'
import { TableCell, Typography } from '@mui/material'
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup'
import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import cellStyles from '../styles.module.scss'
import styles from './styles.module.scss'

export type PopupListCellProps = {
  items: { code: string; name: string }[]
  suffix: string
  width?: number
} & React.StyledProps

export const PopupListCell: React.StyledFC<PopupListCellProps> = memo(
  ({ className, items, suffix, width }: PopupListCellProps) => {
    const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      if (items.length > 1) {
        setAnchor(anchor ? null : event.currentTarget)
      }
    }

    const open = Boolean(anchor)
    const id = open ? 'simple-popper' : undefined

    return (
      <ClickAwayListener onClickAway={() => setAnchor(null)}>
        <TableCell
          className={clsx(className, cellStyles.cell)}
          style={{ width }}
        >
          <Typography
            component="span"
            variant="h5"
            aria-describedby={id}
            onClick={handleClick}
            style={{ width }}
          >
            {items.length === 1 ? items[0]?.name : `${items.length} ${suffix}`}
          </Typography>
          <BasePopup id={id} open={open} anchor={anchor} placement="left">
            <ul className={styles.items}>
              {items.map((item) => (
                <li className={styles.item} key={item.code}>
                  {item?.name}
                </li>
              ))}
            </ul>
          </BasePopup>
        </TableCell>
      </ClickAwayListener>
    )
  }
)

PopupListCell.displayName = 'PopupListCell'
