import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'

export type BackdropLoadingProps = {
  open?: boolean
  onClick?: () => void
} & React.StyledProps

const BackdropLoading: React.StyledFC<BackdropLoadingProps> = ({
  className,
  open = false,
  onClick,
}: BackdropLoadingProps) => {
  return (
    <Backdrop
      open={open}
      className={className}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      onClick={onClick}
    >
      <CircularProgress />
    </Backdrop>
  )
}

export default BackdropLoading
