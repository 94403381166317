import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import * as z from 'zod'
import {
  InvalidInputErrorMessage,
  RegexNoSpaceOnly,
} from 'src/utils/regularExpression'
import clsx from 'clsx'
import styles from './styles.module.scss'
import Button from '../../atoms/Button'
import TextField from '../../molecules/TextField'

export type LoginData = {
  email: string
}

const schema = z.object({
  email: z.string().email('正しいメールアドレスを入力してください'),
})

export type LoginFormProps = {
  disabledSubmit?: boolean
  onSubmit: SubmitHandler<LoginData>
  isEmailExistence?: boolean
} & React.StyledProps

const LoginForm: React.StyledFC<LoginFormProps> = ({
  className,
  disabledSubmit,
  onSubmit,
  isEmailExistence,
}: LoginFormProps) => {
  const formMethods = useForm<LoginData>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(schema),
  })

  return (
    <Box className={className}>
      <Typography className={styles.title}>
        ログイン情報を入力してください。
      </Typography>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <TextField
            className={clsx(
              styles.textField,
              isEmailExistence === false && styles.textFieldError
            )}
            name="email"
            type="email"
            placeholder="メールアドレス"
            validations={[
              {
                regex: RegexNoSpaceOnly,
                message: InvalidInputErrorMessage,
              },
            ]}
          />
          <Box className={styles.errorContainer}>
            {formMethods.formState.errors.email && (
              <Typography
                classes={{ root: styles.emailError }}
                variant="subtitle1"
                display="block"
                component="span"
                role="alert"
              >
                {formMethods.formState.errors.email.message}
              </Typography>
            )}
            {isEmailExistence === false && (
              <Typography
                className={styles.emailError}
                variant="subtitle1"
                display="block"
                component="p"
                role="alert"
              >
                入力されたアドレスはユーザー登録されておりません。
              </Typography>
            )}
          </Box>
          <Box className={styles.buttonWrapper}>
            <Button
              disabled={disabledSubmit}
              className={styles.button}
              color="primary"
              size="large"
              type="submit"
              title="送信する"
            />
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}

export default LoginForm
