import { TableHeaderItem as Column } from '../../components/atoms/TableHeaderRow'
import { TableRow as Record } from '../../components/molecules/OCRFormatTable'
import {
  columnNumberToDisplayString,
  lineNumberToDisplayString,
} from '../../slices/ocrFormats/position'
import { CalculationDSL, OCRFormat } from '../../slices/services/api'
import { injectIndex } from '../../utils/array'

interface TableData {
  columns: Column[]
  records: Record[]
}

interface OCRFormatTabPresenter {
  convertToTableData(ocrFormats: CalculationDSL[]): TableData
}

const baseColumns: Omit<Column, 'index'>[] = [
  { color: 'green', title: '算出項目名' },
  { color: 'green', title: '読取項目名' },
  { color: 'green', title: '読取項目の重複有無' },
  { color: 'green', title: '重複項目のセクション名' },
  { color: 'purple', title: '接頭辞' },
  { color: 'purple', title: '接尾辞' },
  { color: 'purple', title: '行数' },
  { color: 'purple', title: '列数' },
]

const formatToParse = (calculationItemName: string, format: OCRFormat) => [
  { index: 0, title: calculationItemName },
  { index: 1, title: format.readItem },
  { index: 2, title: format.isDuplicated ? '有り' : '無し' },
  { index: 3, title: format.sectionName ?? '-' },
  { index: 4, title: format.prefix ?? '-' },
  { index: 5, title: format.suffix ?? '-' },
  {
    index: 6,
    title: lineNumberToDisplayString(format.readTargetNumberLine),
  },
  {
    index: 7,
    title: columnNumberToDisplayString(format.readTargetNumberColumn),
  },
]

const Presenter: OCRFormatTabPresenter = {
  convertToTableData: (dsls: CalculationDSL[]): TableData => {
    if (dsls.length === 0) {
      return {
        columns: [],
        records: [],
      }
    }

    const records = dsls.reduce((accumulator: Record[], current) => {
      if (current.ocrFormats) {
        current.ocrFormats.forEach((format) => {
          accumulator.push({
            index: accumulator.length,
            items: formatToParse(current.calculationItem.name, format),
          })
        })
      }

      return accumulator
    }, [])

    const columns: Column[] = injectIndex(baseColumns)

    return {
      columns,
      records,
    }
  },
}

export default Presenter
