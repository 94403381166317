import React from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import clsx from 'clsx'
import style from './styles.module.scss'
import Ellipse, { EllipseProps } from '../../atoms/Ellipse'
import Icon from '../../atoms/Icon'

type Status = {
  [status: string]: {
    title: string
    ellipseColor: EllipseProps['color']
    color: string
  }
}

const statuses: Status = {
  ready: { title: '利用中', ellipseColor: 'green', color: 'black' },
  not_ready: { title: '利用開始前', ellipseColor: 'red', color: 'vermilion' },
  closed: { title: '利用不可', ellipseColor: 'gray', color: 'unavailable' },
}

export type TenantListItemProps = {
  storeName?: string
  tenantName: string
  tenantCode: string
  to: string
  status: 'ready' | 'not_ready' | 'closed'
  trainedAt?: string
} & React.StyledProps

const TenantListItem: React.StyledFC<TenantListItemProps> = ({
  className,
  storeName,
  tenantName,
  tenantCode,
  to,
  status,
  trainedAt,
}: TenantListItemProps) => {
  return (
    <ListItem
      button
      component={Link}
      classes={{ root: style.listItem }}
      className={className}
      to={to}
    >
      <ListItemIcon classes={{ root: style.tenantIcon }}>
        <Icon icon="tenant" />
      </ListItemIcon>
      <Box className={style.content}>
        <ListItemText
          className={style.listItemText}
          disableTypography
          secondary={
            <Typography variant="h6">
              <Ellipse
                color={statuses[status].ellipseColor}
                className={style.ellipseIcon}
              />
              <Typography
                className={style[status]}
                variant="inherit"
                component="span"
              >
                {statuses[status].title}
              </Typography>
            </Typography>
          }
        >
          <Box className={style.tenantNameAndCodeContent}>
            <Typography
              className={style.tenantCode}
              variant="subtitle1"
              color="textSecondary"
              component="span"
            >
              {tenantCode}
            </Typography>
            <Typography
              className={style.tenantName}
              variant="subtitle1"
              component="span"
            >
              {tenantName}
            </Typography>
          </Box>
        </ListItemText>
        <Box className={style.description}>
          <Typography
            className={clsx(style.store, style.text)}
            variant="h4"
            component="span"
          >
            {storeName}
          </Typography>
          <Typography component="span" variant="h4" className={style.text}>
            {trainedAt}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  )
}

export default TenantListItem
