/* eslint-disable react/prop-types */
import React, { PropsWithChildren, useCallback, useState } from 'react'
import { Divider, ListSubheader, MenuItem } from '@mui/material'
import clsx from 'clsx'
import Icon, { IconName } from '../../atoms/Icon'
import Select, { Color, SelectProps } from '../../atoms/Select'
import styles from './styles.module.scss'

export type Option = {
  value: string | number
  title: string
  icon?: IconName
  color?: Color
}

export type OptionGroup = {
  title?: string
  isIndent?: boolean
  options: Option[]
}

export type SelectWithGroupOptionProps = {
  optionGroups: OptionGroup[]
  placeholder?: string
  onOpen?: () => void
  onChange?: (option: Option) => void
  valueToRenderEmpty?: string | number
} & React.StyledProps &
  SelectProps

const SelectWithGroupOption: React.StyledFC<SelectWithGroupOptionProps> = ({
  className,
  name,
  placeholder = '',
  defaultValue = '',
  variant,
  optionGroups,
  onOpen,
  onChange,
  disabled,
  valueToRenderEmpty,
  style,
}: PropsWithChildren<SelectWithGroupOptionProps>) => {
  const getFlattenOptions = useCallback(() => {
    return optionGroups.flatMap((group: OptionGroup) =>
      group.options.map((option: Option) => {
        return { value: option.value, title: option.title }
      })
    )
  }, [optionGroups])

  const renderValue = (value: unknown): React.ReactNode => {
    const selectValue = value as string
    if (selectValue === String(valueToRenderEmpty)) {
      return <div />
    }
    const renderTitle = getFlattenOptions().find(
      (option) => option.value === selectValue
    )?.title
    return <span>{renderTitle ?? selectValue}</span>
  }

  const [color, setColor] = useState<Color>(() => {
    if (defaultValue) {
      const defaultColor = optionGroups
        .flatMap((group) => group.options)
        .find((option) => option.value === defaultValue)?.color
      if (defaultColor) return defaultColor as Color
    }
    return 'primary'
  })

  const renderOptions = (options: Option[], isIndent?: boolean) => {
    return options.map((option) => (
      <MenuItem
        className={clsx(styles.option, isIndent && styles.indent)}
        classes={{
          selected: styles.selected,
        }}
        key={`${option.title}_${option.value}`}
        value={option.value}
        onClick={() => {
          if (option.color) setColor(option.color)
          if (onChange) onChange(option)
        }}
      >
        {option.icon && <Icon className={styles.icon} icon={option.icon} />}
        {/* NOTE: div element で囲うことで、並べたときのがたつきがなくなる */}
        <div>{option.title}</div>
      </MenuItem>
    ))
  }

  const renderOptionGroup = (optionGroup: OptionGroup) => {
    return optionGroup.title
      ? [
          <ListSubheader
            disableSticky
            className={styles.optgroup}
            key={optionGroup.title}
          >
            {optionGroup.title}
          </ListSubheader>,
          renderOptions(optionGroup.options, optionGroup.isIndent),
        ]
      : renderOptions(optionGroup.options, optionGroup.isIndent)
  }

  return (
    <Select
      className={className}
      style={style}
      name={name}
      menuClassName={styles.menuPaper}
      defaultValue={defaultValue}
      variant={variant}
      onOpen={onOpen}
      disabled={disabled}
      renderValue={renderValue} // NOTE: select boxに表示する値をカスタマイズ
      color={color}
    >
      <MenuItem className={styles.placeholder} value="">
        {placeholder}
      </MenuItem>
      {optionGroups.map((optionGroup, index) => {
        const notLastIndex = index !== optionGroups.length - 1
        return [renderOptionGroup(optionGroup), notLastIndex && <Divider />]
      })}
    </Select>
  )
}

export default SelectWithGroupOption
