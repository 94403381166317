import React, { memo, useCallback, useEffect, useState } from 'react'
import { Table, TableBody } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  AppTableHead,
  AppTableRow,
  ChildCell,
  InputCell,
  SelectCell,
  TextCell,
} from '../../../../components/tables'
import { activeOptions, roleOptionsForAdminStoreManager } from '../../type'
import { IndexedMember } from '../../reducer'
import {
  InvalidInputErrorMessage,
  RegexEmail,
} from '../../../../utils/regularExpression'
import MemberAssignedStoreCheck from '../../../../components/molecules/MemberAssignedStoreCheck'
import { CheckFormType } from '../../../../components/molecules/CheckBoxListWithFilter'
import { RoleEnum, Store as EntityStore } from '../../../../slices/services/api'
import { getRoleOptions } from '../../function'
import { AllAssignedStoresNotAvailableRoles } from '../../constants'

export interface MemberFormType {
  name: string
  email: string
  role: RoleEnum
  assignedStores: {
    checkListValues: string[]
    allChecked: boolean
  }
  active: string
}

export interface MembersFormType {
  members: MemberFormType[]
}

export type MembersUpdateTableProps = {
  members: IndexedMember[]
  stores: EntityStore[]
  selfRole: RoleEnum
  orgCode: string
} & React.StyledProps

const MembersUpdateTable: React.StyledFC<MembersUpdateTableProps> = memo(
  ({ members, stores, selfRole, orgCode }: MembersUpdateTableProps) => {
    const formMethods = useFormContext<MembersFormType>()
    const [roles, setRoles] = useState<RoleEnum[]>()

    useEffect(() => {
      setRoles(
        members.map((member) => {
          return member.role
        })
      )
    }, [members])

    const allStoreIds = useCallback(() => {
      return stores.map((store) => store.id)
    }, [stores])

    useEffect(() => {
      const subscription = formMethods.watch((value, { name }) => {
        if (!name?.endsWith('role') || !value.members || !roles) return

        // roleが変更されたときに、フォームのassignedStoresを強制変更する処理
        const splitName = name.split('.')
        const formIndex = Number(splitName[1])
        const formMembers: MemberFormType[] = value.members as MemberFormType[]
        const nextRole = value.members[formIndex]?.role
        const previousAssignedStoreAllchecked =
          formMembers[formIndex].assignedStores?.allChecked
        if (
          previousAssignedStoreAllchecked &&
          !!nextRole &&
          AllAssignedStoresNotAvailableRoles.includes(nextRole)
        ) {
          formMethods.setValue(`members.${formIndex}.assignedStores`, {
            checkListValues: selfRole === RoleEnum.Admin ? [] : allStoreIds(),
            allChecked: false,
          })
        }
        if (nextRole === RoleEnum.Admin) {
          formMethods.setValue(`members.${formIndex}.assignedStores`, {
            checkListValues: [],
            allChecked: true,
          })
        }
        const roleArr = formMembers.map((member) => member.role)
        setRoles(roleArr)
      })
      return () => subscription.unsubscribe()
    }, [formMethods, formMethods.watch, roles, selfRole, allStoreIds])

    return (
      <Table>
        <AppTableHead
          columns={[
            { title: '利用ステータス' },
            { title: '権限' },
            { title: '氏名' },
            { title: 'メールアドレス' },
            { title: '担当店舗' },
          ]}
        />
        <TableBody>
          {members.map((member) => {
            const formMember = formMethods.getValues(`members.${member.index}`)
            const { index } = member
            let assignedStoreIds = []
            let allChecked = false
            // NOTE このroleはformMemberから用いても値は同じだが、formMethods.watchで変更した値を変更するには、setRolesでrenderingをかけないといけないので、rolesを用いている。
            const role = roles ? roles[index] : member.role
            if (formMember) {
              assignedStoreIds = formMember.assignedStores.checkListValues
              allChecked = formMember.assignedStores.allChecked
            } else {
              // formがまだ無い時に、ネットワークから取得したデータの設定
              assignedStoreIds =
                member.assignedStores?.map(
                  (assignedStore) => assignedStore.id
                ) || []
              allChecked = member.hasAllStoresPermission
            }

            // NOTE: defaultCheckListは、初期値に加え、MemberAssignedStoreCheckはTextCellから置き換わるときに適用される。
            const defaultCheckList: CheckFormType = {
              checkListValues: assignedStoreIds,
              allChecked,
            }
            const disabledAllCheck =
              AllAssignedStoresNotAvailableRoles.includes(role)
            const disableAssignedStore =
              role === RoleEnum.Admin && allChecked === true

            return (
              <AppTableRow key={member.index}>
                <SelectCell
                  name={`members.${member.index}.active`}
                  options={activeOptions}
                  defaultValue={`${member.active ?? true}`}
                  width={140}
                />
                <SelectCell
                  name={`members.${member.index}.role`}
                  width={190}
                  options={
                    selfRole === RoleEnum.AdminStoreManager
                      ? roleOptionsForAdminStoreManager
                      : getRoleOptions(orgCode)
                  }
                  defaultValue={member.role}
                />
                <InputCell
                  name={`members.${member.index}.name`}
                  defaultValue={member.name}
                  width={300}
                  required
                />
                <InputCell
                  name={`members.${member.index}.email`}
                  defaultValue={member.email}
                  validations={[
                    {
                      regex: RegexEmail,
                      message: InvalidInputErrorMessage,
                    },
                  ]}
                  width={300}
                  required
                />
                {disableAssignedStore ? (
                  <TextCell width={360} text="全ての店舗" />
                ) : (
                  <ChildCell>
                    <MemberAssignedStoreCheck
                      width={360}
                      stores={stores}
                      defaultCheckList={defaultCheckList}
                      formName={`members.${member.index}.assignedStores`}
                      defaultTitle="選択してください"
                      becomeErrorIfEmpty={selfRole === RoleEnum.Admin}
                      disabledAllCheck={disabledAllCheck}
                    />
                  </ChildCell>
                )}
              </AppTableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }
)

MembersUpdateTable.displayName = 'MembersUpdateTable'
export default MembersUpdateTable
