import React from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CircularProgress from '../../atoms/CircularProgress'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const Loading: React.FC = () => {
  const styles = useStyles()
  return (
    <Box className={styles.root}>
      <CircularProgress />
    </Box>
  )
}

export default Loading
