import React, { memo } from 'react'
import { Table, TableBody } from '@mui/material'

import style from './styles.module.scss'
import {
  PopupListCell,
  AppTableRow,
  AppTableHead,
  TextCell,
} from '../../../../components/tables'
import { getRoleTitle } from '../../type'
import { Member } from '../../../../slices/services/api'

export type MembersTableProps = {
  members: Member[]
  isSmallTable?: boolean
} & React.StyledProps

const MembersTable: React.StyledFC<MembersTableProps> = memo(
  ({ members, isSmallTable }: MembersTableProps) => {
    return (
      <Table>
        <AppTableHead
          columns={[
            { title: '利用ステータス' },
            { title: '権限' },
            { title: '氏名' },
            { title: 'メールアドレス' },
            { title: '担当店舗' },
          ]}
        />
        <TableBody>
          {members.map((member) => {
            const association = member.hasAllStoresPermission ? (
              <TextCell text="全ての店舗" width={100} />
            ) : (
              <PopupListCell
                items={
                  member.assignedStores?.map((assignedStore) => {
                    return {
                      code: assignedStore.code,
                      name: assignedStore?.name,
                    }
                  }) || []
                }
                suffix="店舗"
              />
            )
            return (
              <AppTableRow key={member.id}>
                <TextCell
                  className={
                    member.active ? style.statusActive : style.statusDeactive
                  }
                  text={member.active ? '利用中' : '利用停止'}
                  width={100}
                />
                <TextCell text={getRoleTitle(member.role)} width={140} />
                <TextCell text={member.name} width={isSmallTable ? 100 : 300} />
                <TextCell
                  text={member.email}
                  width={isSmallTable ? 200 : 400}
                />
                {association}
              </AppTableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }
)

MembersTable.displayName = 'MembersTable'
export default MembersTable
