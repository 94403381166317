import React from 'react'
import { Box, Divider } from '@mui/material'
import clsx from 'clsx'
import { ReportMessage } from 'src/components/molecules/ReportMessages'
import { Option } from 'src/components/molecules/SelectWithGroupOption'
import PdfViewer from 'src/components/molecules/PdfViewer'
import style from './styles.module.scss'
import { GuideProps } from '../../../../components/molecules/Guide'
import SmallGuide from '../../../../components/molecules/SmallGuide'
import ZoomableReceiptImage from '../../../../components/molecules/ZoomableReceiptImage'

import SalesReportTable from '../SalesReportTable'
import SalesReportHeader from '../SalesReportHeader'
import SalesReportMessages from '../Messages'
import {
  isOneOfRoles,
  AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
} from '../../../../domain/role'
import { ReceiptImage, RoleEnum } from '../../../../slices/services/api'
import { SalesReportType } from '../../type'

type StickyTop = {
  stickyTop?: number // scroll するときの挙動に必要
  guideText?: Pick<GuideProps, 'message' | 'description'>
}

export type SalesReportProps = React.StyledProps &
  StickyTop & {
    salesReport: SalesReportType
    reportMessages: ReportMessage[]
    role?: RoleEnum
    selectOptions: Option[]
    selectedResentImage?: ReceiptImage
    filterForMismatch: boolean
    onClickName: () => void
    onSelectDateChanged: (selectedDate: string) => void
  }

const SalesReport: React.StyledFC<SalesReportProps> = ({
  guideText,
  className,
  stickyTop = 0,
  salesReport,
  selectOptions,
  filterForMismatch,
  reportMessages,
  role,
  selectedResentImage,
  onClickName,
  onSelectDateChanged,
}: SalesReportProps) => {
  const isCurrentImagePdf = selectedResentImage
    ? selectedResentImage.isPdf
    : salesReport.isPdf
  return (
    <Box className={clsx(className, style.root)}>
      <Box sx={{ position: 'sticky', top: stickyTop, zIndex: 3 }}>
        <SalesReportHeader
          className={style.header}
          salesReport={salesReport}
          selectOptions={selectOptions}
          onClickName={onClickName}
          onSelectDateChanged={onSelectDateChanged}
        />
      </Box>
      <Box>
        {guideText ? (
          <SmallGuide
            className={style.guide}
            icon="camera"
            message={guideText.message}
            description={guideText.description}
          />
        ) : (
          <>
            <Box className={style.content}>
              <Box className={style.receiptTableWrapper}>
                <Box className={style.receiptWrapper}>
                  {isCurrentImagePdf ? (
                    <PdfViewer
                      className={style.receipt}
                      separatedOriginal={
                        selectedResentImage?.separatedOriginal ||
                        salesReport.separatedOriginal
                      }
                      concatOriginal={
                        selectedResentImage?.concatOriginal ||
                        salesReport.concatOriginal
                      }
                    />
                  ) : (
                    <ZoomableReceiptImage
                      className={style.receipt}
                      concatOriginal={
                        selectedResentImage?.concatOriginal ||
                        salesReport.concatOriginal
                      }
                      separatedOriginal={
                        selectedResentImage?.separatedOriginal ||
                        salesReport.separatedOriginal
                      }
                      resized={
                        selectedResentImage?.resized || salesReport.resized
                      }
                    />
                  )}
                </Box>
                {isOneOfRoles(
                  AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
                  role
                ) &&
                  salesReport.rows.length > 0 && (
                    <Box className={style.tableWrapper}>
                      <SalesReportTable
                        className={style.table}
                        filterForMismatch={filterForMismatch}
                        rows={salesReport.rows}
                      />
                    </Box>
                  )}
              </Box>
              <SalesReportMessages
                className={style.messages}
                reportMessages={reportMessages}
                tenantMessageBody={salesReport.tenantDetail?.note || ''}
                manageMessage={salesReport.manageMessage}
              />
            </Box>
            <Divider className={style.listDivider} />
          </>
        )}
      </Box>
    </Box>
  )
}

export default SalesReport
