import React from 'react'
import { SvgIcon } from '@mui/material'
import clsx from 'clsx'
import style from './styles.module.scss'
import { ReactComponent as EllipseIcon } from './assets/ellipse.svg'

export type Color = 'green' | 'red' | 'gray'

export type EllipseProps = {
  color: Color
} & React.StyledProps

const Ellipse: React.StyledFC<EllipseProps> = ({
  color,
  className,
}: EllipseProps) => {
  return (
    <SvgIcon
      component={EllipseIcon}
      className={clsx(className, style.root, style[color])}
      viewBox="0 0 8 8"
    />
  )
}

export default Ellipse
