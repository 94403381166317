import React, { ReactElement, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Loading from '../../components/templates/Loading'
import { postAuth } from '../../slices/auth/authSlice'
import Path from '../../routes/path'
import { useAppDispatch, useAppSelector } from '../../store'
import { AuthContext } from '../../context/auth.context'

const Root: React.FC = (): ReactElement => {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const { status, currentAuthorization } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (status === 'idle') {
      dispatch(postAuth())
    }
    if (currentAuthorization) {
      navigate(Path.stores(currentAuthorization.organization.code))
    }
  }, [navigate, status, currentAuthorization, dispatch, authContext])

  return <Loading />
}

export default Root
