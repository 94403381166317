/* eslint-disable no-param-reassign */
import { Selector } from 'react-redux'
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit'
import { normalize } from 'normalizr'
import { PaginatedRequestState } from '../../domain/request'
import { storeEntity } from '../../domain/schema'
// eslint-disable-next-line import/no-cycle
import { AppThunkConfig, RootState } from '../../store'
import { purge } from '../../store/action'
import toKey from '../../utils/key'
import { Store } from '../services/api'
import serializeError from '../services/error'
import getTotalCount from '../services/handler'
import AuthenticatedApi from '../services/authenticatedApi'
// eslint-disable-next-line import/no-cycle
import { getStoreSalesReportSummaries } from '../storeSalesReportSummaries/storeSalesReportSummariesSlice'
// eslint-disable-next-line import/no-cycle
import { getOrgTenants } from '../tenants/tenantsSlice'

export type IndexCode = {
  orgCode: string
  storeCode: string
}

interface StoreState extends EntityState<Store> {
  // toKey(IndexCode)の値をStore.idにマッピングする
  codeToId: {
    [code: string]: string
  }
  query: {
    [key: string]: PaginatedRequestState
  }
}

const storesAdapter = createEntityAdapter<Store>()

export const initialState: StoreState = storesAdapter.getInitialState({
  codeToId: {},
  query: {},
})

type GetStoresParams = {
  orgCode: string
  page: number
  perPage: number
  q?: string
}

interface GetStoresEntities {
  stores: Record<string, Store>
}

interface GetStoresReturned {
  entities: GetStoresEntities
  totalCount: number
}

export const getStores = createAsyncThunk<
  GetStoresReturned,
  GetStoresParams,
  AppThunkConfig
>(
  'stores/getStores',
  async (params, { getState }) => {
    const { auth } = getState()
    const response = await new AuthenticatedApi(
      auth.token
    ).getOrganizationsOrganizationCodeStores(
      params.orgCode,
      params.page,
      params.perPage,
      params.q
    )

    const totalCount = getTotalCount(response)
    if (totalCount === undefined) {
      // TODO Error handling
      const error = { message: '' }
      throw error
    }

    const normalized = normalize<Store, GetStoresEntities>(response.data, [
      storeEntity,
    ])
    return {
      entities: normalized.entities,
      totalCount,
    }
  },
  { serializeError }
)

type GetStoreParams = {
  orgCode: string
  storeCode: string
}

export const getStore = createAsyncThunk<Store, GetStoreParams, AppThunkConfig>(
  'stores/getStore',
  async (params, { getState }) => {
    const { auth } = getState()
    const response = await new AuthenticatedApi(
      auth.token
    ).getOrganizationsOrganizationCodeStoresStoreCode(
      params.orgCode,
      params.storeCode
    )
    return response.data
  },
  { serializeError }
)

const slice = createSlice({
  name: 'stores',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(getStores.pending, (state, { meta }) => {
      return {
        ...state,
        query: {
          ...state.query,
          [toKey(meta.arg)]: {
            status: 'loading',
          },
        },
      }
    })
    builder.addCase(getStores.fulfilled, (state, { meta, payload }) => {
      const { entities, totalCount } = payload
      if (entities.stores === undefined) {
        state.query[toKey(meta.arg)] = {
          status: 'succeeded',
          totalCount,
        }
        return
      }

      storesAdapter.upsertMany(state, entities.stores)

      Object.keys(entities.stores).forEach((id) => {
        const indexCode: IndexCode = {
          orgCode: meta.arg.orgCode,
          storeCode: entities.stores[id].code,
        }
        state.codeToId[toKey(indexCode)] = id
      })

      state.query[toKey(meta.arg)] = {
        status: 'succeeded',
        ids: Object.keys(entities.stores),
        totalCount,
        currentPage: meta.arg.page,
        totalPage: Math.ceil(totalCount / meta.arg.perPage),
      }
    })
    builder.addCase(getStores.rejected, (state, { meta, error }) => {
      return {
        ...state,
        query: {
          ...state.query,
          [toKey(meta.arg)]: {
            status: 'failed',
            error,
          },
        },
      }
    })
    builder.addCase(getStore.pending, (state, { meta }) => {
      return {
        ...state,
        query: {
          ...state.query,
          [toKey(meta.arg)]: {
            status: 'loading',
          },
        },
      }
    })
    builder.addCase(getStore.fulfilled, (state, { meta, payload }) => {
      storesAdapter.upsertOne(state, payload)
      const key = toKey(meta.arg)
      state.codeToId[key] = payload.id
      state.query[key] = {
        status: 'succeeded',
      }
    })
    builder.addCase(getStore.rejected, (state, { meta, error }) => {
      return {
        ...state,
        query: {
          ...state.query,
          [toKey(meta.arg)]: {
            status: 'failed',
            error,
          },
        },
      }
    })
    builder.addCase(getOrgTenants.fulfilled, (state, { payload }) => {
      if (payload.entities.tenants !== undefined) {
        storesAdapter.upsertMany(state, payload.entities.stores)
      }
    })
    builder.addCase(
      getStoreSalesReportSummaries.fulfilled,
      (state, { meta, payload }) => {
        const { stores } = payload.entities
        if (stores === undefined) {
          return state
        }

        storesAdapter.upsertMany(state, stores)

        const { orgCode } = meta.arg

        Object.keys(stores).forEach((id) => {
          const indexCode: IndexCode = {
            orgCode,
            storeCode: stores[id].code,
          }
          state.codeToId[toKey(indexCode)] = id
        })
        return state
      }
    )
  },
})

export default slice.reducer

export const {
  selectById: selectStoreById,
  selectEntities: selectStoreEntities,
  selectAll: selectAllStores,
} = storesAdapter.getSelectors<StoreState>((state) => state)

export const selectStoresByParams = (
  params: GetStoresParams
): Selector<RootState, Store[] | undefined> => {
  const key = toKey(params)
  return createSelector([(state) => state.entities.stores], (state) => {
    if (!state.query[key]) {
      return undefined
    }
    const entities = selectStoreEntities(state)
    return state.query[key].ids
      ?.map((id: any) => entities[id]) // eslint-disable-line @typescript-eslint/no-explicit-any
      .filter((store: any): store is Store => store !== undefined) // eslint-disable-line @typescript-eslint/no-explicit-any
  })
}

export const selectStoresRequestStateByParams = (
  params: GetStoresParams | GetStoreParams
): Selector<RootState, PaginatedRequestState> => {
  return createSelector([(state) => state.entities.stores], (state) => {
    return (
      state.query[toKey(params)] ?? {
        status: 'idle',
      }
    )
  })
}

export const selectStoreByCode = (
  code: IndexCode
): Selector<RootState, Store | undefined> => {
  return createSelector([(state) => state.entities.stores], (state) => {
    const key = toKey(code)
    if (!state.codeToId || state.codeToId[key] === undefined) {
      return undefined
    }
    const storeId = state.codeToId[key]
    return selectStoreById(state, storeId)
  })
}
