import React, { useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import RotationImage from '../RotationImage'

export type LazyLoadImageProps = {
  sources: string[]
  alt: string
  placeholder: string
  onLoaded?: () => void
} & React.StyledProps

const LazyLoadImage: React.StyledFC<LazyLoadImageProps> = ({
  className,
  sources,
  alt,
  placeholder,
  onLoaded,
}: LazyLoadImageProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const loadImage = (src: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.onload = () => resolve()
      image.onerror = (err) => reject(err)
      image.src = src
    })
  }

  useEffect(() => {
    const promises: Promise<void>[] = []
    sources.forEach((src) => {
      promises.push(loadImage(src))
    })
    Promise.all(promises)
      .then(() => {
        setIsLoaded(true)
        if (isLoaded && onLoaded) {
          onLoaded()
        }
      })
      .catch((err) => {
        setIsLoaded(false)
        Bugsnag.notify(err)
      })
  }, [isLoaded, onLoaded, sources])

  return isLoaded ? (
    <div>
      {sources.map((src, index) => (
        <RotationImage
          key={src}
          src={src}
          alt={alt}
          pictureNumber={`${index + 1}/${sources.length}枚目`}
        />
      ))}
    </div>
  ) : (
    <img className={className} src={placeholder} alt={alt} draggable={false} />
  )
}

export default LazyLoadImage
