import React from 'react'
import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import CircularProgress from '../../atoms/CircularProgress'
import styles from './styles.module.scss'

export type CenteringLoadingProps = {
  text?: string
} & React.StyledProps

const CenteringLoading: React.StyledFC<CenteringLoadingProps> = ({
  className,
  text,
}: CenteringLoadingProps) => {
  return (
    <Box className={clsx(styles.root, className)}>
      <CircularProgress className={styles.circularProgress} />
      {text && (
        <Typography component="p" variant="h2">
          {text}
        </Typography>
      )}
    </Box>
  )
}

export default CenteringLoading
