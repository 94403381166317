import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMount } from 'react-use'

import useBeforeUnload from '../../../hooks/useBeforeUnload'
import {
  deselectedTenants,
  setConfirmationDate,
  setSelectedTenantCodes,
  DEFAULT_DATE,
} from '../../../slices/confirmSalesReport/confirmSalesReportSlice'
import { clearPutDefiniteValues } from '../../../slices/definiteValues/definiteValuesSlice'
import { clearMessage } from '../../../slices/message/messageSlice'
import {
  ProgressStatusEnum,
  ReportStatusEnum,
  RoleEnum,
  TenantSalesReportSummary,
} from '../../../slices/services/api'
import { success } from '../../../slices/snackbar/snackbarSlice'
import {
  getStore,
  selectStoreByCode,
  selectStoresRequestStateByParams,
} from '../../../slices/stores/storesSlice'
import { ToastTriggerContext } from '../../../context/toast.context'
import {
  clearTenantSalesReportSummaries,
  getTenantSalesReportSummaries,
  postBulkFinalizationOfSalesDates,
  postSalesReportsCSV,
  resetBulkFinalizationStatus,
  resetRevertReportedStatus,
  resetSyncStatus,
  revertReportedStatus,
  selectDenormalizedTenantSalesReportSummariesByParams,
  selectStoreSalesReportSummariesRequestStateByParams,
} from '../../../slices/tenantSalesReportSummaries/tenantSalesReportSummariesSlice'
import useAppTitle from '../../../hooks/useAppTitle'
import { useApiSalesDate } from '../../../hooks/useApiSalesDate'
import Path, { StorePathParams } from '../../../routes/path'
import { useAppDispatch, useAppSelector } from '../../../store'

import TemplatesTenantSalesReportSummary from '../templates'
import Presenter from './presenter'
import { useApiSalesReports } from '../../../hooks/useApiSalesReportsByStore'
import { downloadCSV } from '../downloadCSV'
import { useApiUpdateTotals } from '../../../hooks/useApiUpdateTotals'
import { ENABLE_UPDATING_REGISTERED_STATUS } from '../../../domain/org'

const PagesTenantSalesReportSummary: React.FC = (): ReactElement => {
  useAppTitle('テナント売上報告一覧')
  const navigate = useNavigate()
  const toastContext = useContext(ToastTriggerContext)
  const { getByStore } = useApiSalesReports()
  const { patchUpdateTotals } = useApiUpdateTotals()
  const { updateStatus } = useApiSalesDate()

  const [isReadLoaded, setIsReadLoaded] = useState<boolean | undefined>()
  const [isCSVDownloading, setIsCSVDownloading] = useState<boolean>(false)

  // Query
  const { date } = useAppSelector((state) => state.forms.confirmSalesReport)
  const role: RoleEnum | undefined = useAppSelector(
    (state) => state.auth.currentAuthorization?.role
  )
  const { orgCode, storeCode } = useParams<StorePathParams>() as StorePathParams

  // state
  const requestParam = useMemo<{
    orgCode: string
    storeCode: string
    date: string
  }>(() => {
    return {
      orgCode,
      storeCode,
      date: date || DEFAULT_DATE,
    }
  }, [date, orgCode, storeCode])

  // Redux
  const dispatch = useAppDispatch()
  useMount(() => {
    dispatch(deselectedTenants())
    dispatch(clearPutDefiniteValues())
    dispatch(clearMessage())
  })

  // get store
  const store = useAppSelector(selectStoreByCode({ orgCode, storeCode }))
  const storeRequestState = useAppSelector(
    selectStoresRequestStateByParams({ orgCode, storeCode })
  )
  useEffect(() => {
    if (store === undefined && storeRequestState.status === 'idle') {
      dispatch(getStore({ orgCode, storeCode }))
    }
  }, [dispatch, store, orgCode, storeCode, storeRequestState.status])

  // get summary
  const summaries: TenantSalesReportSummary[] | undefined = useAppSelector(
    selectDenormalizedTenantSalesReportSummariesByParams(requestParam)
  )
  const summaryRequestState = useAppSelector(
    selectStoreSalesReportSummariesRequestStateByParams(requestParam)
  )

  const revertReportedRequestState = useAppSelector(
    (state) => state.entities.tenantSalesReportSummaries.revertReportedStatus
  )
  const bulkFinalizationRequestState = useAppSelector(
    (state) => state.entities.tenantSalesReportSummaries.bulkFinalizationStatus
  )
  const syncRequestState = useAppSelector(
    (state) => state.entities.tenantSalesReportSummaries.syncStatus
  )

  const isUpdateLoading =
    revertReportedRequestState === 'loading' ||
    bulkFinalizationRequestState === 'loading' ||
    syncRequestState === 'loading'

  useBeforeUnload(isUpdateLoading)

  useEffect(() => {
    switch (summaryRequestState.status) {
      case 'idle':
        dispatch(getTenantSalesReportSummaries(requestParam))
        break
      case 'loading':
        setIsReadLoaded(false)
        break
      case 'failed':
      case 'succeeded':
        setIsReadLoaded(true)
        break
      default:
        setIsReadLoaded(false)
    }
  }, [dispatch, requestParam, summaryRequestState.status])

  const selectedTenantIndex = useAppSelector(
    (state) => state.forms.confirmSalesReport.selectedTenantIndex
  )

  // 報告済み/再報告済みに一括で戻す
  const handleClickRevertReported = async (selectedSalesDateIds: string[]) => {
    if (selectedSalesDateIds.length === 0) return

    // NOTE: progress_statusからregisteredを撤廃後に修正。
    // appのデータ連携完了時にOIOIとAEONはregisteredに、他のORGはcompletedになるため
    const progressStatus: ProgressStatusEnum =
      ENABLE_UPDATING_REGISTERED_STATUS.includes(orgCode)
        ? ProgressStatusEnum.Registered
        : ProgressStatusEnum.Completed

    await dispatch(
      revertReportedStatus({
        orgCode,
        storeCode,
        salesDateIds: selectedSalesDateIds,
        progressStatus,
      })
    )
  }

  // 一括確定値登録
  const handleClickBulkFinalization = (selectedSalesDateIds: string[]) => {
    if (selectedSalesDateIds.length === 0) return
    dispatch(
      postBulkFinalizationOfSalesDates({
        orgCode,
        storeCode,
        salesDateIds: selectedSalesDateIds,
      })
    )
  }

  const handleReloadPage = () => {
    dispatch(getTenantSalesReportSummaries({ ...requestParam }))
  }

  const handleDownloadCSV = async () => {
    const page = 1
    const perPage = 300

    setIsCSVDownloading(true)
    const [salesReports, updateTotalsResult] = await Promise.all([
      getByStore(
        orgCode,
        storeCode,
        page,
        perPage,
        requestParam.date,
        requestParam.date
      ),
      // 店舗単位の合計値を更新
      patchUpdateTotals(
        orgCode,
        requestParam.date,
        requestParam.date,
        storeCode
      ),
    ])
    if (
      !salesReports.success ||
      !salesReports.data ||
      !updateTotalsResult.success
    ) {
      setIsCSVDownloading(false)
      toastContext.sendToast({
        variant: 'error',
        title: `CSVダウンロードに失敗しました: ${salesReports.message}`,
      })
      return
    }

    const targetSalesReports = salesReports.data.filter(
      (salesReport) =>
        salesReport.reportStatus === ReportStatusEnum.FinalizedReportSubmitted
    )
    // 一括で連携済みに更新
    const result = await updateStatus(
      orgCode,
      storeCode,
      targetSalesReports.map((salesReport) => salesReport.id),
      ProgressStatusEnum.FinalizedReportSynced
    )
    if (!result.success) {
      setIsCSVDownloading(false)
      toastContext.sendToast({
        variant: 'error',
        title: `CSVダウンロードに失敗しました: ${result.message}`,
      })
      return
    }

    handleReloadPage()
    downloadCSV(orgCode, storeCode, requestParam.date, targetSalesReports)
    setIsCSVDownloading(false)
    toastContext.sendToast({
      variant: 'success',
      title: `CSVダウンロードに成功しました`,
    })
  }

  // 確定値の確認
  const handleClickStartFinalization = (selectedTenantCodes: string[]) => {
    if (selectedTenantCodes.length === 0) return
    dispatch(setSelectedTenantCodes(selectedTenantCodes))
    dispatch(setConfirmationDate(requestParam.date))
    navigate(
      Path.tenantSalesReport(
        orgCode,
        storeCode,
        selectedTenantCodes[selectedTenantIndex]
      )
    )
  }

  // データ連携
  const handleClickSend = (selectedSalesDateIds: string[]) => {
    if (selectedSalesDateIds.length === 0) return
    dispatch(
      postSalesReportsCSV({
        orgCode,
        storeCode,
        salesDateIds: selectedSalesDateIds,
      })
    )
  }

  const handleChangeDate = (newDate: string) => {
    dispatch(setConfirmationDate(newDate)) // 日付変更をトリガーに、データを再取得する。
    setIsReadLoaded(false)
  }

  useEffect(() => {
    if (
      revertReportedRequestState === 'idle' ||
      revertReportedRequestState === 'loading'
    )
      return
    if (revertReportedRequestState === 'succeeded') {
      toastContext.sendToast({
        variant: 'success',
        title: '報告済み/再送信済みに一括変更しました。',
      })
      dispatch(clearTenantSalesReportSummaries())
      dispatch(resetRevertReportedStatus())
      return
    }
    toastContext.sendToast({
      variant: 'error',
      title: '報告済み/再送信済みの一括変更に失敗しました。',
    })
    dispatch(resetRevertReportedStatus())
  }, [dispatch, revertReportedRequestState, toastContext])

  useEffect(() => {
    if (
      bulkFinalizationRequestState === 'idle' ||
      bulkFinalizationRequestState === 'loading'
    )
      return
    if (bulkFinalizationRequestState === 'succeeded') {
      toastContext.sendToast({
        variant: 'success',
        title: '確定値を一括登録しました。',
      })
      dispatch(clearTenantSalesReportSummaries())
      dispatch(resetBulkFinalizationStatus())
      return
    }
    toastContext.sendToast({
      variant: 'error',
      title: '確定値の一括登録に失敗しました。',
    })
    dispatch(resetBulkFinalizationStatus())
  }, [dispatch, bulkFinalizationRequestState, toastContext])

  useEffect(() => {
    if (syncRequestState === 'succeeded') {
      dispatch(success('データ連携が完了しました。'))
      // NOTE: データ連携完了後、ステータスを最新化するために、一度クリアする
      dispatch(clearTenantSalesReportSummaries())
      dispatch(resetSyncStatus())
    }
  }, [dispatch, syncRequestState])

  return (
    <TemplatesTenantSalesReportSummary
      isReadLoaded={isReadLoaded}
      isUpdateLoading={isUpdateLoading}
      isCSVDownloading={isCSVDownloading}
      role={role}
      date={requestParam.date}
      breadcrumbs={Presenter.breadcrumbs(orgCode, store?.name)}
      storeName={store?.name || ''}
      storeCode={store?.code || ''}
      orgCode={orgCode}
      summaries={summaries || []}
      onDownloadCSV={handleDownloadCSV}
      onChangeDate={handleChangeDate}
      onClickRevertReported={handleClickRevertReported}
      onClickBulkFinalization={handleClickBulkFinalization}
      onClickStartFinalization={handleClickStartFinalization}
      onClickSend={handleClickSend}
      onReloadPage={handleReloadPage}
    />
  )
}

export default PagesTenantSalesReportSummary
