import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import clsx from 'clsx'
import {
  InvalidInputErrorMessage,
  RegexNoSpaceOnly,
} from 'src/utils/regularExpression'
import style from './styles.module.scss'
import SelectWithOption, {
  Option,
  SelectWithOptionProps,
} from '../SelectWithOption'
import TextField from '../TextField'

export type Size = 'small' | 'medium' | 'large'

export type SelectCell = {
  defaultValue?: string | number
  name: string
  groupWithNextCell?: boolean
  size: Size
} & Omit<SelectWithOptionProps, 'variant' | 'control'>

export type InputCell = {
  name: string
  placeholder?: string
  defaultValue?: string | number
}

export type EditFormatRightBodyRowProps = {
  selectCells: SelectCell[]
  inputCell: InputCell
  isGray?: boolean
  showInput?: boolean
  onChangeSelect: (option: Option, name: string) => void
} & React.StyledProps

const EditFormatRightBodyRow: React.StyledFC<EditFormatRightBodyRowProps> = ({
  className,
  selectCells,
  inputCell,
  isGray,
  showInput,
  onChangeSelect,
}: EditFormatRightBodyRowProps) => {
  return (
    <TableRow className={clsx(className, style.row, isGray && style.grayRow)}>
      {selectCells.map((cell) => {
        return (
          <TableCell
            key={cell.name}
            className={clsx(
              style.cell,
              cell.groupWithNextCell && style.noPaddingRight
            )}
          >
            <SelectWithOption
              name={cell.name}
              className={style[cell.size]}
              defaultValue={cell.defaultValue}
              options={cell.options}
              placeholder={cell.placeholder}
              variant="outlined"
              onChange={(option) => onChangeSelect(option, cell.name)}
            />
          </TableCell>
        )
      })}
      {showInput ? (
        <TableCell className={clsx(style.cell, style.noPaddingRight)}>
          <TextField
            className={style.input}
            name={inputCell.name}
            type="text"
            defaultValue={inputCell.defaultValue}
            validations={[
              {
                regex: RegexNoSpaceOnly,
                message: InvalidInputErrorMessage,
              },
            ]}
          />
        </TableCell>
      ) : (
        <TableCell className={style.cell} />
      )}
    </TableRow>
  )
}

export default EditFormatRightBodyRow
