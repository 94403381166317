import React from 'react'
import { Box, Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Tabs, { TabsProps } from '../Tabs'

export type TabsWithLineProps = {
  options: TabsProps['options']
  selectedIndex: number
  onClick: (index: number) => void
} & React.StyledProps

const useStyles = makeStyles({
  tabs: {
    marginBottom: 2,
  },
})

const TabsWithLine: React.StyledFC<TabsWithLineProps> = ({
  options,
  selectedIndex,
  onClick,
  className,
}: TabsWithLineProps) => {
  const classes = useStyles()

  return (
    <Box className={className}>
      <Tabs
        className={classes.tabs}
        options={options}
        selectedIndex={selectedIndex}
        onClick={onClick}
      />
      <Divider />
    </Box>
  )
}

export default TabsWithLine
