import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useLocation, useMount } from 'react-use'
import dayjs from 'dayjs'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import StoreSalesReportSummaryTemplate from '../templates'
import { setSalesReportSummariesParams } from '../../../slices/query/querySlice'
import {
  getStoreSalesReportSummaries,
  selectDenormalizedStoreSalesReportSummariesByParams,
  selectStoreSalesReportSummariesRequestStateByParams,
} from '../../../slices/storeSalesReportSummaries/storeSalesReportSummariesSlice'
import {
  setConfirmationDate,
  DEFAULT_DATE,
} from '../../../slices/confirmSalesReport/confirmSalesReportSlice'
import useAppTitle from '../../../hooks/useAppTitle'
import Path, { OrgPathParams } from '../../../routes/path'
import { useAppDispatch, useAppSelector } from '../../../store'
import { formatSimpleDate } from '../../../utils/date'
import Presenter, { getTableHeader } from './presenter'
import { RoleEnum } from '../../../slices/services/api'
import { ToastTriggerContext } from '../../../context/toast.context'
import { useApiUpdateTotals } from '../../../hooks/useApiUpdateTotals'

const StoreSalesReportSummary: React.FC = (): ReactElement => {
  useAppTitle('店舗売上報告一覧')
  const navigate = useNavigate()
  const toastContext = useContext(ToastTriggerContext)
  const { patchUpdateTotals } = useApiUpdateTotals()
  const [isUpdateTotalsLoading, setIsUpdateTotalsLoading] =
    useState<boolean>(false)

  // Query
  const storedParams = useAppSelector(
    (state) => state.query.salesReportSummariesParams
  )
  const { date } = useAppSelector((state) => state.forms.confirmSalesReport)
  const { orgCode } = useParams<OrgPathParams>() as OrgPathParams
  const { pathname } = useLocation()
  const [query, setQuery] = useQueryParams({
    q: withDefault(StringParam, ''),
  })
  const { q } = query
  const params = useMemo(() => {
    return {
      orgCode,
      q,
      date: dayjs(date, 'YYYY-MM-DD', true).isValid() ? date : DEFAULT_DATE,
    }
  }, [date, orgCode, q])
  useMount(() => {
    if (pathname === storedParams.pathname) {
      setQuery({
        q: params.q,
      })
    }
  })

  // Redux
  const dispatch = useAppDispatch()

  const summaries = useAppSelector(
    selectDenormalizedStoreSalesReportSummariesByParams(params)
  )
  const requestState = useAppSelector(
    selectStoreSalesReportSummariesRequestStateByParams(params)
  )

  useEffect(() => {
    if (requestState.status === 'idle') {
      dispatch(getStoreSalesReportSummaries(params))
    }
  }, [dispatch, params, requestState.status])

  const role: RoleEnum | undefined = useAppSelector(
    (state) => state.auth.currentAuthorization?.role
  )

  const mappedSummaries = useMemo(() => {
    return Presenter.convertToRows(summaries ?? [], role)
  }, [role, summaries])

  useEffect(() => {
    dispatch(
      setSalesReportSummariesParams({
        pathname,
        q: params.q,
        date: params.date,
      })
    )
  }, [dispatch, params.date, params.q, pathname])

  // event handler
  const handleClickTableRow = (rowIndex: number) => {
    if (!summaries) return
    dispatch(setConfirmationDate(params.date))
    navigate(
      Path.tenantSalesReportSummaries(
        params.orgCode,
        summaries[rowIndex].store.code
      )
    )
  }

  const setDate = (offsetDays: number) => {
    const newDate = formatSimpleDate(dayjs(params.date).add(offsetDays, 'day'))
    dispatch(setConfirmationDate(newDate))
  }

  const handleClickPrev = () => {
    setDate(-1)
  }

  const handleClickNext = () => {
    setDate(1)
  }

  const handleSearchText = (text: string) => {
    setQuery({ q: text })
  }

  const handleChangeDate = (newDate: string) => {
    dispatch(setConfirmationDate(newDate))
  }

  const handleTotals = async () => {
    setIsUpdateTotalsLoading(true)
    try {
      const updateTotalsResult = await patchUpdateTotals(
        params.orgCode,
        params.date,
        params.date
      )
      if (!updateTotalsResult.success) {
        toastContext.sendToast({
          variant: 'error',
          title: '報告値/確定値の更新に失敗しました。',
        })
        return
      }
      await dispatch(getStoreSalesReportSummaries(params)).unwrap()
      toastContext.sendToast({
        variant: 'success',
        title: '報告値/確定値を更新しました。',
      })
    } finally {
      setIsUpdateTotalsLoading(false)
    }
  }

  return (
    <StoreSalesReportSummaryTemplate
      isLoading={requestState.status === 'loading'}
      isUpdateTotals={isUpdateTotalsLoading}
      date={formatSimpleDate(params.date)}
      defaultDate={DEFAULT_DATE}
      maxDate={dayjs().format('YYYY-MM-DD')}
      columns={getTableHeader(role)}
      role={role}
      rows={mappedSummaries}
      onClickTableRow={handleClickTableRow}
      onClickPrev={handleClickPrev}
      onClickNext={handleClickNext}
      onChangeDate={handleChangeDate}
      onChangeSearchText={handleSearchText}
      onUpdateTotals={handleTotals}
    />
  )
}

export default StoreSalesReportSummary
