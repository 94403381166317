// Tokenを一つにまとめるために、ルールを変更

/* eslint max-classes-per-file: ["error", 2] */
import TokenDisplay from './token_display'
import TokenType from './token_type'

export default interface Token {
  tokenType: TokenType
  // 生の文字列
  raw: string
  // 表示する文字列
  toDisplayString(): string
}

export class ActualToken implements Token {
  tokenType: TokenType

  raw: string

  private display: TokenDisplay

  constructor(tokenType: TokenType, raw: string, display: TokenDisplay) {
    this.tokenType = tokenType
    this.raw = raw
    this.display = display
  }

  toDisplayString(): string {
    return this.display.display()
  }
}

export class EOFToken implements Token {
  tokenType: TokenType

  raw: string

  constructor() {
    this.tokenType = TokenType.EOF
    this.raw = ''
  }

  toDisplayString(): string {
    return this.raw
  }
}
