import React from 'react'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import colors from '../../../styles/color'
import DatePicker from '../../atoms/DatePicker'

export type DatePaginationProps = {
  date: string
  defaultDate?: string
  maxDate?: string
  disabledPrev?: boolean
  disabledNext?: boolean
  onClickPrev: () => void
  onClickNext: () => void
  onChange: (value: string) => void
} & React.StyledProps

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    width: 24,
    minWidth: 'auto',
    height: 24,
    padding: '5px 10px',
    borderColor: colors.black300,
    color: colors.black,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '14px',
    '&:hover': {
      borderColor: colors.black300,
    },
  },
  date: {
    margin: '0 8px',
  },
})

const DatePagination: React.StyledFC<DatePaginationProps> = ({
  className,
  date,
  defaultDate,
  maxDate,
  disabledPrev,
  disabledNext,
  onClickPrev,
  onClickNext,
  onChange,
}: DatePaginationProps) => {
  const classes = useStyles()
  return (
    <div className={clsx(className, classes.root)}>
      <Button
        className={classes.button}
        type="button"
        variant="outlined"
        disabled={disabledPrev}
        onClick={onClickPrev}
      >
        {'<'}
      </Button>
      <DatePicker
        className={classes.date}
        date={date}
        defaultDate={defaultDate}
        maxDate={maxDate}
        disableArrowIcon
        onChange={onChange}
      />
      <Button
        className={classes.button}
        type="button"
        variant="outlined"
        disabled={disabledNext}
        onClick={onClickNext}
      >
        {'>'}
      </Button>
    </div>
  )
}

export default DatePagination
