import React from 'react'
import { Divider, List } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import StoreListItem from '../StoreListItem'

export type StoreListProps = {
  stores: store[]
} & React.StyledProps

type store = {
  name: string
  code: string
  to: string
  tenantCount: number
}

const useStyles = makeStyles({
  divider: {
    marginLeft: 55,
  },
})

const StoreList: React.StyledFC<StoreListProps> = ({
  className,
  stores,
}: StoreListProps) => {
  const classes = useStyles()

  return (
    <List className={className}>
      {stores.map((item) => {
        return (
          <React.Fragment key={item.code}>
            <StoreListItem
              storeName={item.name}
              storeCode={item.code}
              to={item.to}
              tenantCount={item.tenantCount}
            />
            <Divider
              className={classes.divider}
              variant="inset"
              component="li"
            />
          </React.Fragment>
        )
      })}
    </List>
  )
}

export default StoreList
