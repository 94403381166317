import React, { memo, useEffect, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useMount } from 'react-use'
import style from './styles.module.scss'
import SearchTextField from '../SearchTextField'
import Icon, { IconName } from '../../atoms/Icon'

export type CheckParm = {
  label: string
  value: string
  icon?: IconName
}
export type CheckFormType = {
  checkListValues: string[]
  allChecked: boolean
}

export type CheckBoxListWithFilterProps = {
  checkList: CheckParm[]
  formName: string
  defaultValues: CheckFormType
  disabledAllCheck?: boolean
  disableSearch?: boolean
  onChange?: (data: CheckFormType) => void
} & React.StyledProps

const searchFieldName = 'searchTenant'

const CheckBoxListWithFilter: React.StyledFC<CheckBoxListWithFilterProps> =
  memo(
    ({
      checkList,
      formName,
      defaultValues,
      disabledAllCheck = false,
      disableSearch,
      onChange,
    }: CheckBoxListWithFilterProps) => {
      const { control, getValues, setValue } = useFormContext()
      const [filteredCheckList, setFilteredCheckList] = useState<CheckParm[]>(
        []
      )

      const [checkStatus, setCheckStatus] = useState<CheckFormType>({
        checkListValues: [],
        allChecked: false,
      })

      useEffect(() => {
        setFilteredCheckList(checkList)
      }, [checkList])

      useMount(() => {
        const currentFormValue = getValues(formName)
        if (!currentFormValue) {
          setValue(formName, defaultValues)
          setCheckStatus(defaultValues)
        } else {
          setCheckStatus(currentFormValue)
        }
        return () => {
          if (onChange) {
            onChange(currentFormValue)
          }
        }
      })

      const handleCheckAll = (isChecked: boolean): CheckFormType => {
        let updatedValues: CheckFormType
        if (isChecked) {
          updatedValues = {
            allChecked: true,
            checkListValues: [],
          }
        } else {
          updatedValues = {
            allChecked: false,
            checkListValues: [],
          }
        }
        setCheckStatus(updatedValues)
        if (onChange) {
          onChange(updatedValues)
        }
        return updatedValues
      }
      const handleCheck = (
        isChecked: boolean,
        vlaue: string
      ): CheckFormType => {
        let updatedValues: CheckFormType
        const checkFormValue = getValues(formName)
        if (isChecked) {
          updatedValues = {
            allChecked: false,
            checkListValues: [...checkFormValue.checkListValues, vlaue],
          }
        } else {
          updatedValues = {
            allChecked: false,
            checkListValues: checkFormValue.checkListValues.filter(
              (val: string) => val !== vlaue
            ),
          }
        }
        setCheckStatus(updatedValues)
        if (onChange) {
          onChange(updatedValues)
        }
        return updatedValues
      }

      const searchFieldOnChange = (text: string) => {
        const filterdList = checkList.filter((item) => {
          return item.label.includes(text)
        })
        setFilteredCheckList(filterdList)
      }

      const handleClickClear = () => {
        searchFieldOnChange('')
        setValue(searchFieldName, '')
      }

      const hasIcon = checkList.some((item) => item.icon)

      return (
        <Controller
          control={control}
          name={formName}
          render={({ field }) => {
            return (
              <div className={style.container}>
                <div>
                  {!disableSearch && (
                    <SearchTextField
                      name={searchFieldName}
                      type="text"
                      placeholder="店舗名 or 店舗コード"
                      onClickClear={handleClickClear}
                      onChange={searchFieldOnChange}
                    />
                  )}
                </div>

                <FormControlLabel
                  control={
                    <>
                      <Checkbox
                        onChange={(event) =>
                          field.onChange(handleCheckAll(event.target.checked))
                        }
                        checked={checkStatus.allChecked}
                        onBlur={field.onBlur}
                        disabled={disabledAllCheck}
                      />
                      {hasIcon && <span className={style.iconSpace} />}
                    </>
                  }
                  key="all"
                  label="全て"
                  disabled={disabledAllCheck}
                  className={style.label}
                />
                {filteredCheckList.map((param) => {
                  return (
                    <FormControlLabel
                      control={
                        <>
                          <Checkbox
                            onChange={(event) =>
                              field.onChange(
                                handleCheck(event.target.checked, param.value)
                              )
                            }
                            checked={checkStatus.checkListValues.includes(
                              param.value
                            )}
                          />
                          {param.icon && (
                            <Icon className={style.icon} icon={param.icon} />
                          )}
                        </>
                      }
                      key={param.value}
                      label={param.label}
                      className={style.label}
                    />
                  )
                })}
              </div>
            )
          }}
        />
      )
    }
  )

CheckBoxListWithFilter.displayName = 'CheckBoxListWithFilter'
export default CheckBoxListWithFilter
