/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RequestState as MessageState } from '../../domain/request'
// eslint-disable-next-line import/no-cycle
import { AppThunkConfig } from '../../store'
import { purge } from '../../store/action'
import serializeError from '../services/error'
import AuthenticatedApi from '../services/authenticatedApi'

export const initialState: MessageState = {
  status: 'idle',
}

type PutMessageParam = {
  tenantId: string
  salesDateId: string
  salesDateRegisterId: string
  message: string
}

export const putMessage = createAsyncThunk<
  void,
  PutMessageParam,
  AppThunkConfig
>(
  'message/putMessages',
  async (params, { getState }) => {
    const { auth } = getState()
    await new AuthenticatedApi(auth.token).putTenantsIdSalesDateIdMessages(
      params.tenantId,
      params.salesDateId,
      params.salesDateRegisterId,
      {
        message: params.message,
      }
    )
  },
  { serializeError }
)

const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    clearMessage: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(putMessage.pending, (state) => {
      return {
        ...state,
        status: 'loading',
      }
    })
    builder.addCase(putMessage.fulfilled, () => {
      return {
        ...initialState,
        status: 'succeeded',
      }
    })
    builder.addCase(putMessage.rejected, (state, { error }) => {
      return {
        ...state,
        status: 'failed',
        error,
      }
    })
  },
})

export default slice.reducer

export const { clearMessage } = slice.actions
