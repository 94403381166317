export const TITLE = 'テナント情報編集'
export const DESCRIPTION =
  '「利用停止」に変更した場合、変更完了した時点から売上報告が利用不可となります。'

export const SUBMIT_BUTTON = '更新する'

export const MODAL_TITLE = `「一覧に戻る」を\n押下すると入力した内容が\n全てリセットされます。`

export const BACK_BUTTON = 'もどる'
export const AGREE_BUTTON = '一覧に戻る'
export const CHANCEL_BUTTON = 'キャンセル'
