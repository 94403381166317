import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'
import { ErrorMessage } from '@hookform/error-message'
import { FormValidation } from 'src/utils/form'
import TextField from '../../../../components/molecules/TextField'
import Badge from '../../../../components/atoms/Badge'
import styles from './styles.module.scss'
import { fullToHalf, fullHyphenToHalf } from '../../../../utils/string'
import { FormNameEnum } from '../../type'

export type LabeledTextFieldWithCountProps = {
  label: string
  name: string
  type: string
  defaultValue?: string
  placeholder?: string
  required?: boolean
  validations?: FormValidation[]
  maxLength?: number
} & React.StyledProps

const LabeledTextFieldWithCount: React.FC<LabeledTextFieldWithCountProps> = ({
  className,
  label,
  name,
  type,
  defaultValue,
  placeholder,
  required,
  validations,
  maxLength,
}) => {
  const { trigger, formState, setValue } = useFormContext()
  const watchedValue = useWatch({
    name,
    defaultValue: defaultValue ?? '',
  })

  const handleBlur = () => {
    if (name === FormNameEnum.staffTel) {
      const convertedValue = fullHyphenToHalf(fullToHalf(watchedValue))
      setValue(name, convertedValue)
    }
    trigger(name)
  }

  return (
    <Box className={clsx(className, styles.root)}>
      <Box className={styles.container}>
        <Typography component="h3" className={styles.label}>
          {label}
        </Typography>
        {required && (
          <Box>
            <Badge
              className={styles.badge}
              label="必須"
              size="small"
              color="red"
              fillType="color"
              ellipse
            />
          </Box>
        )}
      </Box>
      <Box>
        <Box className={styles.counterContainer}>
          {maxLength && (
            <Typography className={styles.counter}>
              {`${watchedValue.length}/${maxLength}`}
              <span>文字</span>
            </Typography>
          )}
        </Box>
        <TextField
          className={clsx(
            styles.textField,
            formState.errors[name] && styles.error
          )}
          name={name}
          type={type}
          defaultValue={defaultValue}
          placeholder={placeholder}
          required={required}
          validations={validations}
          maxLength={maxLength}
          onBlur={handleBlur}
        />
      </Box>
      <Box className={styles.errorContainer}>
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => {
            return (
              <Typography component="p" className={styles.errorMessage}>
                {message}
              </Typography>
            )
          }}
        />
      </Box>
    </Box>
  )
}

export default LabeledTextFieldWithCount
