import { matchPath } from 'react-router-dom'
import { Path as BreadcrumbsPath } from '../../components/molecules/Breadcrumbs'
import { TabOption } from '../../components/molecules/Tabs'
import Path, { PathTemplate } from '../../routes/path'
import { injectIndex } from '../../utils/array'

interface TenantDetailPresenter {
  breadcrumbs(
    orgCode: string,
    storeCode: string,
    storeName: string | undefined,
    tenantName: string | undefined,
    currentRoot: string | null
  ): BreadcrumbsPath[]
  tabOptions(orgCode: string): TabOption[]
}

const Presenter: TenantDetailPresenter = {
  breadcrumbs(
    orgCode: string,
    storeCode: string,
    storeName: string | undefined,
    tenantName: string | undefined,
    currentRoot: string | null
  ): BreadcrumbsPath[] {
    const storesPage = {
      title: '店舗一覧',
      to: Path.stores(orgCode),
    }
    const tenantsPage = {
      title: 'テナント一覧',
      to: Path.tenants(orgCode),
    }

    const paths = []
    const isFromTenants =
      currentRoot &&
      matchPath(
        {
          path: PathTemplate.tenants,
          caseSensitive: true,
          end: true,
        },
        currentRoot
      )

    if (isFromTenants) {
      paths.push(tenantsPage)
    } else {
      paths.push(storesPage)
      if (storeName) {
        paths.push({
          title: storeName,
          to: Path.store(orgCode, storeCode),
        })
      }
    }
    paths.push({
      title: tenantName ?? '',
      to: '', // リンクを作成しないために空文字
    })

    return injectIndex(paths)
  },
  // FIXME: orgCodeを使っていないので、引数から削除する
  tabOptions(_orgCode) {
    const tabs = [
      { title: 'テナント基本情報', index: 0 },
      { title: '算出ロジック', index: 1 },
      { title: 'レシート対応表', index: 2 },
    ]
    return tabs
  },
}

export default Presenter
