import { DefaultApi } from './api/api'
import { Configuration } from './api/configuration'
import { axiosInstance } from './axios'

export default class NoAuthApi extends DefaultApi {
  constructor() {
    const configuration = new Configuration({})
    const basePath = process.env.REACT_APP_API_BASE_URL
    super(configuration, basePath, axiosInstance)
  }
}
