import { Cell as Column } from '../../components/molecules/CalculationTableHeaderRow'
import { Record } from '../../components/organisms/CalculationTable'
import parseCalculationDSLs, {
  fillResultItems,
} from '../../slices/calculation/parser'
import {
  CalculationDSL,
  CalculationRoundingTypeEnum,
} from '../../slices/services/api'
import { injectIndex } from '../../utils/array'
import { Option } from '../../components/molecules/SelectWithOption'

interface TableData {
  columns: Column[]
  records: Record[]
}

const baseColumns: Omit<Column, 'index'>[] = [
  {
    text: 'No.',
    size: 'medium',
  },
  {
    text: '項目名',
    size: 'large',
  },
  {
    text: '',
    size: 'small',
  },
  {
    text: '',
    size: 'small',
  },
  {
    text: '算出ロジック',
    size: 'large',
  },
  {
    text: '',
    size: 'small',
  },
]

export const calculationRoundingTypeOptions: Option[] = [
  { title: '四捨五入', value: CalculationRoundingTypeEnum.Round },
  { title: '切り捨て', value: CalculationRoundingTypeEnum.Truncate },
  { title: '切り上げ', value: CalculationRoundingTypeEnum.RoundingUp },
]

export const precisionOptions: Option[] = [
  { title: '0桁', value: 0 },
  { title: '1桁', value: 1 },
  { title: '2桁', value: 2 },
]

export const getBaseColumns = () => {
  return [...baseColumns]
}

interface CalculationTabPresenter {
  convertToTableData(dsls: CalculationDSL[]): TableData
}

const Presenter: CalculationTabPresenter = {
  convertToTableData: (dsls: CalculationDSL[]): TableData => {
    if (dsls.length === 0) {
      return {
        columns: [],
        records: [],
      }
    }

    const dslsToParse = dsls.map((dsl) => {
      return {
        ...dsl,
        readItems: (dsl.ocrFormats ?? []).map((format) => {
          return {
            id: format.id,
            name: format.readItem,
          }
        }),
      }
    })
    const resultItems = parseCalculationDSLs(dslsToParse)
    const records: Record[] = resultItems.map((items, i) => {
      return {
        index: i,
        cells: injectIndex(items),
      }
    })

    const maxLength = Math.max(...resultItems.map((i) => i.length))

    const columnResultItems = fillResultItems(getBaseColumns(), maxLength)
    const columns: Column[] = injectIndex(columnResultItems)

    return {
      columns,
      records,
    }
  },
}

export default Presenter
