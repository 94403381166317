import { createTheme } from '@mui/material/styles'
import color from './styles/color'

const theme = createTheme({
  palette: {
    primary: {
      main: color.primary,
    },
    text: {
      primary: color.black,
      secondary: color.black400,
    },
  },
  // https://material.io/design/typography/the-type-system.html#applying-the-type-scale
  typography: {
    h1: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: 1.188, // = 38px / 32px
    },
    h2: {
      fontSize: 16,
      fontWeight: 700,
    },
    h3: {
      fontSize: 15,
    },
    h4: {
      fontSize: 14,
    },
    h5: {
      fontSize: 13,
    },
    h6: {
      fontSize: 12,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 700,
    },
    body1: {
      fontSize: 13,
      fontWeight: 400,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
    },
    button: {
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'none',
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: 56,
        },
        fontSizeSmall: {
          fontSize: 16,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: 'unset',
          paddingBottom: 'unset',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          fontSize: 18,
          fontWeight: 600,
        },
        containedSecondary: {
          color: color.black,
          backgroundColor: color.white,
          '&:hover': {
            backgroundColor: `${color.lightGray00} !important`,
          },
        },
        outlinedSecondary: {
          borderColor: color.black300,
          '&:hover': {
            backgroundColor: color.lightGray00,
            borderColor: color.black300,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: color.lightGray,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: color.lightGray,
          '&$checked': {
            color: color.primary,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          color: color.black,
          fontWeight: 500,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: color.black,
          textDecoration: 'none',
        },
      },
    },
  },
})

export default theme
