import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { removeToken, updateToken } from '../slices/auth/authSlice'
import { persistor } from '../store'

const LS_KEY_EMAIL_FOR_SIGNIN = 'EMAIL_FOR_SIGNIN'
interface AuthCtx {
  user?: firebase.User | null
  initializing: boolean
  sendEmailForSignIn: (email: string, url: string) => Promise<void>
  isSignInWithEmailLink: (emailLink: string) => boolean
  signIn: () => Promise<void>
  signOut: () => Promise<void>
  forceRefreshToken: () => Promise<void>
  getAuthToken: () => string
  getEmailForSignin: () => string
  getUserEmail: () => string
}

const initialAuthCtx = {
  initializing: false,
  sendEmailForSignIn: () => Promise.resolve(),
  isSignInWithEmailLink: () => true,
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  forceRefreshToken: () => Promise.resolve(),
  getAuthToken: () => '',
  getEmailForSignin: () => '',
  getUserEmail: () => 'auth@example.com',
}

export const AuthContext = createContext<AuthCtx>(initialAuthCtx)

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}
// デバッグ中に2回Initializeしないようにする
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig)
}

function useProvideAuth(): AuthCtx {
  const [user, setUser] = useState<firebase.User | null | undefined>(undefined)
  const [authToken, setAuthToken] = useState<string>('')
  const [initializing, setInitializing] = useState<boolean>(true)
  const dispatch = useDispatch()

  const sendEmailForSignIn = (email: string, url: string) => {
    const settings: firebase.auth.ActionCodeSettings = {
      url,
      handleCodeInApp: true,
    }
    return firebase
      .auth()
      .sendSignInLinkToEmail(email, settings)
      .then(() => {
        window.localStorage.setItem(LS_KEY_EMAIL_FOR_SIGNIN, email)
      })
  }

  const getEmailForSignin = (): string => {
    return window.localStorage.getItem(LS_KEY_EMAIL_FOR_SIGNIN) || ''
  }

  const getAuthToken = (): string => {
    return authToken
  }

  const getUserEmail = (): string => {
    return user?.email || ''
  }

  const isSignInWithEmailLink = (emailLink: string) => {
    return firebase.auth().isSignInWithEmailLink(emailLink)
  }

  const signIn = () => {
    const email = getEmailForSignin()
    if (!email) {
      return Promise.reject()
    }
    return firebase
      .auth()
      .signInWithEmailLink(email)
      .then((response) => {
        window.localStorage.removeItem(LS_KEY_EMAIL_FOR_SIGNIN)
        setUser(response.user)
      })
  }

  const signOut = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null)
        persistor.purge()
      })
  }

  const getIdToken = useCallback(() => {
    return user
      ?.getIdToken(/* forceRefresh */ true)
      .then((token) => {
        dispatch(updateToken(token))
        setAuthToken(token)
      })
      .catch(() => dispatch(removeToken()))
      .finally(() => setInitializing(false))
  }, [user, dispatch])

  const forceRefreshToken = async (): Promise<void> => {
    if (!user || !user.email) {
      return
    }

    await getIdToken()
  }

  useEffect(() => {
    // * Adds an observer for changes to the signed-in user's ID token, which includes
    // * sign-in, sign-out, and token refresh events.
    const unsubscribe = firebase.auth().onIdTokenChanged((authUser) => {
      setUser(authUser)
    })

    // Cleanup subscription on unmount
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    const fetchToken = async () => {
      // まだログイン中かログアウト中か確定していない
      if (user === undefined) {
        return
      }

      // ログアウトの状態のとき
      if (user === null || user.email === null) {
        dispatch(removeToken())
        setInitializing(false)
        return
      }

      await getIdToken()
    }
    fetchToken() // useEffectのなかで asyncとawaitを使用できないので回避処理
  }, [user, dispatch, getIdToken])

  return {
    user,
    initializing,
    sendEmailForSignIn,
    isSignInWithEmailLink,
    signIn,
    signOut,
    forceRefreshToken,
    getAuthToken,
    getEmailForSignin,
    getUserEmail,
  }
}

export function AuthProvider({
  children,
}: React.ChildrenProps): React.ReactElement {
  const auth = useProvideAuth()
  useEffect(() => {
    const refreshInterval = setInterval(
      () => {
        auth.forceRefreshToken()
      },
      55 * 60 * 1000
    )
    return () => clearInterval(refreshInterval)
  }, [auth])
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
