import React from 'react'
import { Box } from '@mui/material'
import clsx from 'clsx'
import style from './styles.module.scss'
import Icon, { IconName } from '../../atoms/Icon'

export type CircleWithIconProps = {
  icon: IconName
  size: number
  color: Color
} & React.StyledProps

export type Color = 'red' | 'black' | 'gray'

const colors = {
  red: '#ff0000',
  black: '#505050',
  gray: '#cdcdcd',
}

const CircleWithIcon: React.StyledFC<CircleWithIconProps> = ({
  className,
  icon,
  size,
  color,
}: CircleWithIconProps) => {
  return (
    <Box
      className={clsx(className, style.root)}
      sx={{
        width: size,
        height: size,
        fontSize: size / 2,
        backgroundColor: colors[color],
      }}
    >
      <Icon className={style.icon} icon={icon} />
    </Box>
  )
}

export default CircleWithIcon
