/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  Selector,
} from '@reduxjs/toolkit'
import { denormalize, normalize } from 'normalizr'
import { PaginatedRequestState } from '../../domain/request'
import {
  NormalizedSalesReportEntity,
  salesReportEntity,
} from '../../domain/schema'
// eslint-disable-next-line import/no-cycle
import { AppThunkConfig, RootState } from '../../store'
import { purge } from '../../store/action'
import toKey from '../../utils/key'
import {
  AggregatedResult,
  ReportStatusEnum,
  SalesReportWithTenant,
  Tenant,
} from '../services/api'
import serializeError from '../services/error'
import getTotalCount from '../services/handler'
import AuthenticatedApi from '../services/authenticatedApi'

interface SalesReportState extends EntityState<NormalizedSalesReportEntity> {
  query: {
    [key: string]: PaginatedRequestState
  }
}

const salesReportsAdapter = createEntityAdapter<NormalizedSalesReportEntity>()

export const initialState: SalesReportState =
  salesReportsAdapter.getInitialState({
    query: {},
  })

export type GetSalesReportsParams = {
  orgCode: string
  storeCode: string
  page: number
  perPage: number
  startDate?: string
  endDate?: string
  tenantCodeOrName?: string
  reportStatus?: ReportStatusEnum[]
  aggregatedResult?: AggregatedResult[]
}

interface GetSalesReportsEntities {
  salesReports: Record<string, NormalizedSalesReportEntity>
  tenants: Record<string, Tenant>
}

interface GetStoreSalesReportsReturned {
  entities: GetSalesReportsEntities
  totalCount: number
}

export const getSalesReports = createAsyncThunk<
  GetStoreSalesReportsReturned,
  GetSalesReportsParams,
  AppThunkConfig
>(
  'salesReports/getSalesReports',
  async (params, { getState }) => {
    const { auth } = getState()
    const response = await new AuthenticatedApi(
      auth.token
    ).getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
      params.orgCode,
      params.storeCode,
      params.page,
      params.perPage,
      params.startDate,
      params.endDate,
      params.tenantCodeOrName,
      params.reportStatus,
      params.aggregatedResult
    )

    const totalCount = getTotalCount(response)
    if (totalCount === undefined) {
      // TODO: Error handling
      const error = { message: '' }
      throw error
    }

    const normalized = normalize<
      NormalizedSalesReportEntity,
      GetSalesReportsEntities
    >(response.data, [salesReportEntity])
    return {
      entities: normalized.entities,
      totalCount,
    }
  },
  { serializeError }
)

const slice = createSlice({
  name: 'salesReports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(getSalesReports.pending, (state, { meta }) => {
      const key = toKey(meta.arg)

      return {
        ...state,
        query: {
          ...state.query,
          [key]: {
            status: 'loading',
          },
        },
      }
    })
    builder.addCase(getSalesReports.fulfilled, (state, { payload, meta }) => {
      const { entities, totalCount } = payload
      const key = toKey(meta.arg)

      salesReportsAdapter.upsertMany(state, entities.salesReports ?? {})

      if (entities.salesReports === undefined) {
        state.query[key] = {
          status: 'succeeded',
          totalCount: 0,
        }
      } else {
        state.query[key] = {
          status: 'succeeded',
          ids: Object.keys(entities.salesReports),
          totalCount,
          currentPage: meta.arg.page,
          totalPage: Math.ceil(totalCount / meta.arg.perPage),
        }
      }
    })
    builder.addCase(getSalesReports.rejected, (state, { error, meta }) => {
      const key = toKey(meta.arg)

      return {
        ...state,
        query: {
          ...state.query,
          [key]: {
            status: 'failed',
            error,
          },
        },
      }
    })
  },
})

export default slice.reducer

export const {
  selectById: selectSalesReportById,
  selectEntities: selectSalesReportEntities,
  selectAll: selectAllSalesReports,
} = salesReportsAdapter.getSelectors<SalesReportState>((state) => state)

export const selectSalesReportsByParams = (
  params: GetSalesReportsParams
): Selector<RootState, NormalizedSalesReportEntity[] | undefined> => {
  const key = toKey(params)
  return createSelector([(state) => state.entities.salesReports], (state) => {
    if (!state.query[key]) {
      return undefined
    }
    const entities = selectSalesReportEntities(state)
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return state.query[key].ids
      ?.map((id: any) => entities[id])
      .filter(
        (salesReport: any): salesReport is NormalizedSalesReportEntity =>
          salesReport !== undefined
      )
    /* eslint-enable */
  })
}

export const selectDenormalizedSalesReportsByParams = (
  params: GetSalesReportsParams
): Selector<RootState, SalesReportWithTenant[] | undefined> => {
  return createSelector(
    [
      (state) => state.entities.salesReports,
      (state) => state.entities.tenants.entities,
    ],
    (state, tenantEntities) => {
      const salesReportIds = state.query[toKey(params)]?.ids
      if (!salesReportIds) {
        return undefined
      }
      return denormalize(salesReportIds, [salesReportEntity], {
        salesReports: state.entities,
        tenants: tenantEntities,
      })
    }
  )
}

export const selectSalesReportsRequestStateByParams = (
  params: GetSalesReportsParams | undefined
): Selector<RootState, PaginatedRequestState> => {
  return createSelector([(state) => state.entities.salesReports], (state) => {
    if (!params) {
      return { status: 'idle' }
    }
    return (
      state.query[toKey(params)] ?? {
        status: 'idle',
      }
    )
  })
}
