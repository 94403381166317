import TokenDisplay from '.'
import { first } from '../../../utils/array'

type IdName = { id: string; name: string }

export default class ReplaceWithIdDisplay implements TokenDisplay {
  str: string

  items: IdName[]

  cachedDisplayString: string | null

  constructor(str: string, items: IdName[]) {
    this.str = str
    this.items = items
    this.cachedDisplayString = null
  }

  display(): string {
    if (this.cachedDisplayString !== null) {
      return this.cachedDisplayString
    }
    const names = this.items
      .filter((item) => this.str.includes(item.id))
      .map((item) => item.name)
    this.cachedDisplayString = first(names)
    if (this.cachedDisplayString === null) {
      return this.str
    }
    return this.cachedDisplayString
  }
}
