import React from 'react'
import { Box, Table, TableBody, TableHead } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import color from '../../../styles/color'
import { TABLE_DIVIDER_THICKNESS } from '../../../styles/constants'
import CalculationTableBodyRow, {
  Cell as BodyCell,
} from '../../molecules/CalculationTableBodyRow'
import CalculationTableHeaderRow, {
  Cell as HeaderCell,
} from '../../molecules/CalculationTableHeaderRow'

export type CalculationTableProps = {
  columns: HeaderCell[]
  records: Record[]
} & React.StyledProps

export type Record = {
  index: number
  cells: BodyCell[]
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
  },
  leftTableWrapper: {
    position: 'sticky',
    zIndex: 1,
    left: 0,
  },
  leftTable: {
    borderRight: `${TABLE_DIVIDER_THICKNESS}px solid ${color.lightGray02}`,
  },
  rightTableWrapper: {
    zIndex: 0,
  },
})

const CalculationTable: React.StyledFC<CalculationTableProps> = ({
  columns,
  records,
  className,
}: CalculationTableProps) => {
  const classes = useStyles()

  return (
    <Box className={clsx(classes.root, className)}>
      <Box className={classes.leftTableWrapper}>
        <Table stickyHeader className={classes.leftTable}>
          <TableHead>
            <CalculationTableHeaderRow cells={columns.slice(0, 3)} />
          </TableHead>
          <TableBody>
            {records.map((record, index) => (
              <CalculationTableBodyRow
                key={record.index}
                cells={record.cells.slice(0, 3)}
                isGray={index % 2 === 1}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box className={classes.rightTableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <CalculationTableHeaderRow cells={columns.slice(3)} />
          </TableHead>
          <TableBody>
            {records.map((record, index) => (
              <CalculationTableBodyRow
                key={record.index}
                cells={record.cells.slice(3)}
                isGray={index % 2 === 1}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

export default CalculationTable
