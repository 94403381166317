import React from 'react'
import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  Divider,
  Box,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import color from '../../../styles/color'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import QRCode, { QRCodeProps } from '../../atoms/QRCode'

export type QRCodeModalProps = {
  open: boolean
  title: string
  disableBackdropClick?: boolean
  buttonText: string
  sections: Section[]
  id: string
  onClickDownload: () => void
  onClickClose: () => void
} & React.StyledProps &
  QRCodeProps

export type Section = {
  title: string
  value: string
}

const useStyles = makeStyles({
  backDrop: {
    backgroundColor: color.black50,
  },
  dialog: {
    width: 532,
    paddingBottom: 36,
    borderRadius: 6,
  },
  pdf: {
    width: '100%',
    alignSelf: 'center',
    textAlign: 'center',
  },
  titleTypography: {
    margin: 'auto',
    fontSize: 16,
    fontWeight: 400,
  },
  title: {
    display: 'flex',
    height: 28,
  },
  action: {
    display: 'grid',
    justifyContent: 'center',
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 0,
    width: 20,
    height: 20,
    padding: 0,
    margin: 'auto 20px',
    backgroundColor: color.white,
  },
  qrCodeWrapper: {
    margin: '50px auto',
  },
  qrCode: {
    padding: 28,
    backgroundColor: color.white,
    boxShadow: '0 0 40px rgba(0, 0, 0, 0.25)',
  },
  sectionTitle: {
    marginBottom: 8,
    fontSize: 16,
    fontWeight: 400,
  },
  sectionValue: {
    fontSize: 20,
    fontWeight: 700,
  },
  section: {
    width: '80%',
    margin: '0 auto 30px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  downloadButton: {
    width: 320,
    height: 64,
    padding: '16px 36px',
    borderRadius: 16,
    margin: '40px auto 0',
    fontSize: 16,
  },
})

const QRCodeModal: React.StyledFC<QRCodeModalProps> = ({
  className,
  open,
  title,
  disableBackdropClick,
  buttonText,
  sections,
  id,
  src,
  size,
  onClickDownload,
  onClickClose,
}: QRCodeModalProps) => {
  const classes = useStyles()

  const handleClose = (_: Event, reason: string) => {
    if (reason === 'backdropClick' && disableBackdropClick) return
    onClickClose?.()
  }

  return (
    <Dialog
      classes={{
        container: className,
        paper: classes.dialog,
      }}
      BackdropProps={{
        className: classes.backDrop,
      }}
      open={open}
      data-testid="QRCodeModal"
      onClose={handleClose}
    >
      <div id={id} className={classes.pdf}>
        <DialogTitle className={classes.title}>
          <Typography className={classes.titleTypography}>{title}</Typography>
        </DialogTitle>
        <IconButton
          className={classes.closeButton}
          onClick={onClickClose}
          size="large"
          data-html2canvas-ignore
        >
          <Icon icon="close" />
        </IconButton>
        <Divider />
        <Box className={classes.qrCodeWrapper}>
          <QRCode className={classes.qrCode} src={src} size={size} />
        </Box>
        {sections.map((section) => {
          return (
            <Box className={classes.section} key={section.title}>
              <Typography className={classes.sectionTitle} variant="h4">
                {section.title}
              </Typography>
              <Typography className={classes.sectionValue} variant="h4">
                {section.value}
              </Typography>
            </Box>
          )
        })}
      </div>
      <Button
        className={classes.downloadButton}
        color="primary"
        size="large"
        title={buttonText}
        onClick={onClickDownload}
      />
    </Dialog>
  )
}

export default QRCodeModal
