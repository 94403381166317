import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import {
  createEnumArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
  JsonParam,
  BooleanParam,
} from 'use-query-params'
import {
  DateRange,
  DefaultFilterValues,
  FilterOptions,
} from 'src/features/salesReport/type'
import { ReportStatusEnum } from '../../../../slices/services/api'
import {
  ConvertedAggregatedResult,
  getConvertedAggregatedResults,
} from '../../../../domain/consistency'
import SalesReportsTabTemplate from '../../templates/SalesReportsTab'
import { DEFAULT_PAGE } from '../../../../constants'
import {
  getSalesReports,
  GetSalesReportsParams,
  selectDenormalizedSalesReportsByParams,
  selectSalesReportsRequestStateByParams,
} from '../../../../slices/salesReports/salesReportsSlice'
import Path, { StorePathParams } from '../../../../routes/path'
import { useAppDispatch, useAppSelector } from '../../../../store'
import Presenter from './presenter'

const PER_PAGE = 20
const DEFAULT_DATE = dayjs().add(-1, 'day').format('YYYY-MM-DD')

type Props = {
  stickyTop?: number
}

const SalesReportsTab: React.FC<Props> = ({
  stickyTop,
}: Props): ReactElement => {
  const { orgCode, storeCode } = useParams<StorePathParams>() as StorePathParams
  const navigate = useNavigate()
  const role = useAppSelector((state) => state.auth.currentAuthorization?.role)

  // Query Params
  const queryConfig = {
    page: withDefault(NumberParam, DEFAULT_PAGE),
    tenantCodeOrName: withDefault(StringParam, undefined),
    startDate: withDefault(StringParam, DEFAULT_DATE),
    endDate: withDefault(StringParam, DEFAULT_DATE),
    reportStatus: withDefault(
      // NOTE: 選択されたフィルター条件をarrayで渡す
      createEnumArrayParam<ReportStatusEnum>([
        ReportStatusEnum.Waiting,
        ReportStatusEnum.Reported,
        ReportStatusEnum.ImageResent,
        ReportStatusEnum.FinalizedReportSynced,
        ReportStatusEnum.FinalizedReportSubmitted,
      ]),
      [] as ReportStatusEnum[]
    ),
    // NOTE: 選択されたフィルター条件をオブジェクトの配列で渡す
    aggregatedResult: withDefault(JsonParam, []),
    filterForMismatch: withDefault(BooleanParam, false),
  }
  const [
    {
      page,
      tenantCodeOrName,
      startDate,
      endDate,
      reportStatus,
      aggregatedResult,
      filterForMismatch,
    },
    setQuery,
  ] = useQueryParams(queryConfig)

  const [defaultFilterValues] = useState<DefaultFilterValues>(
    Presenter.getFilterValuesFromQuery(
      startDate,
      endDate,
      reportStatus,
      aggregatedResult,
      filterForMismatch,
      tenantCodeOrName
    )
  )

  const parsedPage = page || DEFAULT_PAGE
  const params = useMemo(() => {
    return {
      orgCode,
      storeCode,
      page,
      perPage: PER_PAGE,
      tenantCodeOrName,
      startDate,
      endDate,
      reportStatus,
      aggregatedResult,
    }
  }, [
    orgCode,
    storeCode,
    page,
    tenantCodeOrName,
    startDate,
    endDate,
    reportStatus,
    aggregatedResult,
  ])

  // Redux
  const salesReports = useAppSelector(
    selectDenormalizedSalesReportsByParams(params)
  )
  const getSalesReportsState = useAppSelector(
    selectSalesReportsRequestStateByParams(params)
  )

  const convertedSalesReports = useMemo(() => {
    if (!salesReports) return []
    return Presenter.convertResponseToTableData(salesReports)
  }, [salesReports])

  // API Request
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (getSalesReportsState.status === 'idle') {
      const requestParam: GetSalesReportsParams = {
        orgCode: params.orgCode,
        storeCode: params.storeCode,
        page: params.page,
        perPage: params.perPage,
        tenantCodeOrName: params.tenantCodeOrName,
        startDate: params.startDate,
        endDate: params.endDate,
        reportStatus: params.reportStatus,
        aggregatedResult: params.aggregatedResult,
      }
      dispatch(getSalesReports(requestParam))
    }
  }, [dispatch, getSalesReportsState.status, params])

  // Event handler
  const handleClickTenantName = (tenantCode: string) => {
    navigate(Path.tenant(orgCode, storeCode, tenantCode))
  }

  const handleClickPagination = (move: number) => {
    setQuery({ page: move })
  }

  const handleChangeDate = (newDate: DateRange) => {
    setQuery({
      page: DEFAULT_PAGE,
      startDate: newDate.start,
      endDate: newDate.end,
    })
  }

  const handleChangeFilterForMismatch = (isChecked: boolean) => {
    setQuery({
      filterForMismatch: isChecked,
    })
  }

  const handleChangeFilter = (filterOptions: FilterOptions) => {
    const convertedAggregatedResults: ConvertedAggregatedResult[] =
      getConvertedAggregatedResults(filterOptions.consistency)
    setQuery({
      page: DEFAULT_PAGE,
      tenantCodeOrName: filterOptions.tenantCodeOrName || '',
      reportStatus: filterOptions.reportStatus,
      aggregatedResult: convertedAggregatedResults.map((item) =>
        JSON.stringify(item)
      ),
    })
  }

  return (
    <SalesReportsTabTemplate
      isLoaded={getSalesReportsState.status === 'succeeded'}
      currentPage={parsedPage}
      rowsPerPage={PER_PAGE}
      role={role}
      stickyTop={stickyTop}
      salesReports={convertedSalesReports}
      defaultFilterValues={defaultFilterValues}
      filterForMismatch={filterForMismatch}
      onChangeFilter={handleChangeFilter}
      onClickTenantName={handleClickTenantName}
      onClickPagination={handleClickPagination}
      totalCount={getSalesReportsState.totalCount ?? 0}
      date={{
        start: startDate,
        end: endDate,
      }}
      onChangeDate={handleChangeDate}
      onChangeFilterForMismatch={handleChangeFilterForMismatch}
    />
  )
}

SalesReportsTab.defaultProps = {
  stickyTop: 0,
}

export default SalesReportsTab
