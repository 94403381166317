import { TenantFormType } from '../../components/organisms/TenantCreateTable'
import { CreateTenant } from '../../slices/editTenants/editTenantsSlice'
import { TenantStatusEnum } from '../../slices/services/api'
import { fullToHalf } from '../../utils/string'

interface CreateTenantPresenter {
  requestBody(values: TenantFormType): CreateTenant[]
}

export const Presenter: CreateTenantPresenter = {
  requestBody(values) {
    return values.tenants.map((tenant) => {
      return {
        name: fullToHalf(tenant.name),
        code: fullToHalf(tenant.code),
        status: TenantStatusEnum.NotReady,
      }
    })
  },
}
