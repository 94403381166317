import React, {
  createContext,
  useState,
  useContext,
  memo,
  ChildrenProps,
} from 'react'
import Toast, { Variant } from '../components/atoms/Toast'

type ToastParams = {
  isOpen?: boolean
  variant?: Variant
  title?: string
  autoHideDuration?: number
  duration?: number
}

type ToastTriggerContextType = {
  sendToast: (params: ToastParams) => void
}
type ToastParamContextType = {
  toastParam: ToastParams
  onCloseToast: () => void
}

export const ToastTriggerContext = createContext({} as ToastTriggerContextType)
// Note: ToastParamContextはToastContentのみで利用し外部には公開しない
const ToastParamContext = createContext({} as ToastParamContextType)

const ToastContextProvider = ({ children }: ChildrenProps) => {
  const [toastParam, setToastParam] = useState({} as ToastParams)
  const sendToast = (param: ToastParams) => {
    // FIXME: https://github.com/wedinc/boba-admin/issues/2461
    setTimeout(() => {
      setToastParam({
        isOpen: true,
        variant: param.variant,
        title: param.title,
        autoHideDuration: param.autoHideDuration,
        duration: param.duration,
      })
    }, 0)
  }
  const onCloseToast = () => {
    setToastParam({ isOpen: false })
  }

  // NOTE: react/jsx-no-constructed-context-values がでるが、
  // ToastParamContextをToastContentのみで利用
  // ToastTriggerContextをmainのchildrenで利用するので、
  // toastParamの変更がchildrenに伝わらないので、実質的にはchildrenへのrenderを防いでいる。
  // https://qiita.com/soarflat/items/b154adc768bb2d71af21
  // https://github.com/facebook/react/issues/15156#issuecomment-474590693
  /* eslint-disable react/jsx-no-constructed-context-values */
  return (
    <ToastTriggerContext.Provider value={{ sendToast }}>
      <ToastParamContext.Provider value={{ toastParam, onCloseToast }}>
        {children}
      </ToastParamContext.Provider>
    </ToastTriggerContext.Provider>
  )
  /* eslint-enable */
}

const ToastContent = memo(() => {
  const { toastParam, onCloseToast } = useContext(ToastParamContext)
  return (
    <Toast
      open={toastParam.isOpen ?? false}
      title={toastParam.title ?? ''}
      variant={toastParam.variant ?? 'success'}
      duration={toastParam.duration ?? 400}
      autoHideDuration={toastParam.autoHideDuration ?? 3000}
      direction="left"
      position={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={onCloseToast}
    />
  )
})
ToastContent.displayName = 'ToastContent'

const ChildContent: React.FC<ChildrenProps> = memo(
  ({ children }: ChildrenProps) => {
    return <div>{children}</div>
  }
)
ChildContent.displayName = 'ChildContent'

export const ToastProvider = ({ children }: ChildrenProps) => {
  return (
    <ToastContextProvider>
      <ToastContent />
      <ChildContent>{children}</ChildContent>
    </ToastContextProvider>
  )
}
