import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import { FormProvider, useForm } from 'react-hook-form'
import styles from './styles.module.scss'
import SelectWithOption, {
  Option,
} from '../../../../components/molecules/SelectWithOption'
import { FormNameEnum, FormTypesSalesReportTab } from '../../type'

export type SalesReportDateOptionsProps = {
  selectOptions: Option[]
  registerIndex: number
  onSelectDateChanged: (selectedDate: string) => void
} & React.StyledProps

const SalesReportDateOptions: React.StyledFC<SalesReportDateOptionsProps> = ({
  className,
  selectOptions,
  registerIndex,
  onSelectDateChanged,
}: SalesReportDateOptionsProps) => {
  const formMethods = useForm<FormTypesSalesReportTab>({
    defaultValues: { selectSalesDate: '' },
  })

  const watchSelectDate = formMethods.watch(FormNameEnum.selectSalesDate)

  useEffect(() => {
    const selectedDate = watchSelectDate || (selectOptions[0]?.value as string)
    if (!selectedDate) return

    onSelectDateChanged(selectedDate)
  })

  const shouldDisplayDateOption =
    selectOptions.length > 1 && registerIndex === 0

  return (
    <Box className={clsx(className, styles.registerHeader)}>
      <Box className={styles.textAndSelectBox}>
        <Box
          className={clsx(styles.number, {
            [styles.numberRightMargin]: !shouldDisplayDateOption,
          })}
        >
          <Typography component="span" className={styles.registerHeaderNumber}>
            <span className={styles.reportTitle}>報告回数</span>
            <span className={styles.reportNumber}>{selectOptions.length}</span>
            <span className={styles.reportUnit}>件</span>
          </Typography>
        </Box>

        {shouldDisplayDateOption && (
          <FormProvider {...formMethods}>
            <SelectWithOption
              className={styles.selectBox}
              name={FormNameEnum.selectSalesDate}
              options={selectOptions}
              placeholder={selectOptions[0]?.title}
              defaultValue={selectOptions[0]?.value as string | undefined}
              variant="outlined"
            />
          </FormProvider>
        )}
      </Box>
    </Box>
  )
}

export default SalesReportDateOptions
