import React, { ReactElement, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoginData } from '../../components/organisms/LoginForm'
import LoginTemplate from '../../components/templates/Login'
import useAppTitle from '../../hooks/useAppTitle'
import { AuthContext } from '../../context/auth.context'
import Path from '../../routes/path'
import { useApiEmailExistence } from '../../hooks/useApiEmailExistence'

const Login = (): ReactElement => {
  useAppTitle('ログイン')
  const authContext = useContext(AuthContext)
  const { getEmailExistence } = useApiEmailExistence()
  const navigate = useNavigate()
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [isEmailExistence, setIsEmailExistence] = useState<boolean | undefined>(
    undefined
  )

  const handleSubmit = async (data: LoginData) => {
    setDisabledSubmit(true)
    const url =
      process.env.REACT_APP_ENV !== 'preview'
        ? process.env.REACT_APP_FIREBASE_AUTH_URL
        : // NOTE: preview は固定 URL ではないので、現在の URL から action を生成する
          `${window.location.href}/action`

    const res = await getEmailExistence(data.email)
    if (res.data && !res.data.isExist) {
      setDisabledSubmit(false)
      setIsEmailExistence(false)
      return
    }
    setIsEmailExistence(true)

    try {
      await authContext.sendEmailForSignIn(data.email, url)
      navigate(Path.loginSentEmail)
    } finally {
      setDisabledSubmit(false)
    }
  }
  return (
    <LoginTemplate
      disabledSubmit={disabledSubmit}
      onSubmit={handleSubmit}
      isEmailExistence={isEmailExistence}
    />
  )
}

export default Login
