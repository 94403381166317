import React, { createRef, useState, useEffect } from 'react'
import {
  Tooltip as MaterialTooltip,
  TooltipProps as MaterialTooltipProps,
} from '@mui/material'
import style from './styles.module.scss'

export type TooltipProps = {
  showOnlyEllipsis?: boolean // 「...」 で文字が省略されたときだけツールチップを表示するかどうか
} & React.StyledProps &
  MaterialTooltipProps

const Tooltip: React.StyledFC<TooltipProps> = ({
  className,
  open = false,
  title,
  disableHoverListener,
  enterDelay,
  children,
  showOnlyEllipsis,
  placement = 'top-start',
}: TooltipProps) => {
  const ref = createRef<HTMLDivElement>()

  const [isShowEllipsis, setIsShowEllipsis] = useState<boolean>(false)

  useEffect(() => {
    if (ref && ref.current && ref.current.firstElementChild) {
      const { scrollWidth, clientWidth } = ref.current.firstElementChild
      setIsShowEllipsis(scrollWidth > clientWidth)
    }
  }, [ref, title, children])

  return (
    <MaterialTooltip
      className={className}
      classes={{
        tooltip: style.tooltip,
        tooltipPlacementTop: style.placementTop,
        tooltipPlacementBottom: style.placementBottom,
      }}
      open={open}
      title={title}
      disableHoverListener={
        disableHoverListener || (showOnlyEllipsis && !isShowEllipsis)
      }
      enterDelay={enterDelay}
      placement={placement}
    >
      <div ref={ref}>{children}</div>
    </MaterialTooltip>
  )
}

export default Tooltip
