export enum FormNameEnum {
  staffName = 'staffName',
  staffTel = 'staffTel',
  contractStartDate = 'contractStartDate',
  contractEndDate = 'contractEndDate',
  note = 'note',
}

export type FormTypes = {
  [FormNameEnum.staffName]: string
  [FormNameEnum.staffTel]: string
  [FormNameEnum.contractStartDate]: string
  [FormNameEnum.contractEndDate]: string
  [FormNameEnum.note]: string
}
