import { RegexThreeDigitsSeparator } from 'src/utils/regularExpression'
import { fullHyphenToHalf, fullToHalf } from 'src/utils/string'

const removeNonNumericCharactersExceptSomeCharacters = (str: string) => {
  // 先頭の一文字だけハイフンを許容する
  const firstChar = str.charAt(0).replace(/[^\d０-９ー-]/g, '')
  // 先頭の一文字を除いて、数字と全角数字と.以外の文字を削除する
  const processedStr = str.slice(1).replace(/[^\d０-９.]|(\.[^.]*)\.*/g, '$1')

  // 小数点で文字列を分割
  const [integerPart, fractionalPart] = (firstChar + processedStr).split('.')

  // 小数部分から数字以外の文字を除去するのと、最大2桁まで取得
  const cleanedFractionalPart = fractionalPart
    ? fractionalPart.replace(/[^\d０-９]/g, '').slice(0, 2)
    : ''

  // 結果を組み立て(末尾のピリオドを保持)
  return `${integerPart}${
    cleanedFractionalPart || str.endsWith('.') ? '.' : ''
  }${cleanedFractionalPart}`
}

// NOTE: カンマの数の増減を考慮してカーソル位置を調整する
export const getAdjustedCursorPosition = (
  selectionStart: number | null,
  previousValue: string,
  newValue: string
): number => {
  const previousCommaCount = (previousValue.match(/,/g) || []).length
  const newCommaCount = (newValue.match(/,/g) || []).length
  const commaDiff = newCommaCount - previousCommaCount
  const initialCursorPosition = selectionStart || 0
  const isIncludesHyphen = newValue.includes('-')

  let adjustedCursorPosition = initialCursorPosition + commaDiff

  // NOTE: ハイフン付きの数字の先頭を消してカンマが減る場合、カーソルがハイフンの後に来るようにする
  // ex: -1,234 → -234
  if (isIncludesHyphen) {
    adjustedCursorPosition = Math.max(1, adjustedCursorPosition)
    return adjustedCursorPosition
  }

  // NOTE: 先頭の数字を消してカンマが減る場合、adjustedCursorPositionが負の値になるのを防ぐ
  // ex: 1,234 → 234
  adjustedCursorPosition = Math.max(0, adjustedCursorPosition)

  return adjustedCursorPosition
}

export const getValueWithComma = (valueWithoutComma: string): string => {
  return valueWithoutComma.replace(RegexThreeDigitsSeparator, ',')
}

export const getHalfWidthNumericString = (inputValue: string): string => {
  const numericStringWithoutComma =
    removeNonNumericCharactersExceptSomeCharacters(inputValue)

  if (!numericStringWithoutComma) return ''

  const convertedWithoutFullHyphen = fullHyphenToHalf(numericStringWithoutComma)

  return fullToHalf(convertedWithoutFullHyphen)
}
