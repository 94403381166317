export default interface FirebaseError {
  code: string
  message: string
}

const messages: { [code: string]: string } = {
  'auth/expired-action-code': 'メールリンクの有効期限が切れています',
  'auth/invalid-email': 'メールアドレスが不正です',
  'auth/user-disabled': '無効なユーザーです',
}

export const localizedMessage = (code: string): string => {
  return messages[code] ?? '認証中にエラーが発生しました'
}
