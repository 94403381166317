import React from 'react'
import { Box, Typography } from '@mui/material'
import style from './styles.module.scss'

export type HeadlineProps = {
  headline: string
  description?: string
} & React.StyledProps

const Headline: React.StyledFC<HeadlineProps> = ({
  headline,
  description,
  className,
}: HeadlineProps) => {
  return (
    <Box className={className}>
      <Typography className={style.headline} variant="h2">
        {headline}
      </Typography>
      {description && <Typography variant="h4">{description}</Typography>}
    </Box>
  )
}

export default Headline
