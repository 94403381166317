/* eslint-disable react/prop-types */
import React from 'react'
import { Box, Modal } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import {
  InvalidInputErrorMessage,
  RegexNoSpaceOnly,
} from 'src/utils/regularExpression'
import color from '../../../styles/color'
import Button from '../../atoms/Button'
import TextField from '../TextField'

export type InputModalProps = {
  open: boolean
  name: string
  type: 'text' | 'number'
  defaultValue?: string | number
  placeholder?: string
  confirmButtonText: string
  closeButtonText: string
  onClickConfirm: () => void
  onClickClear: () => void
  onClose: () => void
} & React.StyledProps

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
  },
  box: {
    position: 'absolute',
    padding: '24px 20px',
    borderRadius: 6,
    backgroundColor: color.white,
    outline: 'none',
  },
  buttonWrapper: {
    marginTop: 20,
    textAlign: 'right',
  },
  button: {
    width: 80,
    fontSize: 15,
    fontWeight: 400,
  },
  close: {
    border: 'none',
    marginRight: 16,
    whiteSpace: 'nowrap',
    '&:hover': {
      border: 'none',
    },
  },
})

const InputModal: React.StyledFC<InputModalProps> = ({
  className,
  open,
  name,
  type,
  defaultValue,
  placeholder,
  confirmButtonText,
  closeButtonText,
  onClickConfirm,
  onClose,
}) => {
  const classes = useStyles()

  return (
    <Modal // `disableBackdropClick` is removed by codemod.
      // You can find more details about this breaking change in [the migration guide](https://mui.com/guides/migration-v4/#modal)
      open={open}
      onClose={onClose}
      className={clsx(className, classes.modal)}
    >
      <Box className={classes.box}>
        <TextField
          name={name}
          type={type}
          defaultValue={defaultValue}
          placeholder={placeholder}
          validations={[
            {
              regex: RegexNoSpaceOnly,
              message: InvalidInputErrorMessage,
            },
          ]}
        />
        <Box className={classes.buttonWrapper}>
          <Button
            title={closeButtonText}
            className={clsx(classes.button, classes.close)}
            onClick={onClose}
            color="secondary"
            size="medium"
          />
          <Button
            title={confirmButtonText}
            className={classes.button}
            onClick={onClickConfirm}
            color="primary"
            size="medium"
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default InputModal
