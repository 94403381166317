/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
interface AppError {
  code: string
  message: string
  showSnackbar: boolean
}

export interface PostCalculationDSLError extends AppError {
  errors?: CalculationDSLPostErrorItem[] // 追加
}

export interface CalculationDSLPostErrorItem {
  // eslint-disable-next-line camelcase
  calculation_id: string
  code: string
  field: string
  resource: string
}

export interface PutDefiniteValueError extends AppError {
  errors?: DefiniteValueErrorItem[] // 追加
}

interface DefiniteValueErrorItem {
  field: string
  code: string
}

export function isAppError(err: any): err is AppError {
  if (!err) {
    return false
  }
  return (
    typeof err === 'object' &&
    typeof err.code === 'string' &&
    typeof err.message === 'string' &&
    typeof err.showSnackbar === 'boolean' &&
    typeof err.needSignout === 'boolean'
  )
}

export function isPostCalculationDSLError(
  data: any
): data is PostCalculationDSLError {
  if (!data) {
    return false
  }
  return (
    data.message !== undefined &&
    typeof data.message === 'string' &&
    data.errors !== undefined &&
    typeof data.errors === 'object' &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data.errors.every((error: any) => {
      return (
        error.calculation_id !== undefined &&
        typeof error.calculation_id === 'string' &&
        error.code !== undefined &&
        typeof error.code === 'string' &&
        error.field !== undefined &&
        typeof error.field === 'string' &&
        error.resource !== undefined &&
        typeof error.resource === 'string'
      )
    })
  )
}

export function isPutDefiniteValueError(
  data: any
): data is PutDefiniteValueError {
  if (!data) {
    return false
  }
  return (
    data.message !== undefined &&
    typeof data.message === 'string' &&
    data.errors !== undefined &&
    typeof data.errors === 'object' &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data.errors.every((error: any) => {
      return (
        error.code !== undefined &&
        typeof error.code === 'string' &&
        error.field !== undefined &&
        typeof error.field === 'string'
      )
    })
  )
}

export default AppError
