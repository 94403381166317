import React, { useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import { useMount } from 'react-use'
import {
  consistencyCheckOptions,
  ConsistencyEnum,
  getConsistencyTitle,
} from '../../../../domain/consistency'
import { ReportStatusEnum, RoleEnum } from '../../../../slices/services/api'

import PopupChild from '../../../../components/molecules/PopupChild'
import CheckBoxListWithFilter, {
  CheckFormType,
} from '../../../../components/molecules/CheckBoxListWithFilter'

import SearchTextField from '../../../../components/molecules/SearchTextField'

import { getMemoTitle, memoCheckOptions } from '../../constantsAndFunction'
import {
  getReportStatusTitle,
  statusCheckOptions,
} from '../../../../domain/reportStatus'
import { FilterParamType, MemoType } from '../../type'
import style from './styles.module.scss'
import {
  AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
  isOneOfRoles,
} from '../../../../domain/role'

const SS_KEY_TENANT_SALES_REPORT_SUMMARY_FILTER =
  'tenantSalesReportSummaryFilter'

export type TenantSalesReportSummaryHeaderProps = {
  role?: RoleEnum
  onChangeFilterParams: (filterData: FilterParamType) => void
} & React.StyledProps

type FilterFormType = {
  reportStatus?: CheckFormType
  memo?: CheckFormType
  searchText?: string
  consistency?: CheckFormType
}

export const TenantSalesReportSummaryFilterBar: React.StyledFC<
  TenantSalesReportSummaryHeaderProps
> = ({
  className,
  role,
  onChangeFilterParams,
}: TenantSalesReportSummaryHeaderProps) => {
  const [memoTitle, setMemoTitle] = useState('全て')
  const [reportStatusTitle, setReportStatusTitle] = useState('全て')
  const [consistencyTitle, setConsistencyTitle] = useState('全て')

  const formInitialParam: FilterFormType = useMemo(() => {
    const storedFilterParam = sessionStorage.getItem(
      SS_KEY_TENANT_SALES_REPORT_SUMMARY_FILTER
    )
    const filterParam = storedFilterParam ? JSON.parse(storedFilterParam) : {}
    return {
      reportStatus: {
        allChecked: filterParam.reportStatus?.allChecked ?? true,
        checkListValues: filterParam.reportStatus?.checkListValues || [],
      },
      searchText: filterParam.searchText || '',
      memo: {
        allChecked: filterParam.memo?.allChecked ?? true,
        checkListValues: filterParam.memo?.checkListValues || [],
      },
      consistency: {
        allChecked: filterParam.consistency?.allChecked ?? true,
        checkListValues: filterParam.consistency?.checkListValues || [],
      },
    }
  }, [])

  const formMethods = useForm<FilterFormType>({
    defaultValues: formInitialParam,
  })
  const reportStatusList = formMethods.watch('reportStatus')?.checkListValues
  const memoList = formMethods.watch('memo')?.checkListValues
  const consistencyList = formMethods.watch('consistency')?.checkListValues

  const setFilterParamToSessionStorage = (formValue: FilterFormType) => {
    sessionStorage.setItem(
      SS_KEY_TENANT_SALES_REPORT_SUMMARY_FILTER,
      JSON.stringify(formValue)
    )
  }

  const convertFilterParam = (data: FilterFormType): FilterParamType => {
    return {
      reportStatus: data.reportStatus?.allChecked
        ? []
        : (data.reportStatus?.checkListValues as ReportStatusEnum[]) || [],
      searchText: data.searchText || '',
      memo: (data.memo?.checkListValues as MemoType[]) || [],
      consistency:
        (data.consistency?.checkListValues as ConsistencyEnum[]) || [],
    }
  }

  const updateCheckboxTitle = (data: FilterParamType) => {
    if (data.memo.length === 0) {
      setMemoTitle('全て')
    } else if (data.memo.length === 1) {
      setMemoTitle(getMemoTitle(data.memo[0]))
    } else {
      setMemoTitle(`${data.memo.length}つ選択中`)
    }

    if (data.consistency.length === 0) {
      setConsistencyTitle('全て')
    } else if (data.consistency.length === 1) {
      setConsistencyTitle(getConsistencyTitle(data.consistency[0]))
    } else {
      setConsistencyTitle(`${data.consistency.length}つ選択中`)
    }

    if (data.reportStatus.length === 0) {
      setReportStatusTitle('全て')
    } else if (data.reportStatus.length === 1) {
      setReportStatusTitle(getReportStatusTitle(data.reportStatus[0]))
    } else {
      setReportStatusTitle(`${data.reportStatus.length}つ選択中`)
    }
  }

  useMount(() => {
    const filterParam: FilterParamType = convertFilterParam(formInitialParam)
    onChangeFilterParams(filterParam)
    updateCheckboxTitle(filterParam)
  })

  useEffect(() => {
    const subscription = formMethods.watch((value) => {
      setFilterParamToSessionStorage(value as FilterFormType)
      const filterParam: FilterParamType = convertFilterParam(
        value as FilterFormType
      )
      onChangeFilterParams(filterParam)
      updateCheckboxTitle(filterParam)
    })
    return () => subscription.unsubscribe()
  })

  const handleClickClear = () => {
    formMethods.setValue('searchText', '')
  }

  const showConsistencyFilterByRole = useCallback((): boolean => {
    return isOneOfRoles(
      AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
      role
    )
  }, [role])

  return (
    <FormProvider {...formMethods}>
      <form>
        <Box className={className}>
          <Box display="flex" alignItems="center">
            <Box>
              <SearchTextField
                className={style.field}
                name="searchText"
                type="text"
                placeholder="コード or テナント名"
                onClickClear={handleClickClear}
              />
            </Box>
            <Box className={style.checkContainer}>
              <Typography
                className={style.label}
                variant="subtitle1"
                component="span"
              >
                ステータス
              </Typography>
              <PopupChild
                title={reportStatusTitle}
                placement="bottom-start"
                className={clsx(
                  style.popupChild,
                  style.checkPosition,
                  style.smallPopupChild,
                  reportStatusList && reportStatusList.length > 0 && style.blue
                )}
              >
                <CheckBoxListWithFilter
                  defaultValues={{ allChecked: true, checkListValues: [] }}
                  checkList={statusCheckOptions}
                  formName="reportStatus"
                  disableSearch
                />
              </PopupChild>
            </Box>
            <Box className={style.checkContainer}>
              <Typography
                className={style.label}
                variant="subtitle1"
                component="span"
              >
                メモ
              </Typography>
              <PopupChild
                title={memoTitle}
                placement="bottom-start"
                className={clsx(
                  style.popupChild,
                  style.checkPosition,
                  style.smallPopupChild,
                  memoList && memoList.length > 0 && style.blue
                )}
              >
                <CheckBoxListWithFilter
                  defaultValues={{ allChecked: true, checkListValues: [] }}
                  checkList={memoCheckOptions}
                  formName="memo"
                  disableSearch
                />
              </PopupChild>
            </Box>
            {showConsistencyFilterByRole() && (
              <Box className={style.checkContainer}>
                <Typography
                  className={style.label}
                  variant="subtitle1"
                  component="span"
                >
                  完全一致フラグ
                </Typography>
                <PopupChild
                  title={consistencyTitle}
                  placement="bottom-start"
                  className={clsx(
                    style.popupChild,
                    style.checkPosition,
                    style.biggerPopupChild,
                    consistencyList && consistencyList.length > 0 && style.blue
                  )}
                >
                  <CheckBoxListWithFilter
                    defaultValues={{ allChecked: true, checkListValues: [] }}
                    checkList={consistencyCheckOptions}
                    formName="consistency"
                    disableSearch
                  />
                </PopupChild>
              </Box>
            )}
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}
