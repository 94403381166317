import {
  FormNameEnum,
  FormTypesTenantSalesReport,
  Register,
  RegisterWithSum,
} from '../../type'
import { limitValue } from '../../constants'

export const calculateSumCell = (
  register: Register[],
  formValues: FormTypesTenantSalesReport
): RegisterWithSum[] => {
  const registerWithSums: RegisterWithSum[] = [...register]
  // NOTE: レジ台数が1台の場合は計算不要
  if (registerWithSums.length === 1) return registerWithSums

  const formRegistersValue = formValues[FormNameEnum.registers]
  if (!formRegistersValue) return registerWithSums

  // NOTE: Formの値を元にサーバーからきた値も更新して、Componentに渡す
  formRegistersValue.forEach((formRegisterValue, index) => {
    formRegisterValue.rows.forEach((row, rowIndex) => {
      if (
        !registerWithSums[index] ||
        !registerWithSums[index].rows ||
        !registerWithSums[index].rows[rowIndex]
      )
        return
      // NOTE: 一番上の行の場合は、合算値をリセット
      const currentSum =
        index === 0 ? 0 : registerWithSums[index].rows[rowIndex].sumCell || 0
      // NOTE: InputCellが空の場合は''が渡ってきてしまうので、0にする
      // フォームを編集したあとは、formValuesがStringになるので、Number型に変換してから足し算をする
      const rowValue = row.value === '' ? 0 : row.value
      const newSum = currentSum + Number(rowValue)
      registerWithSums.forEach((_, registerValueIndex) => {
        registerWithSums[registerValueIndex].rows[rowIndex] = {
          ...registerWithSums[registerValueIndex].rows[rowIndex],
          sumCell: newSum,
        }
      })
    })
  })
  return registerWithSums
}

export const hasExceededSumCell = (registers: RegisterWithSum[]): boolean => {
  return registers.some((register) =>
    register.rows.some(
      (row) => row.sumCell !== undefined && row.sumCell > limitValue
    )
  )
}
