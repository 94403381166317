import { RoleEnum } from '../../slices/services/api'
import { Option } from '../../domain/tenant'

type RoleTitleMap = {
  [key in RoleEnum]: string
}

export const roleTitleMap: Partial<RoleTitleMap> = {
  [RoleEnum.Admin]: '全体管理者',
  [RoleEnum.AdminStoreManager]: '施設管理者',
  [RoleEnum.AdminSalesReportManager]: '売上担当者・責任者',
  [RoleEnum.EditorSalesReportManager]: '売上担当者・作業者',
  [RoleEnum.EditorStoreManagerInOperationCenter]: '施設担当者･OPC',
  [RoleEnum.EditorStoreManager]: '施設担当者',
  [RoleEnum.Viewer]: '閲覧者',
}

export const getRoleTitle = (role?: RoleEnum): string => {
  return role ? roleTitleMap[role] ?? '' : ''
}

export const roleOptionsForAdminStoreManager: Option[] = [
  {
    value: String(RoleEnum.EditorStoreManager),
    title: getRoleTitle(RoleEnum.EditorStoreManager),
  },
]

export const roleOptions: Option[] = [
  {
    value: String(RoleEnum.Admin),
    title: getRoleTitle(RoleEnum.Admin),
  },
  {
    value: String(RoleEnum.AdminStoreManager),
    title: getRoleTitle(RoleEnum.AdminStoreManager),
  },
  {
    value: String(RoleEnum.AdminSalesReportManager),
    title: getRoleTitle(RoleEnum.AdminSalesReportManager),
  },
  {
    value: String(RoleEnum.EditorSalesReportManager),
    title: getRoleTitle(RoleEnum.EditorSalesReportManager),
  },
  {
    value: String(RoleEnum.EditorStoreManager),
    title: getRoleTitle(RoleEnum.EditorStoreManager),
  },
  { value: String(RoleEnum.Viewer), title: getRoleTitle(RoleEnum.Viewer) },
]

export const editorSalesReportManagerIndex = 3

export const activeOptions: Option[] = [
  {
    value: 'true',
    title: '利用中',
    color: 'green',
  },
  {
    value: 'false',
    title: '利用停止',
    color: 'gray',
  },
]

export const roleOptionsWithAll = [{ value: '', title: '全て' }, ...roleOptions]
