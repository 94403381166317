import React from 'react'
import { Box, Link as MuiLink, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import ReportMessages, {
  ReportMessage,
} from 'src/components/molecules/ReportMessages'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import Button from 'src/components/atoms/Button'
import { ENABLE_SEND_NOTIFICATION_ORG_CODES } from 'src/domain/org'
import { RoleEnum } from 'src/slices/services/api'
import { Notification } from 'src/slices/services/api/api'
import { formatDateTime } from 'src/utils/date'
import styles from './styles.module.scss'
import Icon from '../../../../components/atoms/Icon'
import { FormNameEnum } from '../../type'

export type TenantSalesReportMessagesAndNotificationProps = {
  reportMessages: ReportMessage[]
  tenantMessageBody?: string
  manageMessage?: string
  notifications?: Notification[]
  tenantPath: string
  orgCode: string
  role?: RoleEnum
  isEnableToFinalizeAndFilterByMisMatchAndSendMessageAndSendNotificationByRoles: boolean
  onClickNotificationButton: () => void
} & React.StyledProps

const TenantSalesReportMessagesAndNotification: React.StyledFC<
  TenantSalesReportMessagesAndNotificationProps
> = ({
  className,
  reportMessages,
  tenantMessageBody,
  manageMessage,
  notifications,
  tenantPath,
  orgCode,
  isEnableToFinalizeAndFilterByMisMatchAndSendMessageAndSendNotificationByRoles,
  onClickNotificationButton,
}: TenantSalesReportMessagesAndNotificationProps) => {
  const formMethods = useFormContext()

  return (
    <Box className={clsx(className, styles.root)}>
      {reportMessages.length > 0 && (
        <Box className={styles.reportMessageWrapper}>
          <Box className={styles.title}>
            <Icon
              className={clsx(styles.icon, styles.colorBlack)}
              icon="memo"
            />
            <Typography className={styles.text} variant="h4">
              テナントからの補足事項
            </Typography>
          </Box>
          <ReportMessages reportMessages={reportMessages} />
        </Box>
      )}
      <Box>
        <Box className={styles.eachCategory}>
          <Box className={styles.title}>
            <Icon
              className={clsx(styles.icon, styles.colorBlack)}
              icon="note2"
            />
            <Typography className={styles.text} variant="h4">
              管理者メモ(テナントごと)
            </Typography>
            <MuiLink
              className={styles.pathToDetail}
              component={Link}
              to={tenantPath}
              target="_blank"
            >
              修正する
            </MuiLink>
          </Box>
          <Box className={styles.fixedMemo}>
            <Typography variant="body1" className={styles.messageBody}>
              {tenantMessageBody}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box className={styles.eachCategory}>
            <Box className={styles.title}>
              <Icon
                className={clsx(styles.icon, styles.colorBlack)}
                icon="eye"
              />
              <Typography className={styles.text} variant="h4">
                管理者メモ(報告ごと)
              </Typography>
            </Box>
            <Box className={styles.textareaContainer}>
              <textarea
                {...formMethods.register(FormNameEnum.manageMessage)}
                className={styles.textarea}
                defaultValue={manageMessage}
                placeholder="補足事項を記入する"
                name={FormNameEnum.manageMessage}
                disabled={
                  !isEnableToFinalizeAndFilterByMisMatchAndSendMessageAndSendNotificationByRoles
                }
              />
            </Box>
          </Box>
        </Box>
        {ENABLE_SEND_NOTIFICATION_ORG_CODES.includes(orgCode) &&
          isEnableToFinalizeAndFilterByMisMatchAndSendMessageAndSendNotificationByRoles && (
            <Box className={styles.notificationsContainer}>
              {notifications && notifications.length > 0 ? (
                <Box className={styles.eachCategory}>
                  <Box className={styles.title}>
                    <Icon
                      className={clsx(styles.icon, styles.colorBlack)}
                      icon="exclamation"
                    />
                    <Typography className={styles.text} variant="h4">
                      メッセージ
                      <span className={styles.notificationCount}>
                        {notifications.length}件
                      </span>
                    </Typography>
                    <button
                      type="button"
                      className={styles.addNotificationButton}
                      onClick={onClickNotificationButton}
                    >
                      追加する
                    </button>
                  </Box>
                  {notifications.map((notification) => (
                    <Box key={notification.createdAt}>
                      <Box className={styles.sentDate}>
                        <Typography className={styles.fontInfo}>
                          送信日時
                        </Typography>
                        <Typography
                          className={clsx(styles.date, styles.fontInfo)}
                        >
                          {formatDateTime(notification.createdAt)}
                        </Typography>
                      </Box>
                      <Typography
                        className={clsx(styles.messageBody, styles.fontInfo)}
                      >
                        {notification.content}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box className={styles.buttonWrapper}>
                  <Button
                    className={styles.notificationButton}
                    color="secondary"
                    size="large"
                    title="メッセージを登録する"
                    onClick={onClickNotificationButton}
                  />
                </Box>
              )}
            </Box>
          )}
      </Box>
    </Box>
  )
}

export default TenantSalesReportMessagesAndNotification
