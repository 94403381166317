import React, { memo } from 'react'
import clsx from 'clsx'
import { Box } from '@mui/material'
import {
  Unstable_Popup as BasePopup,
  PopupPlacement,
} from '@mui/base/Unstable_Popup'
import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import style from './styles.module.scss'

export type PopupChildProps = {
  title: string
  width?: number
  placement?: PopupPlacement
} & React.StyledProps &
  React.ChildrenProps

const PopupChild: React.StyledFC<PopupChildProps> = memo(
  ({ className, title, children, width, placement }: PopupChildProps) => {
    const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchor(anchor ? null : event.currentTarget)
    }
    const open = Boolean(anchor)
    const id = open ? 'simple-popper' : undefined

    return (
      <ClickAwayListener onClickAway={() => setAnchor(null)}>
        <div style={{ width }}>
          <Box
            aria-describedby={id}
            className={style.container}
            onClick={handleClick}
          >
            <Box className={clsx(style.box, className)}>{title}</Box>
          </Box>
          <BasePopup
            id={id}
            open={open}
            anchor={anchor}
            placement={placement}
            className={style.basePopup}
          >
            {children}
          </BasePopup>
        </div>
      </ClickAwayListener>
    )
  }
)

PopupChild.displayName = 'PopupChild'
export default PopupChild
