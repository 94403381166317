import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import CheckBox from '../../atoms/CheckBox'
import SearchTextField from '../SearchTextField'
import SelectWithOption, { SelectWithOptionProps } from '../SelectWithOption'

export type Filter = Omit<SelectWithOptionProps, 'variant'>

export type TenantFilterProps = {
  filters: Filter[]
  tenantCount?: number
  defaultSearchValue?: string
  isChecked?: boolean
  searchFieldOnChange: (value: string) => void
  onChangeCheck?: () => void
} & React.StyledProps

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  count: {
    alignItems: 'center',
  },
  filterWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
  },
  filter: {
    marginRight: 8,
    '&:last-child': {
      marginRight: 'unset',
    },
  },
  searchField: {
    alignSelf: 'center',
  },
  checkAria: {
    alignItems: 'center',
  },
  checkText: {
    cursor: 'pointer',
    fontSize: 13,
    fontWeight: 500,
    userSelect: 'none',
  },
})

const TenantFilter: React.StyledFC<TenantFilterProps> = ({
  className,
  filters,
  tenantCount,
  defaultSearchValue,
  isChecked,
  searchFieldOnChange,
  onChangeCheck,
}: TenantFilterProps) => {
  const classes = useStyles()

  const { setValue } = useFormContext()
  const searchFieldName = 'searchTenant'

  const handleClickClear = () => {
    setValue(searchFieldName, '')
    searchFieldOnChange('')
  }

  useEffect(() => {
    if (!defaultSearchValue) return

    setValue(searchFieldName, defaultSearchValue)
  }, [defaultSearchValue, setValue])

  return (
    <Box className={clsx(className, classes.header)}>
      {tenantCount !== undefined ? (
        <Box className={classes.count}>
          <Typography variant="h2" component="p" display="inline">
            {`${tenantCount} `}
          </Typography>
          <Typography variant="subtitle1" component="p" display="inline">
            テナント
          </Typography>
        </Box>
      ) : (
        <Box />
      )}
      <Box display="flex">
        <Box className={classes.filterWrapper}>
          {filters.map((filter) => (
            <SelectWithOption
              className={classes.filter}
              key={filter.name}
              name={filter.name}
              options={filter.options}
              placeholder={filter.placeholder}
              variant="standard"
            />
          ))}
        </Box>
        <SearchTextField
          name={searchFieldName}
          className={classes.searchField}
          type="text"
          placeholder="コード or テナント名"
          onClickClear={handleClickClear}
          onChange={(text: string) => searchFieldOnChange(text)}
        />
        {onChangeCheck && (
          <Box
            display="flex"
            className={classes.checkAria}
            onClick={onChangeCheck}
          >
            <CheckBox isChecked={isChecked} shape="rectangle" disableRipple />
            <Typography component="span" className={classes.checkText}>
              練習モード実施なしで絞る
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default TenantFilter
