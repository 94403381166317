import React from 'react'
import { Box, Table, TableBody, TableHead } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import color from '../../../styles/color'
import { TABLE_DIVIDER_THICKNESS } from '../../../styles/constants'
import { injectIndex } from '../../../utils/array'
import { isOdd } from '../../../utils/number'
import { CellSize } from '../../atoms/CalculationTableBodyCell'
import CalculationTableBodyRow, {
  Cell as LeftTableCell,
} from '../../molecules/CalculationTableBodyRow'
import CalculationTableHeaderRow, {
  Cell as HeaderCel,
} from '../../molecules/CalculationTableHeaderRow'
import EditCalculationDSLBodyRow, {
  Cell as RightTableCell,
} from '../../molecules/EditCalculationDSLBodyRow'
import { Option } from '../../molecules/SelectWithGroupOption'

const LEFT_TABLE_COLUMNS = 3

export type BodyRow = {
  number: string
  title: string
  cells: RightTableCell[]
  disabledRow?: boolean
  disabledAddButton?: boolean
}

export type EditCalculationDSLTableProps = {
  columns: HeaderCel[]
  rows: BodyRow[]
  onClickAdd: (rowIndex: number) => void
  onOpenSelect: (rowIndex: number, cellIndex: number) => void
  onChangeSelect: (option: Option, rowIndex: number, cellIndex: number) => void
  valueToRenderEmpty?: string
} & React.StyledProps

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
  },
  leftTableWrapper: {
    position: 'sticky',
    zIndex: 1,
    left: 0,
  },
  leftTable: {
    borderRight: `${TABLE_DIVIDER_THICKNESS}px solid ${color.lightGray02}`,
  },
  tableBodyRow: {
    height: 56,
  },
  rightTableWrapper: {
    zIndex: 0,
    width: '100%',
  },
  rightTableHeader: {
    padding: '0 10px',
  },
})

const EditCalculationDSLTable: React.StyledFC<EditCalculationDSLTableProps> = ({
  className,
  columns,
  rows,
  onClickAdd,
  onOpenSelect,
  onChangeSelect,
  valueToRenderEmpty,
}: EditCalculationDSLTableProps) => {
  const classes = useStyles()

  const convertedLeftTableCell = (row: BodyRow): LeftTableCell[] => {
    const cells = [
      {
        text: row.number,
        size: 'medium' as CellSize,
      },
      {
        text: row.title,
        size: 'large' as CellSize,
      },
      {
        text: '=',
        size: 'small' as CellSize,
      },
    ]
    return injectIndex(cells)
  }

  const rightColumnCount = columns.length - LEFT_TABLE_COLUMNS

  return (
    <Box className={clsx(className, classes.root)}>
      <Box className={classes.leftTableWrapper}>
        <Table stickyHeader className={classes.leftTable}>
          <TableHead>
            <CalculationTableHeaderRow
              cells={columns.slice(0, LEFT_TABLE_COLUMNS)}
            />
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <CalculationTableBodyRow
                className={classes.tableBodyRow}
                key={row.title}
                cells={convertedLeftTableCell(row)}
                isGray={isOdd(index)}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box className={classes.rightTableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <CalculationTableHeaderRow
              cells={columns.slice(LEFT_TABLE_COLUMNS)}
            />
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <EditCalculationDSLBodyRow
                key={row.title}
                className={classes.tableBodyRow}
                cells={row.cells}
                emptyCellCount={rightColumnCount - row.cells.length}
                isGray={isOdd(rowIndex)}
                onOpenSelect={(cellIndex) => onOpenSelect(rowIndex, cellIndex)}
                onChangeSelect={(option, cellIndex) =>
                  onChangeSelect(option, rowIndex, cellIndex)
                }
                onClickAdd={() => onClickAdd(rowIndex)}
                disabledRow={row.disabledRow}
                disabledAddButton={row.disabledAddButton}
                valueToRenderEmpty={valueToRenderEmpty}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

export default EditCalculationDSLTable
