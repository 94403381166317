import { Sections } from '../../components/organisms/Sidebar'
import { Item } from '../../components/molecules/TableOfContents'
import Path from '../../routes/path'
import { RoleEnum } from '../../slices/services/api'
import { AdminAdminStoreManagerRoles, isOneOfRoles } from '../../domain/role'

interface SidebarPresenter {
  sections(orgCode: string, role: RoleEnum): Sections
}

const Presenter: SidebarPresenter = {
  sections(orgCode, role) {
    const sectionObj: Sections = {
      business: [
        {
          items: [
            {
              title: '日次管理',
              to: Path.storeSalesReportSummaries(orgCode),
              icon: 'calendar',
            },
            {
              title: '店舗一覧',
              to: Path.stores(orgCode),
              icon: 'company',
            },
            {
              title: 'テナント一覧',
              to: Path.tenants(orgCode),
              icon: 'shop',
            },
          ],
        },
      ],
      account: [
        {
          items: [
            {
              title: 'プロフィール',
              to: Path.profile(orgCode),
              icon: 'profile',
            },
            {
              title: 'リリースノート',
              to: 'https://wed-public.notion.site/Zero-db2366b8414044cd9e987596a9116a0d',
              icon: 'note',
            },
          ],
        },
      ],
    }

    if (isOneOfRoles(AdminAdminStoreManagerRoles, role)) {
      const accountItem: Item = {
        title: 'ユーザー管理',
        to: Path.members(orgCode),
        icon: 'member',
      }
      sectionObj.account[0].items.unshift(accountItem)
    }
    return sectionObj
  },
}

export default Presenter
