/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducer from '../slices/auth/authSlice'
import calculationDSLsReducer from '../slices/calculationDSLs/calculationDSLsSlice'
import calculationGroupsReducer from '../slices/calculationGroups/calculationGroupsSlice'
import calculationItemsReducer from '../slices/calculationItems/calculationItemsSlice'
import editTenantsReducer from '../slices/editTenants/editTenantsSlice'
import confirmSalesReportReducer from '../slices/confirmSalesReport/confirmSalesReportSlice'
import definiteValuesReducer from '../slices/definiteValues/definiteValuesSlice'
import editCalculationsReducer from '../slices/editCalculations/editCalculationsSlice'
import messageReducer from '../slices/message/messageSlice'
import navigationReducer from '../slices/navigation/navigationSlice'
import ocrFormatsReducer from '../slices/ocrFormats/ocrFormatsSlice'
import queryReducer from '../slices/query/querySlice'
import salesReportsReducer from '../slices/salesReports/salesReportsSlice'
import snackbarReducer from '../slices/snackbar/snackbarSlice'
import storeSalesReportSummariesReducer from '../slices/storeSalesReportSummaries/storeSalesReportSummariesSlice'
import storesReducer from '../slices/stores/storesSlice'
import tenantSalesReportSummariesReducer from '../slices/tenantSalesReportSummaries/tenantSalesReportSummariesSlice'
import tenantSalesReportSliceReducer from '../slices/tenantSalesReports/tenantSalesReportsSlice'
import tenantsReducer from '../slices/tenants/tenantsSlice'
import profileReducer from '../slices/profile/profileSlice'

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: [],
}

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['status', 'error'],
}

const queryPersistConfig = {
  key: 'query',
  storage,
  blacklist: [],
}

const navigationPersistConfig = {
  key: 'navigation',
  storage,
  whitelist: ['currentRoot'],
}

const editCalculationsPersistConfig = {
  key: 'editCalculations',
  storage,
  blacklist: [],
}

const confirmSalesReportConfig = {
  key: 'confirmSalesReport',
  storage,
  blacklist: [],
}

const definiteValuesConfig = {
  key: 'definiteValues',
  storage,
  blacklist: [],
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  navigation: persistReducer(navigationPersistConfig, navigationReducer),
  query: persistReducer(queryPersistConfig, queryReducer),
  message: messageReducer,
  snackbar: snackbarReducer,
  forms: combineReducers({
    editTenants: editTenantsReducer,
    editCalculations: persistReducer(
      editCalculationsPersistConfig,
      editCalculationsReducer
    ),
    confirmSalesReport: persistReducer(
      confirmSalesReportConfig,
      confirmSalesReportReducer
    ),
    definiteValues: persistReducer(definiteValuesConfig, definiteValuesReducer),
  }),
  entities: combineReducers({
    stores: storesReducer,
    tenants: tenantsReducer,
    calculationDSLs: calculationDSLsReducer,
    calculationItems: calculationItemsReducer,
    calculationGroups: calculationGroupsReducer,
    ocrFormats: ocrFormatsReducer,
    salesReports: salesReportsReducer,
    storeSalesReportSummaries: storeSalesReportSummariesReducer,
    tenantSalesReportSummaries: tenantSalesReportSummariesReducer,
    tenantSalesReports: tenantSalesReportSliceReducer,
    profile: profileReducer,
  }),
})

export default persistReducer(rootPersistConfig, rootReducer)
