import React from 'react'
import { Box, Typography, Link as MuiLink } from '@mui/material'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import Icon from '../../../../components/atoms/Icon'
import { Store, Tenant, TenantDetail } from '../../../../slices/services/api'
import styles from './styles.module.scss'

export type TenantSalesReportInfoProps = {
  store?: Store
  tenant?: Tenant
  tenantDetail?: TenantDetail
  tenantPath: string
} & React.StyledProps

const TenantSalesReportInfo: React.StyledFC<TenantSalesReportInfoProps> = ({
  className,
  store,
  tenant,
  tenantDetail,
  tenantPath,
}: TenantSalesReportInfoProps) => {
  return (
    <Box className={clsx(className, styles.root)}>
      <Box className={styles.rightAbove}>
        <MuiLink
          className={styles.pathToDetail}
          component={Link}
          to={tenantPath}
          target="_blank"
        >
          テナント詳細ページへ
          <Icon className={styles.icon} icon="externalLink" />
        </MuiLink>
      </Box>
      <Typography variant="body1" className={styles.title}>
        テナント情報
      </Typography>

      <Typography className={styles.eachInfo} variant="body2">
        <span className={styles.content}>
          <span className={styles.code}>{store?.code}</span>
          <span>{store?.name}</span>
        </span>
      </Typography>
      <Typography className={styles.eachInfo} variant="body2">
        <span className={styles.content}>
          <span className={styles.code}>{tenant?.code}</span>
          <span>{tenant?.name}</span>
        </span>
      </Typography>
      <Typography className={styles.eachInfo} variant="body2">
        <span className={styles.content}>
          {tenantDetail?.staffTel || ' - '}
        </span>
      </Typography>
    </Box>
  )
}

export default TenantSalesReportInfo
