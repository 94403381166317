import { useContext } from 'react'
import AuthenticatedApi from '../slices/services/authenticatedApi' // TODO: api呼び出しをhooksから利用するとすると、feturesを呼ぶのが遠いので構成変更検討
import {
  Member,
  MemberPatchRequest,
  MemberPostRequest,
} from '../slices/services/api'
import { ApiResponse } from '../domain/api'
import { axiosErrorMessage } from '../slices/services/error'
import { AuthContext } from '../context/auth.context'

export const useApiMember = () => {
  const authContext = useContext(AuthContext)

  const getMembers = async (
    orgCode: string
  ): Promise<ApiResponse<Member[]>> => {
    try {
      const token = authContext.getAuthToken()
      if (!token) {
        return {
          success: false,
          message: 'ログインしなおしてください: token is not found',
        }
      }
      const res = await new AuthenticatedApi(
        token
      ).getOrganizationsOrganizationCodeMembers(orgCode)
      return { success: true, data: res.data }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: unknown) {
      const message = axiosErrorMessage(e)
      return { success: false, message }
    }
  }

  const postMembers = async (
    orgCode: string,
    members: MemberPostRequest[]
  ): Promise<ApiResponse<Member[]>> => {
    try {
      const token = authContext.getAuthToken()
      if (!token) {
        return {
          success: false,
          message: 'ログインしなおしてください: token is not found',
        }
      }
      await new AuthenticatedApi(
        token
      ).postOrganizationsOrganizationCodeMembers(orgCode, members)
      return { success: true }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: unknown) {
      const message = axiosErrorMessage(e)
      return { success: false, message }
    }
  }

  const patchMembers = async (
    orgCode: string,
    members: Member[]
  ): Promise<ApiResponse<void>> => {
    const patchMembersData: MemberPatchRequest[] = members.map((member) => {
      return {
        id: member.id,
        name: member.name,
        email: member.email,
        role: member.role,
        assignedStoreIds: member.assignedStores.map((store) => store.id),
        hasAllStoresPermission: member.hasAllStoresPermission,
        active: member.active,
      }
    })
    try {
      const token = authContext.getAuthToken()
      if (!token) {
        return {
          success: false,
          message: 'ログインしなおしてください: token is not found',
        }
      }
      await new AuthenticatedApi(
        token
      ).patchOrganizationsOrganizationCodeMembers(orgCode, patchMembersData)
      return { success: true }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: unknown) {
      const message = axiosErrorMessage(e)
      return { success: false, message }
    }
  }

  return { getMembers, postMembers, patchMembers }
}
