import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Organization } from '../../components/molecules/AccountInformation'
import { Option } from '../../components/molecules/SelectWithOption'
import SidebarTemplate, { Sections } from '../../components/organisms/Sidebar'
import { postAuth, setCurrentAuthorization } from '../../slices/auth/authSlice'
import { AuthContext } from '../../context/auth.context'
import Path from '../../routes/path'
import { useAppDispatch, useAppSelector } from '../../store'
import Presenter from './presenter'

const DEFAULT_SECTIONS: Sections = {
  business: [],
  account: [],
}

const Sidebar = (): ReactElement => {
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)
  const { status, authorizations, currentAuthorization } = useAppSelector(
    (state) => state.auth
  )
  const { currentRoot } = useAppSelector((state) => state.navigation)
  const dispatch = useAppDispatch()
  const [selectedNavigationPath, setSelectedNavigationPath] = useState<
    string | undefined
  >()
  const [sections, setSections] = useState<Sections>(DEFAULT_SECTIONS)
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const [isModalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (status === 'idle') {
      dispatch(postAuth())
    }
  }, [status, dispatch])

  useEffect(() => {
    if (currentRoot) {
      setSelectedNavigationPath(currentRoot)
    }
  }, [currentRoot])

  useEffect(() => {
    if (!authorizations) return

    const authOrganizations = authorizations.map((authorization) => {
      return {
        id: authorization.organization.id,
        name: authorization.organization.name,
      }
    })
    setOrganizations(authOrganizations)
  }, [authorizations])

  useEffect(() => {
    if (currentAuthorization) {
      setSections(
        Presenter.sections(
          currentAuthorization.organization.code,
          currentAuthorization.role
        )
      )
    } else {
      setSections(DEFAULT_SECTIONS)
    }
  }, [currentAuthorization])

  const handleLogoClick = () => {
    if (currentAuthorization) {
      navigate(Path.stores(currentAuthorization.organization.code))
    }
  }

  const handleLogoutClick = () => {
    authContext.signOut()
  }

  const handleChangeOrganization = (option: Option) => {
    const changedAuth = authorizations.find(
      (authorization) => authorization.organization.id === option.value
    )
    if (changedAuth) {
      dispatch(setCurrentAuthorization(changedAuth.organization.id))
      navigate(Path.stores(changedAuth.organization.code))
    }
  }

  return (
    <SidebarTemplate
      organizations={organizations}
      selectedNavigationPath={selectedNavigationPath}
      sections={sections}
      currentOrganizationName={currentAuthorization?.organization.name || ''}
      memberName={currentAuthorization?.memberName || ''}
      isModalOpen={isModalOpen}
      onClickLogo={handleLogoClick}
      onClickLogout={handleLogoutClick}
      onChangeOrganization={handleChangeOrganization}
      onModalOpen={() => setModalOpen(true)}
      onModalClose={() => setModalOpen(false)}
    />
  )
}

export default Sidebar
