import Token from './token'
import TokenFactory from './token_factory'
import TokenType, { allTokenTypes, tokenRegExp } from './token_type'

export default class Lexer {
  index: number

  str: string

  tokenFactory: TokenFactory

  /**
   * Creates an instance of lexer.
   * @param str 字句解析したい文字列
   * @param tokenFactory Tokenを生成する
   */
  constructor(str: string, tokenFactory: TokenFactory) {
    this.index = 0
    this.str = str
    this.tokenFactory = tokenFactory
  }

  /**
   * 読み終わっていない文字列に対して、正規表現をマッチさせる
   * @param regexp
   * @returns マッチオブジェクト
   */
  match(regexp: RegExp): RegExpMatchArray | null {
    const currentStr = this.str.substr(this.index)
    return currentStr.match(regexp)
  }

  /**
   * マッチした文字列を返して、indexをマッチした文字列の次に進める
   * @param regexp 正規表現
   * @returns 正規表現にマッチした文字列
   */
  next(regexp: RegExp): string {
    const match = this.match(regexp)
    if (match === null) {
      throw new Error('No match found')
    }
    const result = match[0]
    this.index += result.length
    return result
  }

  /**
   * 文字列の前から順次読んで、次のトークンを返す
   * @returns 次のトークン
   */
  scan(): Token {
    const tokenType = allTokenTypes.find((type) =>
      this.match(tokenRegExp(type))
    )
    if (tokenType === undefined) {
      return this.tokenFactory.createToken(TokenType.EOF, '')
    }

    const matched = this.next(tokenRegExp(tokenType))
    return this.tokenFactory.createToken(tokenType, matched)
  }

  /**
   * 字句解析をする
   * @returns this.strを解析して得られたトークンの配列
   */
  analyze(): Token[] {
    const tokens: Token[] = []
    let reachEOF = false
    while (!reachEOF) {
      const token = this.scan()
      if (token.tokenType === TokenType.EOF) {
        reachEOF = true
      } else {
        tokens.push(token)
      }
    }
    return tokens
  }
}
