import { Column } from 'src/components/tables'

export const columns: Column[] = [
  {
    title: '算出項目名称',
    width: 184,
  },
  {
    title: '手入力値',
    width: 148,
  },
  {
    title: 'OCR値',
    width: 148,
  },
  {
    title: '確定値',
    width: 148,
  },
]

export const columnsWithSum: Column[] = [
  {
    title: '算出項目名称',
    width: 160,
  },
  {
    title: '手入力値',
    width: 120,
  },
  {
    title: 'OCR値',
    width: 120,
  },
  {
    title: '確定値',
    width: 120,
  },
  {
    title: '合算値',
    width: 120,
  },
]

export const limitValue = 999999999.99
