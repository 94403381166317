import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useMount } from 'react-use'
import { ToastTriggerContext } from 'src/context/toast.context'
import { EventType, useBigQuery } from '../../../../hooks/useBigQuery'
import {
  decrementSelectedTenantIndex,
  DEFAULT_DATE,
  incrementSelectedTenantIndex,
} from '../../../../slices/confirmSalesReport/confirmSalesReportSlice'
import {
  clearPutDefiniteValues,
  PutDefiniteValue,
  putDefiniteValues,
} from '../../../../slices/definiteValues/definiteValuesSlice'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { putMessage } from '../../../../slices/message/messageSlice'
import { ReceiptImage, Store, Tenant } from '../../../../slices/services/api'
import { clearTenantSalesReportSummaries } from '../../../../slices/tenantSalesReportSummaries/tenantSalesReportSummariesSlice'
import {
  clearGetTenantSalesReportByParams,
  getTenantSalesReports,
  postNotificationToTenant,
  selectTenantSalesReportByParams,
  selectTenantSalesReportStateByParams,
} from '../../../../slices/tenantSalesReports/tenantSalesReportsSlice'
import {
  getTenant,
  selectTenantByCode,
  selectTenantStateByCode,
} from '../../../../slices/tenants/tenantsSlice'
import useAppTitle from '../../../../hooks/useAppTitle'
import Path, { TenantPathParams } from '../../../../routes/path'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { formatDateWithDayOfWeek } from '../../../../utils/date'
import Presenter from './presenter'
import { FormNameEnum, FormTypesTenantSalesReport, Register } from '../../type'
import {
  getStore,
  selectStoreByCode,
} from '../../../../slices/stores/storesSlice'

import TenantSalesReportTemplate from '../../templates/TenantSalesReport'
import { columns, columnsWithSum } from '../../constants'
import {
  AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
  isOneOfRoles,
} from '../../../../domain/role'

export const DefaultFieldValues: FormTypesTenantSalesReport = {
  registers: [],
  selectSalesDate: '',
  manageMessage: '',
  filterForMismatch: false,
}

const TenantSalesReport: React.FC = (): ReactElement => {
  useAppTitle('売上報告詳細')
  const navigate = useNavigate()
  const { orgCode, storeCode, tenantCode } =
    useParams<TenantPathParams>() as TenantPathParams
  const { sendReportCheckEvent } = useBigQuery()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const role = useAppSelector((state) => state.auth.currentAuthorization?.role)
  const toastContext = useContext(ToastTriggerContext)

  const { selectedTenantIndex, selectedTenantCodes, date } = useAppSelector(
    (state) => state.forms.confirmSalesReport
  )
  const params = useMemo(() => {
    return {
      orgCode,
      date: date || DEFAULT_DATE, // FIXME: ここでdateが無い場合はhomeにリダイレクトのほうが良さそう。
    }
  }, [date, orgCode])

  // entity
  const tenant: Tenant | undefined = useAppSelector(
    selectTenantByCode({ orgCode, storeCode, tenantCode })
  )
  const store: Store | undefined = useAppSelector(
    selectStoreByCode({ orgCode, storeCode })
  )
  const tenantSalesReport = useAppSelector(
    selectTenantSalesReportByParams({
      orgCode,
      storeCode,
      tenantCode,
      date: params.date,
    })
  )

  const defaultManageMessage = useMemo(() => {
    return tenantSalesReport?.manageMessage ?? ''
  }, [tenantSalesReport])

  // Select Date Form
  const formMethods = useForm<FormTypesTenantSalesReport>({
    defaultValues: {
      ...DefaultFieldValues,
      manageMessage: defaultManageMessage,
    },
  })

  useEffect(() => {
    formMethods.setValue(FormNameEnum.manageMessage, defaultManageMessage || '')
  }, [defaultManageMessage, formMethods, tenantSalesReport])

  const registers: Register[] = useMemo(() => {
    return Presenter.convertToRegisters(tenantSalesReport?.registers ?? [])
  }, [tenantSalesReport?.registers])

  const selectOptions = useMemo(() => {
    return Presenter.genSelectDateOptions(tenantSalesReport)
  }, [tenantSalesReport])

  const reportMessages = useMemo(() => {
    return Presenter.genReportMessages(tenantSalesReport)
  }, [tenantSalesReport])

  const watchSelectDate = formMethods.watch(FormNameEnum.selectSalesDate)
  const [selectedResentImage, setSelectedResentImage] = useState<
    ReceiptImage | undefined
  >()
  const deselectImage = () => {
    setSelectedResentImage(undefined)
  }
  useEffect(() => {
    // NOTE: watchSelectDate の初期値がundefinedの場合があるので、options から取得するように
    const selectedDate = watchSelectDate || (selectOptions[0]?.value as string)
    if (!selectedDate) return

    const newImage = Presenter.findSelectedResentImage(
      selectedDate,
      tenantSalesReport
    )

    setSelectedResentImage(newImage)
  }, [tenantSalesReport, formMethods.watch, watchSelectDate, selectOptions])
  const [confirmValidation, setConfirmValidation] = useState(false)
  const [inProgress, setInProgress] = useState(false)

  const updateConfirmValidation = (formValues: FormTypesTenantSalesReport) => {
    const isValid = Presenter.validateInputValues(formValues)
    setConfirmValidation(isValid)
  }

  useEffect(() => {
    const subscription = formMethods.watch((value) => {
      updateConfirmValidation(value as FormTypesTenantSalesReport)
    })
    return () => subscription.unsubscribe()
  }, [formMethods, formMethods.watch])

  // FIXME: reportを一度見て、日時管理を確認し、もう一度Reportを見るというプロセスを取ると、
  // formのsubscriptionが発火しないので、強引に実施する。
  // ただ、そもそもformのvalidationは、useformの機能としてformState.isValidで検出すればいいので
  // updateConfirmValidationじたいが不要な処理なので後々Refactorする。
  useEffect(() => {
    setTimeout(() => {
      const value = formMethods.getValues()
      updateConfirmValidation(value as FormTypesTenantSalesReport)
    }, 0)
  }, [formMethods])

  // utils
  const getDefiniteValues = (): PutDefiniteValue[] => {
    if (!tenantSalesReport) return []

    return Presenter.convertToDefiniteValues(
      tenantSalesReport.registers,
      // FIXME: getValuesに前のテナント情報が含まれているので、修正する
      formMethods.getValues()
    )
  }

  // request state
  const tenantRequestState = useAppSelector(
    selectTenantStateByCode({ orgCode, storeCode, tenantCode })
  )
  const tenantSalesReportState = useAppSelector(
    selectTenantSalesReportStateByParams({
      orgCode,
      storeCode,
      tenantCode,
      date,
    })
  )

  const dispatch = useAppDispatch()
  // call GET API
  useEffect(() => {
    if (tenant === undefined && tenantRequestState.status === 'idle') {
      dispatch(getTenant({ orgCode, storeCode, tenantCode }))
    }
  }, [
    dispatch,
    orgCode,
    storeCode,
    tenant,
    tenantCode,
    tenantRequestState.status,
  ])
  useEffect(() => {
    if (store === undefined && tenantRequestState.status === 'idle') {
      dispatch(getStore({ orgCode, storeCode }))
    }
  }, [dispatch, orgCode, store, storeCode, tenantRequestState.status])
  useEffect(() => {
    if (tenantSalesReportState.status === 'idle') {
      dispatch(
        getTenantSalesReports({
          orgCode,
          storeCode,
          tenantCode,
          date: params.date,
        })
      )
    }
  }, [
    dispatch,
    orgCode,
    storeCode,
    tenantCode,
    params.date,
    tenantSalesReportState.status,
  ])

  useEffect(() => {
    if (
      tenantRequestState.status === 'loading' ||
      tenantSalesReportState.status === 'loading'
    ) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [tenantRequestState.status, tenantSalesReportState.status])

  const [showModal, setShowModal] = useState(false)

  useMount(() => {
    if (selectedTenantIndex === 0) {
      sendReportCheckEvent('start')
    }
  })

  // Post Request State
  const goToSummariesPage = useCallback(() => {
    sendReportCheckEvent('finish')
    dispatch(clearTenantSalesReportSummaries())
    navigate(Path.tenantSalesReportSummaries(orgCode, storeCode))
    deselectImage()
  }, [sendReportCheckEvent, dispatch, navigate, orgCode, storeCode])

  const goToNextTenant = useCallback(
    (type: EventType) => {
      dispatch(
        clearGetTenantSalesReportByParams({
          orgCode,
          storeCode,
          tenantCode,
          date,
        })
      )
      dispatch(incrementSelectedTenantIndex())
      dispatch(clearPutDefiniteValues())
      deselectImage()
      const filterForMismatch = formMethods.getValues(
        FormNameEnum.filterForMismatch
      )
      formMethods.reset({
        filterForMismatch,
        manageMessage: '',
        selectSalesDate: '',
      })

      if (selectedTenantIndex === selectedTenantCodes.length - 1) {
        goToSummariesPage()
        return
      }
      sendReportCheckEvent(type)

      navigate(
        Path.tenantSalesReport(
          orgCode,
          storeCode,
          selectedTenantCodes[selectedTenantIndex + 1]
        )
      )
    },
    [
      dispatch,
      orgCode,
      storeCode,
      tenantCode,
      date,
      formMethods,
      selectedTenantIndex,
      selectedTenantCodes,
      navigate,
      sendReportCheckEvent,
      goToSummariesPage,
    ]
  )

  const callPutMessage = async () => {
    const message = formMethods.getValues().manageMessage
    const registersArray = tenantSalesReport?.registers ?? []
    const registerIdIndex =
      registersArray.length > 0 ? registersArray.length - 1 : 0
    if (defaultManageMessage === message) return
    await dispatch(
      putMessage({
        tenantId: tenant?.id ?? '',
        salesDateId: tenantSalesReport?.id ?? '',
        // NOTE: 一番最後のレジIDとメモを紐づける
        salesDateRegisterId: registersArray[registerIdIndex]?.id ?? '',
        message,
      })
    ).unwrap()
  }

  // Event Handler
  const handleClickConfirm = async () => {
    const conditions =
      showModal ||
      !tenant ||
      !confirmValidation ||
      !tenantSalesReport ||
      inProgress
    if (conditions) return
    setInProgress(true)
    const putValues = {
      tenantId: tenant.id,
      salesDateId: tenantSalesReport.id,
      forms: getDefiniteValues(),
    }
    try {
      await dispatch(putDefiniteValues(putValues)).unwrap()
      // NOTE: PreCheckに失敗したら、メモの登録はしない
      await callPutMessage()

      goToNextTenant('next')
    } finally {
      setInProgress(false)
    }
  }

  const handleClickBack = () => {
    if (showModal) return

    // NOTE: 一番最初の場合は、一覧に戻る
    if (selectedTenantIndex <= 0) {
      setShowModal(true)
      return
    }

    dispatch(decrementSelectedTenantIndex())
    dispatch(clearPutDefiniteValues())
    deselectImage()
    sendReportCheckEvent('back')

    formMethods.resetField(FormNameEnum.selectSalesDate)

    navigate(
      Path.tenantSalesReport(
        orgCode,
        storeCode,
        selectedTenantCodes[selectedTenantIndex - 1]
      )
    )
  }

  const isEnableToFinalizeAndFilterByMisMatchAndSendMessageAndSendNotificationByRoles =
    isOneOfRoles(
      AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
      role
    )

  const handleClickSkip = async () => {
    if (showModal) return
    try {
      if (
        isEnableToFinalizeAndFilterByMisMatchAndSendMessageAndSendNotificationByRoles
      ) {
        await callPutMessage()
      }
      goToNextTenant('skip')
    } finally {
      setInProgress(false)
    }
  }

  const handleClickClose = () => {
    setShowModal(true)
  }

  const handleClickModalConfirm = () => {
    goToSummariesPage()
  }

  const handleClickModalCancel = () => {
    setShowModal(false)
  }

  const callNotificationToTenantAPI = async (
    notification: string
  ): Promise<void> => {
    try {
      await dispatch(
        postNotificationToTenant({
          orgCode,
          storeCode,
          tenantCode,
          salesDate: params.date,
          salesDateId: tenantSalesReport?.id ?? '',
          content: notification,
        })
      ).unwrap()
    } catch (error) {
      toastContext.sendToast({
        variant: 'error',
        title: 'メッセージの送信に失敗しました',
      })
    }
  }

  const handleClickValue = (
    value: string,
    index: number,
    rowIndex: number,
    childIndex?: number
  ) => {
    if (childIndex === undefined) {
      formMethods.setValue(
        `${FormNameEnum.registers}.${index}.rows.${rowIndex}.value`,
        // @ts-ignore
        value
      )
      // NOTE: 小要素が一つの場合は、小要素の値も更新
      if (registers[index].rows[rowIndex].childRows.length === 1) {
        formMethods.setValue(
          `${
            FormNameEnum.registers
          }.${index}.rows.${rowIndex}.children.${0}.value`,
          // @ts-ignore
          value
        )
      }
    } else {
      formMethods.setValue(
        `${FormNameEnum.registers}.${index}.rows.${rowIndex}.children.${childIndex}.value`,
        // @ts-ignore
        value
      )
    }
  }

  const progressPercent =
    ((selectedTenantIndex + 1) / selectedTenantCodes.length) * 100

  return (
    <FormProvider {...formMethods}>
      <form>
        <TenantSalesReportTemplate
          isLoading={isLoading}
          isEnableToFinalizeAndFilterByMisMatchAndSendMessageAndSendNotificationByRoles={
            isEnableToFinalizeAndFilterByMisMatchAndSendMessageAndSendNotificationByRoles
          }
          orgCode={orgCode}
          store={store}
          tenant={tenant}
          tenantPath={Path.tenant(orgCode, storeCode, tenantCode)}
          date={formatDateWithDayOfWeek(params.date)}
          selectedDate={params.date}
          tenantDetail={tenantSalesReport?.tenantDetail}
          columns={registers.length === 1 ? columns : columnsWithSum}
          registers={registers}
          disabledConfirmButton={!confirmValidation || inProgress}
          onClickConfirm={handleClickConfirm}
          onClickBack={handleClickBack}
          onClickClose={handleClickClose}
          onClickSkip={handleClickSkip}
          showModal={showModal}
          reportMessages={reportMessages}
          notifications={tenantSalesReport?.notifications}
          role={role}
          selectOptions={selectOptions}
          selectPlaceholder={selectOptions[0]?.title}
          selectDefaultValue={selectOptions[0]?.value as string | undefined}
          progress={progressPercent}
          totalCount={selectedTenantCodes.length}
          currentCount={selectedTenantIndex + 1}
          selectedResentImage={selectedResentImage}
          selectedTenantCodes={selectedTenantCodes}
          onClickModalConfirm={handleClickModalConfirm}
          onClickModalCancel={handleClickModalCancel}
          onClickNotificationModalRegister={callNotificationToTenantAPI}
          onClickValue={handleClickValue}
        />
      </form>
    </FormProvider>
  )
}

export default TenantSalesReport
