/* eslint-disable import/prefer-default-export */

export function first<T>(array: T[]): T | null {
  if (array.length === 0) {
    return null
  }
  return array[0]
}

export function last<T>(array: T[]): T | null {
  if (array.length === 0) {
    return null
  }
  return array.slice(-1)[0]
}

export function isEqual<T>(array1: T[], array2: T[]): boolean {
  if (array1.length !== array2.length) {
    return false
  }
  return array1.every((item, i) => item === array2[i])
}

export function isEqualArrayElementl<T>(array1: T[], array2: T[]): boolean {
  return (
    array1.every((item) => array2.includes(item)) &&
    array2.every((item) => array1.includes(item))
  )
}

export function uniq<T>(array: T[]): T[] {
  const set = new Set(array)
  return Array.from(set)
}

export function injectIndex<T extends Record<string, unknown>>(
  array: T[]
): (T & { index: number })[] {
  return array.map((item, index) => {
    return {
      ...item,
      index,
    }
  })
}

export function groupBy<T>(
  array: T[],
  keyGetter: (item: T) => string
): Map<string, T[]> {
  const map = new Map()
  array.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}
