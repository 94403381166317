import React from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Pagination, { PaginationProps } from '../../molecules/Pagination'
import TenantList, { TenantListProps } from '../../molecules/TenantList'

export type TenantListWithPaginationProps = {
  currentPage: number
  rowsPerPage: number
  tenantCount?: number
  paginationOnClick: PaginationProps['onClick']
} & React.StyledProps &
  TenantListProps

const useStyles = makeStyles({
  pagination: {
    marginTop: 24,
  },
})

const TenantListWithPagination: React.StyledFC<
  TenantListWithPaginationProps
> = ({
  className,
  currentPage,
  rowsPerPage,
  tenantCount,
  tenants,
  useStoreName,
  paginationOnClick,
}: TenantListWithPaginationProps) => {
  const classes = useStyles()

  return (
    <Box className={className}>
      <TenantList {...{ tenants, useStoreName }} />
      {tenantCount && tenants.length !== 0 && (
        <Pagination
          className={classes.pagination}
          currentPage={currentPage}
          pageCount={Math.ceil(tenantCount / rowsPerPage)}
          onClick={(move) => paginationOnClick(move)}
        />
      )}
    </Box>
  )
}

export default TenantListWithPagination
