import React from 'react'
import { Box } from '@mui/material'

import style from './styles.module.scss'
import Icon from '../../atoms/Icon'

export type UpdateButtonProps = {
  onUpdate: () => void
} & React.StyledProps

const UpdateButton: React.StyledFC<UpdateButtonProps> = ({
  className,
  onUpdate,
}: UpdateButtonProps) => {
  return (
    <Box className={className}>
      <button className={style.button} type="button" onClick={onUpdate}>
        <Icon className={style.icon} icon="reload" />
        <span className={style.update}>更新</span>
      </button>
    </Box>
  )
}

export default UpdateButton
