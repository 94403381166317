import CalculationDSL from './calculation_dsl'
import Lexer from './lexer'
import Token from './token'
import TokenFactory from './token_factory'
import TokenType from './token_type'

type Size = 'small' | 'medium' | 'large'

export type ResultItem = {
  text: string
  size: Size
}

const createBlank = (size: Size): ResultItem => {
  return { text: '', size }
}

export const parseCalculationDSL = (
  dsl: string,
  factory: TokenFactory
): ResultItem[] => {
  const lexer = new Lexer(dsl, factory)
  const tokens = lexer.analyze()
  const result: ResultItem[] = []
  // `(`, `)` をinsertした場合、余計なblankをinsertしないようにフラグが必要
  let insertedParenthesis = false
  tokens.forEach((token) => {
    switch (token.tokenType) {
      case TokenType.LeftParenthesis:
      case TokenType.RightParenthesis:
        result.push({
          text: token.toDisplayString(),
          size: 'small',
        })
        insertedParenthesis = true
        break
      case TokenType.Operation:
      case TokenType.ReadItem:
      case TokenType.CalculationItem:
      case TokenType.Number: {
        if (!insertedParenthesis) {
          result.push(createBlank('small'))
        }
        const size = token.tokenType === TokenType.Operation ? 'small' : 'large'
        result.push({
          text: token.toDisplayString(),
          size,
        })
        insertedParenthesis = false
        break
      }
      default:
        throw new Error('Unhandled token type')
    }
  })
  if (!insertedParenthesis) {
    result.push(createBlank('small'))
  }
  return result
}

export const fillResultItems = (
  items: ResultItem[],
  length: number
): ResultItem[] => {
  const filledItems = items
  // '', '1', ''のような最小単位の長さ
  const baseLength = 3
  if (filledItems.length < baseLength) {
    filledItems.push(createBlank('small'))
    filledItems.push(createBlank('large'))
    filledItems.push(createBlank('small'))
  }

  const fillLength = length - filledItems.length
  if (fillLength % 4 !== 0) {
    throw new Error('Invalid length')
  }
  const iterateCount = fillLength / 4
  for (let i = 0; i < iterateCount; i++) {
    filledItems.push(createBlank('small'))
    filledItems.push(createBlank('small'))
    filledItems.push(createBlank('large'))
    filledItems.push(createBlank('small'))
  }
  return filledItems
}

export type ResultItems = ResultItem[]

const createLeftSideItems = (dsls: CalculationDSL[]): ResultItems[] => {
  return dsls.map((dsl, i) => {
    return [
      { text: (i + 1).toString(), size: 'medium' },
      { text: dsl.calculationItem.name, size: 'large' },
      { text: '=', size: 'small' },
    ]
  })
}

const parseCalculationDSLs = (dsls: CalculationDSL[]): ResultItems[] => {
  const readItems = dsls.flatMap((dsl) => dsl.readItems)
  const calcItems = dsls.map((dsl) => dsl.calculationItem)
  const factory = new TokenFactory(readItems, calcItems)

  const resultItems: ResultItems[] = dsls.map((dsl) => {
    return parseCalculationDSL(dsl.dsl, factory)
  })

  // 一番長いDSLのItem数を求める
  const maxLength = Math.max(...resultItems.map((i) => i.length))
  const rightSideItems: ResultItems[] = resultItems.map((items) =>
    fillResultItems(items, maxLength)
  )
  const leftSideItems = createLeftSideItems(dsls)
  return leftSideItems.map((left, i) => {
    return left.concat(rightSideItems[i])
  })
}

export default parseCalculationDSLs

export const parseCalculationDSLsToTokens = (
  dsls: CalculationDSL[]
): Token[][] => {
  const readItems = dsls.flatMap((dsl) => dsl.readItems)
  const calcItems = dsls.map((dsl) => dsl.calculationItem)
  const factory = new TokenFactory(readItems, calcItems)

  return dsls.map((dsl) => {
    const lexer = new Lexer(dsl.dsl, factory)
    const tokens = lexer.analyze()
    return tokens
  })
}

export interface TextResult {
  text: string[]
}

// FIXME: 関数名は変えた方がいい
export const parseCalculationDSLsToText = (
  dsls: CalculationDSL[]
): TextResult[] => {
  return parseCalculationDSLsToTokens(dsls)
    .map((tokens) => tokens.map((token) => token.toDisplayString()))
    .map((textArr) => {
      if (textArr.length === 1 && textArr[0] === '0') {
        return { text: [] }
      }
      textArr.unshift('=')
      return { text: textArr }
    })
}
