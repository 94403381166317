import React from 'react'
import { Box, Typography } from '@mui/material'
import style from './styles.module.scss'

export type SalesDateCountProps = {
  checkCount: number
  filteredCount: number
  allCount: number
} & React.StyledProps

const SalesDateCount: React.StyledFC<SalesDateCountProps> = ({
  className,
  checkCount,
  filteredCount,
  allCount,
}: SalesDateCountProps) => {
  return (
    <Box className={className}>
      {checkCount === 0 && allCount !== filteredCount && (
        <Typography
          className={style.count}
        >{`${filteredCount}/${allCount}件`}</Typography>
      )}
      {checkCount > 0 && (
        <Typography
          className={style.count}
        >{`${checkCount}件選択中`}</Typography>
      )}
      {checkCount === 0 && allCount === filteredCount && (
        <Typography className={style.count}>{`全体: ${allCount}件`}</Typography>
      )}
    </Box>
  )
}

export default SalesDateCount
