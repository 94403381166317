import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useBeforeUnload from '../../../../hooks/useBeforeUnload'
import TemplatesMembersCreate from '../../templates/Create'
import useAppTitle from '../../../../hooks/useAppTitle'
import {
  MemberPostRequest,
  RoleEnum,
  Store as EntityStore,
} from '../../../../slices/services/api'
import { useApiMember } from '../../../../hooks/useApiMember'
import Path, { OrgPathParams } from '../../../../routes/path'
import { ToastTriggerContext } from '../../../../context/toast.context'
import { useAppDispatch, useAppSelector } from '../../../../store'
import {
  getStores,
  selectStoresByParams,
  selectStoresRequestStateByParams,
} from '../../../../slices/stores/storesSlice'
import Loading from '../../../../components/templates/Loading'
import {
  AdminAdminStoreManagerRoles,
  isOneOfRoles,
} from '../../../../domain/role'

const PagesMembersCreate: React.FC = (): ReactElement => {
  useAppTitle('ユーザー新規追加')
  useBeforeUnload()
  const toastContex = useContext(ToastTriggerContext)
  const { postMembers } = useApiMember()
  const { orgCode } = useParams<OrgPathParams>() as OrgPathParams
  const navigate = useNavigate()

  const param = useMemo(() => {
    return { orgCode, page: 1, perPage: 1000 }
  }, [orgCode])

  const dispatch = useAppDispatch()
  const stores: EntityStore[] =
    useAppSelector((state) => selectStoresByParams(param)(state)) ?? []

  const { status } = useAppSelector((state) =>
    selectStoresRequestStateByParams(param)(state)
  )

  const selfRole: RoleEnum | undefined = useAppSelector(
    (state) => state.auth.currentAuthorization?.role
  )

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getStores(param))
    }
  }, [dispatch, status, param])

  const onClickConfirmClose = () => {
    navigate(Path.members(orgCode))
  }
  const handleCreateMembers = async (members: MemberPostRequest[]) => {
    const result = await postMembers(orgCode, members)
    if (!result.success) {
      toastContex.sendToast({
        variant: 'error',
        title: `ユーザーの作成に失敗しました: ${result.message}`,
      })
      return
    }
    toastContex.sendToast({
      variant: 'success',
      title: `ユーザーの作成に成功しました`,
    })

    navigate(Path.members(orgCode))
  }

  const showSwitchByRole = useCallback((): boolean => {
    return isOneOfRoles(AdminAdminStoreManagerRoles, selfRole)
  }, [selfRole])

  return (
    <div>
      {!showSwitchByRole() && <div>権限がありません</div>}

      {status === 'succeeded' && showSwitchByRole() && selfRole ? (
        <TemplatesMembersCreate
          onClickConfirmClose={onClickConfirmClose}
          onCreateMembers={handleCreateMembers}
          stores={stores}
          selfRole={selfRole}
          orgCode={orgCode}
        />
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default PagesMembersCreate
