import React from 'react'
import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import { Option } from 'src/components/molecules/SelectWithOption'
import Badge from 'src/components/atoms/Badge'
import {
  getReportStatusBadgeColor,
  getReportStatusFillTypeColor,
  getReportStatusTitle,
} from 'src/domain/reportStatus'
import SalesReportDateOptions from 'src/features/salesReport/components/DateOptions'
import { SalesReportType } from 'src/features/salesReport/type'
import styles from './styles.module.scss'

export type SalesReportHeaderProps = {
  salesReport: SalesReportType
  selectOptions: Option[]
  onClickName: () => void
  onSelectDateChanged: (selectedDate: string) => void
} & React.StyledProps

const SalesReportHeader: React.StyledFC<SalesReportHeaderProps> = ({
  className,
  salesReport,
  selectOptions,
  onClickName,
  onSelectDateChanged,
}: SalesReportHeaderProps) => {
  return (
    <Box className={clsx(className, styles.root)}>
      {salesReport.registerIndex === 0 && (
        <SalesReportDateOptions
          selectOptions={selectOptions}
          registerIndex={salesReport.registerIndex}
          onSelectDateChanged={onSelectDateChanged}
        />
      )}
      <Box
        className={clsx(styles.centerLine, {
          [styles.centerLineLeftMargin]: salesReport.registerIndex > 0,
        })}
      >
        <Badge
          className={styles.badge}
          label={getReportStatusTitle(salesReport.reportStatus)}
          color={getReportStatusBadgeColor(salesReport.reportStatus)}
          fillType={getReportStatusFillTypeColor(salesReport.reportStatus)}
          ellipse
        />
        <Typography className={styles.title} variant="subtitle2">
          <Typography
            className={styles.code}
            color="textSecondary"
            display="inline"
            variant="inherit"
          >
            {salesReport.code}
          </Typography>
          <Typography
            className={styles.name}
            display="inline"
            variant="inherit"
            onClick={onClickName}
          >
            {salesReport.name}
          </Typography>
        </Typography>
      </Box>
      <Box className={styles.rightLine}>
        <Typography component="span" className={styles.salesReportDate}>
          <span className={styles.salesReportDateTitle}>売上報告日</span>
          <span className={styles.date}>{salesReport.salesReportDate}</span>
        </Typography>
      </Box>
    </Box>
  )
}

export default SalesReportHeader
