import { CellSize } from '../../components/atoms/CalculationTableHeaderCell'
import { Path } from '../../components/molecules/BreadcrumbsPipe'
import { Cell as Column } from '../../components/molecules/CalculationTableHeaderRow'
import {
  InputCell,
  SelectCell,
  Size,
} from '../../components/molecules/EditFormatRightBodyRow'
import { Option } from '../../components/molecules/SelectWithOption'
import { BodyRow } from '../../components/organisms/EditOCRFormatTable'
import {
  EditOCRFormat,
  EditReadItem,
} from '../../slices/editCalculations/editCalculationsSlice'
import {
  columnNumberToDisplayString,
  lineNumberToDisplayString,
} from '../../slices/ocrFormats/position'
import {
  CalculationDSL,
  CalculationItem,
  OCRFormat,
} from '../../slices/services/api'
import { injectIndex } from '../../utils/array'

type FieldName =
  | 'sectionName'
  | 'isDuplicated'
  | 'prefix'
  | 'suffix'
  | 'readTargetNumberLine'
  | 'readTargetNumberColumn'

export const FieldNameList: FieldName[] = [
  'sectionName',
  'isDuplicated',
  'prefix',
  'suffix',
  'readTargetNumberLine',
  'readTargetNumberColumn',
]

type FormNameWithValue = {
  name:
    | `rows.${number}`
    | `rows.${number}.sectionName`
    | `rows.${number}.isDuplicated`
    | `rows.${number}.prefix`
    | `rows.${number}.suffix`
    | `rows.${number}.readTargetNumberLine`
    | `rows.${number}.readTargetNumberColumn` // NOTE: form の name 属性
  value: string | number
}

export type RowFieldValue = {
  sectionName?: string
  isDuplicated: number
  prefix: string
  suffix: string
  readTargetNumberLine?: number
  readTargetNumberColumn?: number
}

// NOTE: editOCRFormat の state に格納する型
type PayloadValue = Omit<OCRFormat, 'id' | 'readItem'>

interface EditOCRFormatPresenter {
  breadcrumbs(storeName?: string, tenantName?: string): Path[]
  columns(): Column[]
  rows(
    calculationItems: CalculationItem[],
    editReadItems: EditReadItem[],
    stateEditOCRFormats?: EditOCRFormat[],
    restoredEditOCRFormats?: EditOCRFormat[]
  ): BodyRow[]
  convertOCRFormatsToApiVal(values: RowFieldValue[]): PayloadValue[]
  convertDSLToEditOCRFormat(
    dsls: CalculationDSL[],
    editReadItems: EditReadItem[]
  ): EditOCRFormat[]
  getFormNameWithValues(
    rowIndex: number,
    editOCRFormat: EditOCRFormat
  ): FormNameWithValue[]
}

const generateInputCell = (
  name: string,
  defaultValue: string | number = ''
): InputCell => {
  return {
    name,
    placeholder: '該当項目のあるセクション名を入力',
    defaultValue,
  }
}

export const generateSelectCell = (
  name: string,
  options: Option[],
  size: Size,
  defaultValue?: string | number,
  isGroup = false
): SelectCell => {
  return {
    name,
    options,
    placeholder: '',
    defaultValue: defaultValue || options[0].value,
    groupWithNextCell: isGroup,
    size,
  }
}

const formatPrefix: Option[] = [
  { title: '-', value: '' },
  { title: '¥', value: '¥' },
  { title: '(', value: '(' },
  { title: '(¥', value: '(¥' },
]

const formatSuffix: Option[] = [
  { title: '-', value: '' },
  { title: '¥', value: '¥' },
  { title: ')', value: ')' },
  { title: '円', value: '円' },
  { title: '件', value: '件' },
  { title: '人', value: '人' },
  { title: '個', value: '個' },
  { title: '名', value: '名' },
  { title: '回', value: '回' },
  { title: '客', value: '客' },
  { title: '数', value: '数' },
  { title: '点', value: '点' },
  { title: '組', value: '組' },
  { title: '枚', value: '枚' },
]

const formatLineNumber = (length: number): Option[] => {
  return new Array(length).fill(null).map((_, i) => {
    return { title: lineNumberToDisplayString(i), value: i }
  })
}

const formatColumnNumber = (length: number): Option[] => {
  return new Array(length).fill(null).map((_, i) => {
    return { title: columnNumberToDisplayString(i), value: i }
  })
}

const formatDuplicated: Option[] = [
  { title: '無し', value: 0 }, // NOTE: Number(false)
  { title: '有り', value: 1 }, // NOTE: Number(true)
]

const generateSelectCellSet = (
  rowIndex: number,
  defaultEditOCRFormat?: EditOCRFormat
): SelectCell[] => {
  const maxLineDownCount = 6
  const maxColumnsRightCount = 6
  return [
    generateSelectCell(
      `rows.${rowIndex}.prefix`,
      formatPrefix,
      'small',
      defaultEditOCRFormat?.prefix,
      false
    ),
    generateSelectCell(
      `rows.${rowIndex}.suffix`,
      formatSuffix,
      'small',
      defaultEditOCRFormat?.suffix,
      false
    ),
    generateSelectCell(
      `rows.${rowIndex}.readTargetNumberLine`,
      formatLineNumber(maxLineDownCount),
      'large',
      defaultEditOCRFormat?.readTargetNumberLine,
      true
    ),
    generateSelectCell(
      `rows.${rowIndex}.readTargetNumberColumn`,
      formatColumnNumber(maxColumnsRightCount),
      'large',
      defaultEditOCRFormat?.readTargetNumberColumn,
      false
    ),
    generateSelectCell(
      `rows.${rowIndex}.isDuplicated`,
      formatDuplicated,
      'medium',
      Number(defaultEditOCRFormat?.isDuplicated),
      true
    ),
  ]
}

const getDefaultOptionValue = (fieldName: FieldName): Option['value'] => {
  switch (fieldName) {
    case 'sectionName':
      return ''
    case 'isDuplicated':
      return formatDuplicated[0].value
    case 'prefix':
      return formatPrefix[0].value
    case 'suffix':
      return formatSuffix[0].value
    case 'readTargetNumberLine':
      return formatLineNumber(1)[0].value
    case 'readTargetNumberColumn':
      return formatColumnNumber(1)[0].value
    default:
      return ''
  }
}

const Presenter: EditOCRFormatPresenter = {
  breadcrumbs(storeName?: string, tenantName?: string): Path[] {
    const paths = []

    if (storeName && tenantName) {
      paths.push({ title: storeName }, { title: tenantName })
    }

    paths.push({ title: '利用開始設定' }, { title: 'ステップ 2' })

    return injectIndex(paths)
  },
  columns(): Column[] {
    const columns = [
      {
        text: 'No.',
        size: 'medium' as CellSize,
      },
      {
        text: '算出項目名',
        size: 'medium' as CellSize,
      },
      {
        text: '対応項目',
        size: 'large' as CellSize,
      },
      {
        text: '接頭辞',
        size: 'medium' as CellSize,
      },
      {
        text: '接尾辞',
        size: 'medium' as CellSize,
      },
      {
        text: '数値の位置',
        size: 'medium' as CellSize,
        tooltipText:
          '取得すべき数値が読取項目から見てどの位置に記載されているか選択してください。',
      },
      {
        text: '',
        size: 'small' as CellSize,
      },
      {
        text: '同名称項目の有無',
        size: 'large' as CellSize,
        colSpan: 2,
        tooltipText:
          '精算レシート内に読取項目の重複がある場合は、「はい」を選択し、直前に記載されている項目をセクション名に記載してください。',
      },
    ]
    return injectIndex(columns)
  },
  rows(
    calculationItems: CalculationItem[],
    editReadItems: EditReadItem[],
    stateEditOCRFormats = [],
    restoredEditOCRFormats = []
  ): BodyRow[] {
    return editReadItems.flatMap((readItem, index) => {
      const defaultEditOCRFormat =
        stateEditOCRFormats[index] || restoredEditOCRFormats[index]

      const calculationItemName = calculationItems.find(
        (calculationItem) => calculationItem.id === readItem.calculationId
      )?.name

      if (!calculationItemName) {
        return []
      }

      return {
        number: String(index + 1),
        calculationItemName,
        readItemName: readItem.readItem,
        inputCell: generateInputCell(
          `rows.${index}.sectionName`,
          defaultEditOCRFormat?.sectionName
        ),
        showInput: Boolean(defaultEditOCRFormat?.isDuplicated),
        selectCells: generateSelectCellSet(index, defaultEditOCRFormat),
      }
    })
  },
  convertOCRFormatsToApiVal(values) {
    if (!values) {
      return []
    }
    return values.map((value) => {
      return {
        ...value,
        isDuplicated: Boolean(value.isDuplicated),
        readTargetNumberColumn: value.readTargetNumberColumn || 0,
        readTargetNumberLine: value.readTargetNumberLine || 0,
      }
    })
  },
  convertDSLToEditOCRFormat(
    dsls: CalculationDSL[],
    editReadItems: EditReadItem[]
  ): EditOCRFormat[] {
    if (dsls.length === 0) return []

    const flatDSLOCRFormats = dsls.flatMap(
      (dsl) =>
        dsl.ocrFormats?.map((format) => {
          return {
            ...format,
            calculationId: dsl.calculationItem.id,
          }
        })
    )

    return editReadItems.reduce((accumulator: EditOCRFormat[], current) => {
      // NOTE: 編集前と編集時で、読取項目の ID が変わってしまう。
      // その影響で、 section name が重複してしまうため、一度見つかった読取項目を候補のリストから削除する。
      const dslFormatIdx = flatDSLOCRFormats.findIndex(
        (format) =>
          format?.calculationId === current.calculationId &&
          format.readItem === current.readItem
      )
      const dslFormat = flatDSLOCRFormats[dslFormatIdx]
      if (dslFormat) {
        flatDSLOCRFormats.splice(dslFormatIdx, 1)
      }
      accumulator.push({
        id: current.id,
        calculationId: current.calculationId,
        readItem: current.readItem,
        isDuplicated: dslFormat?.isDuplicated || false,
        sectionName: dslFormat?.sectionName,
        suffix: dslFormat?.suffix,
        prefix: dslFormat?.prefix,
        readTargetNumberColumn: dslFormat?.readTargetNumberColumn || 0,
        readTargetNumberLine: dslFormat?.readTargetNumberLine || 0,
      })
      return accumulator
    }, [])
  },
  // TODO: この関数は、convertOCRFormatsFromFormValuesToRequestValuesと対をなす関係にして、
  // Formの形式と、APIの形式を変換する関数にして、利用側で利用方法を工夫する方が良い。
  // データ形式と変換方式を要考察
  getFormNameWithValues(
    rowIndex: number,
    editOCRFormat: EditOCRFormat
  ): FormNameWithValue[] {
    return FieldNameList.map((fieldName) => {
      if (fieldName === 'isDuplicated') {
        return {
          name: `rows.${rowIndex}.${fieldName}`,
          value:
            Number(editOCRFormat[fieldName]) ??
            getDefaultOptionValue(fieldName),
        }
      }
      return {
        name: `rows.${rowIndex}.${fieldName}`,
        value: editOCRFormat[fieldName] ?? getDefaultOptionValue(fieldName),
      }
    })
  },
}

export default Presenter
