/* eslint-disable no-param-reassign */
import { Selector } from 'react-redux'
import {
  createSlice,
  createEntityAdapter,
  EntityState,
  createSelector,
} from '@reduxjs/toolkit'
import { RequestState } from '../../domain/request'
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../store'
import { purge } from '../../store/action'
// eslint-disable-next-line import/no-cycle
import { getCalculationDSLs } from '../calculationDSLs/calculationDSLsSlice'
// eslint-disable-next-line import/no-cycle
import {
  GetParams,
  getCalculationGroup,
  selectByParams as selectCalculationGroupsByParams,
} from '../calculationGroups/calculationGroupsSlice'
import { CalculationItem } from '../services/api'

export interface CalculationItemState
  extends EntityState<CalculationItem>,
    RequestState {}

const calculationItemsAdapter = createEntityAdapter<CalculationItem>()

export const initialState: CalculationItemState =
  calculationItemsAdapter.getInitialState({
    status: 'idle',
  })

const slice = createSlice({
  name: 'calculationItems',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(getCalculationDSLs.pending, (state) => {
      return {
        ...state,
        status: 'loading',
      }
    })
    builder.addCase(getCalculationDSLs.fulfilled, (state, { payload }) => {
      calculationItemsAdapter.upsertMany(
        state,
        payload.dsls?.calculationItems ?? {}
      )
      state.status = 'succeeded'
    })
    builder.addCase(getCalculationDSLs.rejected, (state, { error }) => {
      return {
        ...state,
        status: 'failed',
        error,
      }
    })
    builder.addCase(getCalculationGroup.fulfilled, (state, { payload }) => {
      calculationItemsAdapter.upsertMany(state, payload.calculationItems ?? {})
    })
  },
})

export default slice.reducer

// Selector
export const {
  selectById: selectCalculationItemById,
  selectEntities: selectCalculationItemEntities,
  selectAll: selectAllCalculationItems,
} = calculationItemsAdapter.getSelectors<CalculationItemState>((state) => state)

export const selectCalculationItemsByParams = (
  params: GetParams
): Selector<RootState, CalculationItem[] | undefined> => {
  return createSelector(
    [
      (state) => selectCalculationGroupsByParams(params)(state),
      (state) => selectCalculationItemEntities(state.entities.calculationItems),
    ],
    (calculationGroup, calculationItemEntities) => {
      return calculationGroup?.calculationItems
        .map((calculationItemId) => calculationItemEntities[calculationItemId])
        .filter(
          (calculationItem): calculationItem is CalculationItem =>
            calculationItem !== undefined
        )
    }
  )
}
