import React from 'react'
import { TableRow, TableCell, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import color from '../../../styles/color'
import { TABLE_DIVIDER_THICKNESS } from '../../../styles/constants'

export type TableHeaderItem = {
  index: number
  align?: 'left' | 'center' | 'right'
  color: 'red' | 'green' | 'purple'
  title: string
}

export type TableHeaderRowProps = {
  items: TableHeaderItem[]
} & React.StyledProps

const useStyles = makeStyles({
  root: {
    whiteSpace: 'nowrap',
  },
  item: {
    height: 40 + TABLE_DIVIDER_THICKNESS,
    padding: '0 8px',
    border: `1px solid ${color.lightGray02}`,
    borderBottom: `${TABLE_DIVIDER_THICKNESS}px solid ${color.lightGray02}`,
    '&:last-child': {
      width: '100%',
    },
  },
  red: {
    backgroundColor: color.lightOrange,
  },
  green: {
    backgroundColor: color.lightGreen,
  },
  purple: {
    backgroundColor: color.lightBlue,
  },
})

const TableHeaderRow: React.StyledFC<TableHeaderRowProps> = ({
  className,
  items,
}: TableHeaderRowProps) => {
  const classes = useStyles()

  return (
    <TableRow className={clsx(classes.root, className)}>
      {items.map((item) => (
        <TableCell
          key={item.index}
          align={item.align ?? 'left'}
          classes={{
            root: clsx(classes.item, classes[item.color]),
          }}
        >
          <Typography variant="h6" component="span">
            {item.title}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  )
}

export default TableHeaderRow
