import React, { useEffect, useState } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import TextareaFieldWithCount from 'src/components/molecules/TextareaFieldWithCount'
import Modal from '../../../../components/molecules/Modal'
import { TenantDetail } from '../../../../slices/services/api'
import Button from '../../../../components/atoms/Button'
import styles from './styles.module.scss'
import commonStyles from '../../common.module.scss'
import Card from '../../../../components/atoms/Card'
import LabeledTextFieldWithCount from '../../components/LabeledTextFieldWithCount'
import DatePickerWithFormControl from '../../../../components/molecules/DatePickerWithFormControl'
import { FormTypes, FormNameEnum } from '../../type'
import {
  RegexNoSpaceOnly,
  InvalidInputErrorMessage,
} from '../../../../utils/regularExpression'
import { VIEWABLE_CONTRACT_DATE_CODES } from '../../../../domain/org'

export type EditTenantDetailProps = {
  tenantDetail?: TenantDetail
  orgCode: string
  onCancelEdit: () => void
  onSubmit: (data: TenantDetail) => void
}

const contractDateErrorMessage = '契約開始日よりも早い終了日が選択されています'

const EditTenantDetail: React.StyledFC<EditTenantDetailProps> = ({
  tenantDetail,
  orgCode,
  onSubmit,
  onCancelEdit,
}: EditTenantDetailProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const methods = useForm<FormTypes>({
    defaultValues: tenantDetail,
  })

  const { formState, watch, trigger, handleSubmit } = methods

  const watchedContractStartDate = watch(FormNameEnum.contractStartDate)
  const watchedContractEndDate = watch(FormNameEnum.contractEndDate)

  const validateWhetherStartDateIsBeforeEndDate = () => {
    return (
      // NOTE: 日付が片方でも未選択の場合、バリデーションを通す
      !(watchedContractStartDate && watchedContractEndDate) ||
      watchedContractStartDate < watchedContractEndDate ||
      contractDateErrorMessage
    )
  }

  // 契約開始日または終了日に変更があったタイミングでバリデーションを実行
  useEffect(() => {
    trigger([FormNameEnum.contractStartDate, FormNameEnum.contractEndDate])
  }, [watchedContractStartDate, watchedContractEndDate, trigger])

  const handleClickAgree = () => {
    onCancelEdit()
    setIsModalOpen(false)
  }

  const handleClickDisagree = () => {
    setIsModalOpen(false)
  }

  return (
    <Box className={styles.root}>
      <FormProvider {...methods}>
        <form>
          <Box className={commonStyles.container}>
            <Box className={commonStyles.leftColumn}>
              <Box className={commonStyles.leftCard}>
                <Card title="テナント情報">
                  <Box className={styles.textFieldContainer}>
                    <LabeledTextFieldWithCount
                      label="代表者氏名"
                      name={FormNameEnum.staffName}
                      type="text"
                      defaultValue={tenantDetail?.staffName}
                      placeholder="テナント 太郎"
                      validations={[
                        {
                          regex: RegexNoSpaceOnly,
                          message: InvalidInputErrorMessage,
                        },
                      ]}
                    />
                    <LabeledTextFieldWithCount
                      label="電話番号"
                      name={FormNameEnum.staffTel}
                      type="text"
                      defaultValue={tenantDetail?.staffTel}
                      placeholder="090-1234-5678"
                      maxLength={15}
                      validations={[
                        {
                          regex: RegexNoSpaceOnly,
                          message: InvalidInputErrorMessage,
                        },
                      ]}
                    />
                  </Box>
                  {VIEWABLE_CONTRACT_DATE_CODES.includes(orgCode) && (
                    <>
                      <Box className={styles.flexContainer}>
                        <Box className={styles.flexItemContainer}>
                          <Typography component="h3" className={styles.title}>
                            契約開始日
                          </Typography>
                          <DatePickerWithFormControl
                            name={FormNameEnum.contractStartDate}
                            validate={validateWhetherStartDateIsBeforeEndDate}
                          />
                        </Box>
                        <Box className={styles.flexItemContainer}>
                          <Typography component="h3" className={styles.title}>
                            契約終了日
                          </Typography>
                          <DatePickerWithFormControl
                            name={FormNameEnum.contractEndDate}
                            validate={validateWhetherStartDateIsBeforeEndDate}
                          />
                        </Box>
                      </Box>
                      <Box className={styles.errorContainer}>
                        <ErrorMessage
                          errors={formState.errors}
                          name={FormNameEnum.contractStartDate}
                          render={({ message }) => {
                            return (
                              <Typography
                                component="p"
                                className={styles.errorMessage}
                              >
                                {message}
                              </Typography>
                            )
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Card>
              </Box>
            </Box>
            <Box className={commonStyles.rightColumn}>
              <Box className={commonStyles.rightCard}>
                <Card title="管理者メモ（テナントごと）">
                  <TextareaFieldWithCount
                    name={FormNameEnum.note}
                    maxLength={100}
                    defaultValue={tenantDetail?.note}
                  />
                </Card>
              </Box>
            </Box>
          </Box>
          <Box className={commonStyles.footer}>
            <Divider className={commonStyles.divider} />
            <Box className={commonStyles.footerButtonWrapper}>
              <Box className={styles.flexContainer}>
                <Button
                  className={commonStyles.footerButton}
                  color="secondary"
                  size="large"
                  title="もどる"
                  variant="light"
                  onClick={() => setIsModalOpen(true)}
                />
                <Button
                  className={commonStyles.footerButton}
                  type="button"
                  color="primary"
                  size="large"
                  title="更新する"
                  disabled={Object.entries(formState.errors).length !== 0}
                  onClick={handleSubmit(onSubmit)}
                />
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
      <Modal
        open={isModalOpen}
        title={`今中断すると編集内容は\n失われてしまいますがよろしいですか？`}
        agreeButtonTitle="このまま中断する"
        disagreeButtonTitle="編集に戻る"
        onClickAgree={handleClickAgree}
        onClickDisagree={handleClickDisagree}
      />
    </Box>
  )
}

export default EditTenantDetail
