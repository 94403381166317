/* eslint-disable react/prop-types */
import React, { memo, useEffect } from 'react'
import { RegisterOptions, useFormContext, useWatch } from 'react-hook-form'
import clsx from 'clsx'
import { FormValidation, createFormValidation } from 'src/utils/form'
import CancelButton from '../../atoms/CancelButton'
import styles from './styles.module.scss'

export type TextFieldProps = {
  name: string
  type: string
  defaultValue?: string | number
  placeholder?: string
  required?: boolean
  validations?: FormValidation[]
  minLength?: number
  maxLength?: number
  disabled?: boolean
  onBlur?: () => void
  onKeyPress?: () => void
} & React.StyledProps

const TextField: React.StyledFC<TextFieldProps> = memo(
  ({
    className,
    name,
    type,
    defaultValue,
    placeholder,
    required,
    minLength,
    maxLength,
    validations,
    disabled,
    onBlur,
    onKeyPress,
    style,
  }) => {
    const { register, setValue } = useFormContext()
    const watchedValue = useWatch({ name, defaultValue })

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (type === 'number' && event.key === 'e') {
        event.preventDefault()
      }
    }

    const onClickClear = () => {
      setValue(name, '')
    }
    // Note: input要素に直接設定するのではなくこのように設定することで、親componentでwatchできるようになる。
    useEffect(() => {
      setValue(name, defaultValue)
    }, [name, setValue, defaultValue])

    const options: RegisterOptions = {
      required: required && '必須項目です',
      minLength: minLength && {
        value: minLength,
        message:
          minLength === maxLength
            ? `${minLength}文字で入力してください`
            : `${minLength}文字以上で入力してください`,
      },
      maxLength: maxLength && {
        value: maxLength,
        message:
          minLength === maxLength
            ? `${maxLength}文字で入力してください`
            : `${maxLength}文字以下で入力してください`,
      },
      validate: validations && createFormValidation(validations),
    }
    return (
      <div
        className={clsx(className, styles.textField)}
        style={style}
        data-testid="textfield-container"
      >
        <input
          {...register(name, options)}
          name={name}
          className={styles.input}
          type={type}
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
          // defaultValue={defaultValue}
          placeholder={placeholder}
          data-testid="textfield-input"
          disabled={disabled}
          onKeyUp={onKeyPress}
        />
        {Boolean(watchedValue ?? defaultValue) && (
          <CancelButton onClick={onClickClear} />
        )}
      </div>
    )
  }
)
TextField.displayName = 'TextField'

export default TextField
