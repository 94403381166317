import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import { Box, IconButton, Typography } from '@mui/material'
import clsx from 'clsx'
import SelectWithOption, { Option } from '../SelectWithOption'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import {
  CalculationMethod,
  CalculationRoundingTypeEnum,
} from '../../../slices/services/api'
import styles from './styles.module.scss'

export interface CalculationMethodFieldValue {
  roundingType: CalculationRoundingTypeEnum
  precision: number
}

export type EditCalculationMethodProps = {
  calculationMethod?: CalculationMethod
  roundingTypeOptions: Option[]
  precisionOptions: Option[]
  onClickEditCalculationMethod: (
    roundingType: CalculationRoundingTypeEnum,
    precision: number
  ) => void
} & React.StyledProps

const EXAMPLE_INPUT: number = 123.456

const EditCalculationMethod: React.StyledFC<EditCalculationMethodProps> = ({
  className,
  calculationMethod,
  roundingTypeOptions,
  precisionOptions,
  onClickEditCalculationMethod,
}: EditCalculationMethodProps) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const precisionTitle = useMemo(
    () =>
      precisionOptions.find((opt) => opt.value === calculationMethod?.precision)
        ?.title || '',
    [calculationMethod?.precision, precisionOptions]
  )
  const roundingTypeText = useMemo(
    () =>
      roundingTypeOptions.find(
        (opt) => opt.value === calculationMethod?.roundingType
      )?.title || '',
    [calculationMethod?.roundingType, roundingTypeOptions]
  )

  const handleClickEdit = () => {
    setIsEdit(true)
  }
  const onSubmit = (formValue: CalculationMethodFieldValue) => {
    if (
      formValue.roundingType !== calculationMethod?.roundingType ||
      formValue.precision !== calculationMethod?.precision
    ) {
      onClickEditCalculationMethod(formValue.roundingType, formValue.precision)
    }
    setIsEdit(false)
  }

  const calculateExample = useCallback(
    (
      roundingType?: CalculationRoundingTypeEnum,
      precision?: number
    ): string => {
      if (!roundingType || !precision) return ''

      let output: number
      const factor = 10 ** precision

      switch (roundingType) {
        case CalculationRoundingTypeEnum.Round:
          output = Number(EXAMPLE_INPUT.toFixed(precision))
          break
        case CalculationRoundingTypeEnum.Truncate:
          output = Math.trunc(EXAMPLE_INPUT * factor) / factor
          break
        case CalculationRoundingTypeEnum.RoundingUp:
          output = Math.ceil(EXAMPLE_INPUT * factor) / factor
          break
        default:
          output = EXAMPLE_INPUT
      }

      return `(例) ${EXAMPLE_INPUT} → ${output}`
    },
    []
  )

  const formMethods = useForm<CalculationMethodFieldValue>({
    defaultValues: {
      roundingType: calculationMethod?.roundingType,
      precision: calculationMethod?.precision,
    },
  })
  const [example, setExample] = useState<string>(
    calculateExample(
      calculationMethod?.roundingType,
      calculationMethod?.precision
    )
  )

  useEffect(() => {
    const subscription = formMethods.watch(() => {
      const roundingType = formMethods.getValues('roundingType')
      const precision = formMethods.getValues('precision')

      if (roundingType && precision !== undefined) {
        const exampleText = calculateExample(roundingType, precision)
        setExample(exampleText)
      }
    })
    return () => subscription.unsubscribe()
  }, [calculateExample, formMethods, formMethods.watch])

  return isEdit ? (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(onSubmit)}
        className={clsx(className, styles.form)}
      >
        <Box className={styles.formContainer}>
          <Box className={styles.editCalculationMethod}>
            <Typography variant="subtitle2" className={styles.label}>
              端数処理
            </Typography>
            <SelectWithOption
              className={styles.roundingTypeOptions}
              name="roundingType"
              placeholder={roundingTypeText}
              options={roundingTypeOptions}
              variant="outlined"
            />
          </Box>
          <Box className={styles.precision}>
            <Typography variant="subtitle2" className={styles.label}>
              桁数
            </Typography>
            <SelectWithOption
              className={styles.precisionOptions}
              name="precision"
              placeholder={precisionTitle}
              options={precisionOptions}
              variant="outlined"
            />
          </Box>
          <Typography className={styles.example}>{example}</Typography>
          <Box className={styles.buttonWrapper}>
            <Button
              className={styles.updateButton}
              title="更新"
              color="secondary"
              size="small"
              shape="round"
              type="submit"
            />
            <Button
              className={styles.cancelButton}
              title="キャンセル"
              color="secondary"
              size="small"
              shape="round"
              onClick={() => {
                setIsEdit(false)
              }}
            />
          </Box>
        </Box>
      </form>
    </FormProvider>
  ) : (
    <Box className={clsx(className, styles.displayCalculationMethod)}>
      <Typography variant="subtitle2">端数処理</Typography>
      <Typography className={styles.value}>{roundingTypeText}</Typography>
      <Typography variant="subtitle2">桁数</Typography>
      <Typography
        className={styles.value}
      >{`${calculationMethod?.precision}桁`}</Typography>
      <Box className={styles.editWrapper} onClick={handleClickEdit}>
        <IconButton className={styles.editIcon}>
          <Icon icon="edit" />
        </IconButton>
        <Typography className={styles.editDescription}>編集</Typography>
      </Box>
    </Box>
  )
}

export default EditCalculationMethod
