import React, { memo } from 'react'
import { RegisterOptions, useFormContext, useWatch } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import { ErrorMessage } from '@hookform/error-message'
import clsx from 'clsx'
import { FormValidation, createFormValidation } from 'src/utils/form'
import styles from './styles.module.scss'

export type TextareaFieldWithCountProps = {
  name: string
  defaultValue?: string
  placeholder?: string
  validations?: FormValidation[]
  maxLength?: number
  onBlur?: () => void
  onKeyUp?: () => void
} & React.StyledProps

const TextareaFieldWithCount: React.StyledFC<TextareaFieldWithCountProps> =
  memo(
    ({
      className,
      name,
      defaultValue,
      placeholder,
      validations,
      maxLength,
      onBlur,
      onKeyUp,
    }: TextareaFieldWithCountProps) => {
      const { trigger, register, formState } = useFormContext()
      const options: RegisterOptions = {
        maxLength: maxLength && {
          value: maxLength,
          message: `${maxLength}文字以下で入力してください`,
        },
        validate: validations && createFormValidation(validations),
      }

      const watchedValue = useWatch({
        name,
        defaultValue: defaultValue ?? '',
      })

      return (
        <Box className={clsx(className, styles.root)}>
          <Box>
            {/* FIXME: DOM構成的にTextAreaがBoxを飛び出しているので修正する */}
            <textarea
              {...register(name, options)}
              className={clsx(
                styles.textarea,
                formState.errors[name] && styles.error
              )}
              name={name}
              defaultValue={defaultValue}
              placeholder={placeholder}
              onKeyUp={onKeyUp || (() => trigger(name))}
              onBlur={onBlur}
            />
          </Box>
          <Box className={styles.container}>
            <Box>
              <ErrorMessage
                errors={formState.errors}
                name={name}
                render={({ message }) => (
                  <Typography component="p" className={styles.errorMessage}>
                    {message}
                  </Typography>
                )}
              />
            </Box>
            <Box>
              {maxLength && (
                <Typography className={styles.counter}>
                  {`${watchedValue.length}/${maxLength}`}
                  <span>文字</span>
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      )
    }
  )
TextareaFieldWithCount.displayName = 'TextareaFieldWithCount'

export default TextareaFieldWithCount
