import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import BreadcrumbsPipe, { Path } from '../../molecules/BreadcrumbsPipe'
import { Cell as HeaderCell } from '../../molecules/CalculationTableHeaderRow'
import CenteringLoading from '../../molecules/CenteringLoading'
import Modal from '../../molecules/Modal'
import EditReadItemTable, { BodyRow } from '../../organisms/EditReadItemTable'

export type EditReadItemProps = {
  breadcrumbs: Path[]
  isLoading: boolean
  onClickGoForward: () => void
  onClickClose: () => void
  columns: HeaderCell[]
  rows: BodyRow[]
  onClickAdd: (rowIndex: number) => void
  showModal: boolean
  onClickConfirm: () => void
  onClickCancel: () => void
}

const HEADER_HEIGHT = 94

const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    width: '100%',
    height: HEADER_HEIGHT,
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  breadcrumb: {
    margin: '24px 0 0 20px',
  },
  closeButtonWrapper: {
    margin: '20px 20px 0 0',
  },
  closeButton: {
    width: 20,
    height: 20,
  },
  contentWrapper: {
    display: 'flex',
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    width: '80%',
    minWidth: 854,
    height: '100%',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
  },
  title: {
    marginBottom: 32,
    lineHeight: '36px',
    whiteSpace: 'pre-wrap',
  },
  table: {
    width: '100%',
    marginBottom: 32,
  },
  buttonWrapper: {
    margin: 'auto 0 124px',
    textAlign: 'center',
  },
})

const EditReadItemTemplate: React.StyledFC<EditReadItemProps> = ({
  breadcrumbs,
  isLoading,
  onClickGoForward,
  onClickClose,
  columns,
  rows,
  onClickAdd,
  showModal,
  onClickConfirm,
  onClickCancel,
}: EditReadItemProps) => {
  const classes = useStyles()

  const renderContent = () => {
    return (
      <Box className={classes.content}>
        <Typography className={classes.title} variant="h1">
          {`売上報告フォーマットの各項目に対応する\nレシートの項目を登録しましょう。`}
        </Typography>
        <EditReadItemTable
          className={classes.table}
          columns={columns}
          rows={rows}
          onClickAdd={onClickAdd}
        />
        <Box className={classes.buttonWrapper}>
          <Button
            title="つぎへ"
            onClick={onClickGoForward}
            color="primary"
            size="large"
          />
        </Box>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.header} component="header">
        <BreadcrumbsPipe className={classes.breadcrumb} paths={breadcrumbs} />
        <Box className={classes.closeButtonWrapper}>
          <IconButton
            className={classes.closeButton}
            onClick={onClickClose}
            size="large"
          >
            <Icon icon="close" />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.contentWrapper}>
        {isLoading ? <CenteringLoading /> : renderContent()}
        <Modal // `disableBackdropClick` is removed by codemod.
          // You can find more details about this breaking change in [the migration guide](https://mui.com/guides/migration-v4/#modal)

          open={showModal}
          title={`今中断するとこれまでの設定内容は\n失われてしまいますがよろしいですか？`}
          agreeButtonTitle="このまま中断する"
          disagreeButtonTitle="設定に戻る"
          onClickAgree={onClickConfirm}
          onClickDisagree={onClickCancel}
        />
      </Box>
    </Box>
  )
}

export default EditReadItemTemplate
