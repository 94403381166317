import { useContext } from 'react'
import AuthenticatedApi from '../slices/services/authenticatedApi' // TODO: api呼び出しをhooksから利用するとすると、feturesを呼ぶのが遠いので構成変更検討
import { TenantDetail, TenantDetailsPostRequest } from '../slices/services/api'
import { ApiResponse } from '../domain/api'
import { axiosErrorMessage } from '../slices/services/error'
import { AuthContext } from '../context/auth.context'

export const useApiTenantDetail = () => {
  const authContext = useContext(AuthContext)

  const getTenantDetail = async (
    orgCode: string,
    storeCode: string,
    tenantCode: string
  ): Promise<ApiResponse<TenantDetail>> => {
    try {
      const token = authContext.getAuthToken()
      if (!token) {
        return {
          success: false,
          message: 'ログインしなおしてください: token is not found',
        }
      }
      const res = await new AuthenticatedApi(
        token
      ).getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
        orgCode,
        storeCode,
        tenantCode
      )
      return { success: true, data: res.data?.tenantDetail || {} }
    } catch (e: unknown) {
      const message = axiosErrorMessage(e)
      return { success: false, message }
    }
  }

  const postTenantDetail = async (
    orgCode: string,
    storeCode: string,
    tenantCode: string,
    tenantDetail: TenantDetailsPostRequest
  ): Promise<ApiResponse<TenantDetail>> => {
    try {
      const token = authContext.getAuthToken()
      if (!token) {
        return {
          success: false,
          message: 'ログインしなおしてください: token is not found',
        }
      }
      await new AuthenticatedApi(
        token
      ).postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
        orgCode,
        storeCode,
        tenantCode,
        tenantDetail
      )
      return { success: true }
    } catch (e: unknown) {
      const message = axiosErrorMessage(e)
      return { success: false, message }
    }
  }

  return { getTenantDetail, postTenantDetail }
}
