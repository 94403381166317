import React from 'react'
import { Box, Table, TableBody, TableHead } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import color from '../../../styles/color'
import { TABLE_DIVIDER_THICKNESS } from '../../../styles/constants'
import { injectIndex } from '../../../utils/array'
import { isOdd } from '../../../utils/number'
import { CellSize } from '../../atoms/CalculationTableBodyCell'
import CalculationTableBodyRow, {
  Cell as LeftTableCell,
} from '../../molecules/CalculationTableBodyRow'
import CalculationTableHeaderRow, {
  Cell as HeaderCell,
} from '../../molecules/CalculationTableHeaderRow'
import EditFormatRightBodyRow, {
  SelectCell,
  InputCell,
} from '../../molecules/EditFormatRightBodyRow'
import { Option } from '../../molecules/SelectWithOption'

export type BodyRow = {
  number: string
  calculationItemName: string
  readItemName: string
  selectCells: SelectCell[]
  inputCell: InputCell
  showInput: boolean
}

export type EditOCRFormatTableProps = {
  columns: HeaderCell[]
  rows: BodyRow[]
  onChangeSelect: (option: Option, rowIndex: number, name: string) => void
} & React.StyledProps

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
  },
  leftTableWrapper: {
    position: 'sticky',
    zIndex: 1,
    left: 0,
  },
  leftTable: {
    borderRight: `${TABLE_DIVIDER_THICKNESS}px solid ${color.lightGray02}`,
  },
  tableBodyRow: {
    height: 56,
  },
  rightTableWrapper: {
    zIndex: 0,
    width: '100%',
  },
  rightTableHeader: {
    padding: '0 10px',
  },
})

const EditOCRFormatTable: React.StyledFC<EditOCRFormatTableProps> = ({
  className,
  columns,
  rows,
  onChangeSelect,
}: EditOCRFormatTableProps) => {
  const classes = useStyles()

  const convertedLeftTableCell = (row: BodyRow): LeftTableCell[] => {
    const cells = [
      {
        text: row.number,
        size: 'medium' as CellSize,
      },
      {
        text: row.calculationItemName,
        size: 'large' as CellSize,
      },
      {
        text: row.readItemName,
        size: 'large' as CellSize,
      },
    ]
    return injectIndex(cells)
  }

  return (
    <Box className={clsx(className, classes.root)}>
      <Box className={classes.leftTableWrapper}>
        <Table stickyHeader className={classes.leftTable}>
          <TableHead>
            <CalculationTableHeaderRow cells={columns.slice(0, 3)} />
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <CalculationTableBodyRow
                className={classes.tableBodyRow}
                key={row.number}
                cells={convertedLeftTableCell(row)}
                isGray={isOdd(rowIndex)}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box className={classes.rightTableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <CalculationTableHeaderRow cells={columns.slice(3)} />
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <EditFormatRightBodyRow
                className={classes.tableBodyRow}
                key={row.number}
                selectCells={row.selectCells}
                inputCell={row.inputCell}
                isGray={isOdd(rowIndex)}
                showInput={row.showInput}
                onChangeSelect={(option, name) =>
                  onChangeSelect(option, rowIndex, name)
                }
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

export default EditOCRFormatTable
