/* eslint-disable import/prefer-default-export */

export const isEven = (number: number): boolean => {
  return number % 2 === 0
}

export const isOdd = (number: number): boolean => {
  return number % 2 === 1
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const isNumber = (value: any): boolean => {
  if (typeof value === 'string') {
    return value !== '' && !Number.isNaN(Number(value.replace(/,/g, '')))
  }
  return !Number.isNaN(Number(value))
}
