enum TokenType {
  Operation,
  LeftParenthesis,
  RightParenthesis,
  ReadItem,
  CalculationItem,
  Number,
  EOF,
}

export const allTokenTypes: TokenType[] = [
  TokenType.Operation,
  TokenType.LeftParenthesis,
  TokenType.RightParenthesis,
  TokenType.ReadItem,
  TokenType.CalculationItem,
  TokenType.Number,
  TokenType.EOF,
]

const uuidPattern =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'

export function tokenRegExp(type: TokenType): RegExp {
  switch (type) {
    case TokenType.Operation:
      return /^[+\-*/]/
    case TokenType.LeftParenthesis:
      return /^\(\(/
    case TokenType.RightParenthesis:
      return /^\)\)/
    case TokenType.ReadItem:
      return new RegExp(`^{{${uuidPattern}}}`)
    case TokenType.CalculationItem:
      return new RegExp(`^<<${uuidPattern}>>`)
    case TokenType.Number:
      return /^[0-9]+(\.[0-9]+)?/
    case TokenType.EOF:
      return /^$/
    default:
      throw new Error('Invalid token type')
  }
}

export default TokenType
