import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import Icon from '../../atoms/Icon'
import styles from './styles.module.scss'

export type AppTableAddRowButtonProps = {
  onClick: () => void
} & React.StyledProps

const AppTableAddRowButton: React.StyledFC<AppTableAddRowButtonProps> = ({
  onClick,
}: AppTableAddRowButtonProps) => {
  return (
    <Box className={styles.buttonWrapper}>
      <Button className={styles.button} onClick={onClick}>
        <Icon icon="plus" />
        <Typography variant="body1">空行を追加</Typography>
      </Button>
    </Box>
  )
}

export default AppTableAddRowButton
