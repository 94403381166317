export type TenantStatus = 'ready' | 'not_ready' | 'closed'

// TODO: schema 変更後に修正
export enum ConsistencyMismatch {
  Any = 'any',
  Calculation = 'calculation',
  Input = 'input',
}

export type Color = 'primary' | 'green' | 'red' | 'gray'
