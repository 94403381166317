import React from 'react'
import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import ReportMessages, {
  ReportMessage,
} from 'src/components/molecules/ReportMessages'
import styles from './styles.module.scss'
import Icon from '../../../../components/atoms/Icon'

export type SalesReportMessagesProps = {
  reportMessages: ReportMessage[]
  tenantMessageBody?: string
  manageMessage?: string
} & React.StyledProps

const SalesReportMessages: React.StyledFC<SalesReportMessagesProps> = ({
  className,
  reportMessages,
  tenantMessageBody,
  manageMessage,
}: SalesReportMessagesProps) => {
  return (
    <Box className={clsx(className, styles.root)}>
      {reportMessages.length > 0 && (
        <Box className={styles.eachCategory}>
          <Box className={styles.title}>
            <Icon
              className={clsx(styles.icon, styles.colorBlack)}
              icon="memo"
            />
            <Typography className={styles.text} variant="h4">
              テナントからの補足事項
            </Typography>
          </Box>
          <ReportMessages reportMessages={reportMessages} />
        </Box>
      )}
      <Box className={styles.eachCategory}>
        <Box className={styles.title}>
          <Icon className={clsx(styles.icon, styles.colorBlack)} icon="note2" />
          <Typography className={styles.text} variant="h4">
            管理者メモ(テナントごと)
          </Typography>
        </Box>
        <Box className={styles.message}>
          <Typography variant="body1" className={styles.messageBody}>
            {tenantMessageBody}
          </Typography>
        </Box>
      </Box>
      <Box className={styles.eachCategory}>
        <Box className={styles.title}>
          <Icon className={clsx(styles.icon, styles.colorBlack)} icon="eye" />
          <Typography className={styles.text} variant="h4">
            管理者メモ(報告ごと)
          </Typography>
        </Box>
        <Box className={styles.message}>
          <Typography variant="body1" className={styles.messageBody}>
            {manageMessage}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default SalesReportMessages
