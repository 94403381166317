import React, { memo, useState } from 'react'
import Icon from 'src/components/atoms/Icon'
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import Button from 'src/components/atoms/Button'
import TextareaFieldWithCount from 'src/components/molecules/TextareaFieldWithCount'
import clsx from 'clsx'
import { FormProvider, useFormContext } from 'react-hook-form'
import styles from './styles.module.scss'

type ScreenState = 'input' | 'confirm'

export type InputNotificationModalProps = {
  open: boolean
  onClickSend: (notification: string) => void
  onClickClose: () => void
} & React.StyledProps

const InputNotificationModal: React.StyledFC<InputNotificationModalProps> =
  memo(
    ({
      className,
      open,
      onClickSend,
      onClickClose,
    }: InputNotificationModalProps) => {
      const formMethods = useFormContext()
      const { getValues, watch, resetField } = formMethods
      const notificationWatcher = watch('notification')

      const [notificationModalScreen, setNotificationModalScreen] =
        useState<ScreenState>('input')
      const isEditing = notificationModalScreen === 'input'

      const handleClickClose = () => {
        setNotificationModalScreen('input')
        onClickClose()
      }

      const handleChangeScreen = (screen: ScreenState) => {
        setNotificationModalScreen(screen)
      }

      const handleClickSend = async () => {
        try {
          await onClickSend(getValues('notification'))
          resetField('notification')
          setNotificationModalScreen('input')
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('メッセージ送信失敗', error)
        }
      }

      return (
        <Dialog
          classes={{
            paper: clsx(className, styles.dialog),
          }}
          sx={{
            className: styles.backDrop,
          }}
          open={open}
          onClose={handleClickClose}
        >
          <FormProvider {...formMethods}>
            <form>
              <Box className={styles.container}>
                <DialogTitle className={styles.header}>
                  <Typography className={styles.title}>
                    {isEditing ? 'メッセージ' : 'メッセージを確認'}
                  </Typography>
                  <IconButton
                    className={styles.closeButton}
                    onClick={handleClickClose}
                  >
                    <Icon icon="close" />
                  </IconButton>
                </DialogTitle>
                {isEditing ? (
                  <TextareaFieldWithCount
                    className={styles.textField}
                    name="notification"
                    placeholder="こちらにメッセージを入力してください"
                    maxLength={300}
                  />
                ) : (
                  <Box className={styles.confirmWrapper}>
                    <Typography className={styles.confirmMessage}>
                      こちらの内容で登録してもよろしいですか？
                    </Typography>
                    <Box className={styles.confirmNotificationWrapper}>
                      <Typography className={styles.confirmInputNotification}>
                        {getValues('notification')}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <DialogActions disableSpacing className={styles.action}>
                  <Button
                    color="secondary"
                    size="large"
                    className={styles.backButton}
                    title={isEditing ? 'キャンセル' : '戻る'}
                    onClick={
                      isEditing
                        ? handleClickClose
                        : () => handleChangeScreen('input')
                    }
                  />
                  <Button
                    color="primary"
                    size="large"
                    className={styles.agreeButton}
                    title={isEditing ? '確認する' : '登録する'}
                    disabled={
                      !notificationWatcher ||
                      notificationWatcher === '' ||
                      notificationWatcher.length > 300
                    }
                    onClick={
                      isEditing
                        ? () => handleChangeScreen('confirm')
                        : handleClickSend
                    }
                  />
                </DialogActions>
              </Box>
            </form>
          </FormProvider>
        </Dialog>
      )
    }
  )

InputNotificationModal.displayName = 'InputNotificationModal'
export default InputNotificationModal
