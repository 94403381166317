import React from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import BreadcrumbsPipe, { Path } from '../../molecules/BreadcrumbsPipe'
import Guide from '../../molecules/Guide'

export type CompleteEditCalculationProps = {
  breadcrumbs: Path[]
  onClickClose: () => void
}

const HEADER_HEIGHT = 94

const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
  },
  header: {
    width: '100%',
    height: HEADER_HEIGHT,
    flexGrow: 1,
  },
  breadcrumb: {
    margin: '24px 0 0 20px',
  },
  contentWrapper: {
    display: 'flex',
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const CompleteEditCalculation: React.StyledFC<CompleteEditCalculationProps> = ({
  breadcrumbs,
  onClickClose,
}: CompleteEditCalculationProps) => {
  const classes = useStyles()

  const buttons = [
    {
      index: 0,
      title: '閉じる',
      onClick: onClickClose,
    },
  ]

  return (
    <Box className={classes.root}>
      <Box className={classes.header} component="header">
        <BreadcrumbsPipe className={classes.breadcrumb} paths={breadcrumbs} />
      </Box>
      <Box className={classes.contentWrapper}>
        <Guide
          message="設定が
完了しました。"
          description="テナントの利用準備ができました。ステータスは利用中に変更になります。利用停止する場合などはステータスの変更をしましょう。"
          buttons={buttons}
          icon="clap"
        />
      </Box>
    </Box>
  )
}

export default CompleteEditCalculation
