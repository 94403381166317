import React from 'react'
import { SvgIcon } from '@mui/material'
import { StyledComponentProps } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { ReactComponent as RotateLeftIcon } from './assets/RotateLeft.svg'
import { ReactComponent as RotateRightIcon } from './assets/RotateRight.svg'
import { ReactComponent as ArrowDropDownIcon } from './assets/arrowDropDown.svg'
import { ReactComponent as BagIcon } from './assets/bag.svg'
import { ReactComponent as CalculatorIcon } from './assets/calculator.svg'
import { ReactComponent as CalendarIcon } from './assets/calendar.svg'
import { ReactComponent as CameraIcon } from './assets/camera.svg'
import { ReactComponent as CheckIcon } from './assets/check.svg'
import { ReactComponent as CheckedIcon } from './assets/checked.svg'
import { ReactComponent as ClapIcon } from './assets/clap.svg'
import { ReactComponent as CloseIcon } from './assets/close.svg'
import { ReactComponent as CloseWithCircle } from './assets/closeWithCircle.svg'
import { ReactComponent as CompanyIcon } from './assets/company.svg'
import { ReactComponent as CrossIcon } from './assets/cross.svg'
import { ReactComponent as DeleteIcon } from './assets/delete.svg'
import { ReactComponent as EditIcon } from './assets/edit.svg'
import { ReactComponent as EmailIcon } from './assets/email.svg'
import { ReactComponent as Exclamation } from './assets/exclamation.svg'
import { ReactComponent as ExternalLink } from './assets/externalLink.svg'
import { ReactComponent as ExpandLessIcon } from './assets/expandLess.svg'
import { ReactComponent as ExpandMoreIcon } from './assets/expandMore.svg'
import { ReactComponent as EyeIcon } from './assets/eye.svg'
import { ReactComponent as HelpIcon } from './assets/help.svg'
import { ReactComponent as HomeIcon } from './assets/home.svg'
import { ReactComponent as KirakiraIcon } from './assets/kirakira.svg'
import { ReactComponent as LogoIcon } from './assets/logo.svg'
import { ReactComponent as LogoutIcon } from './assets/logout.svg'
import { ReactComponent as MatchIcon } from './assets/match.svg'
import { ReactComponent as MatchPartialIcon } from './assets/match_partial.svg'
import { ReactComponent as MemberIcon } from './assets/member.svg'
import { ReactComponent as MemoIcon } from './assets/memo.svg'
import { ReactComponent as MinusIcon } from './assets/minus.svg'
import { ReactComponent as MoreIcon } from './assets/more.svg'
import { ReactComponent as NavigateNextIcon } from './assets/navigateNext.svg'
import { ReactComponent as NoteIcon } from './assets/note.svg'
import { ReactComponent as Note2Icon } from './assets/note2.svg'
import { ReactComponent as OrganizationIcon } from './assets/organization.svg'
import { ReactComponent as PlusIcon } from './assets/plus.svg'
import { ReactComponent as PointRightIcon } from './assets/pointRight.svg'
import { ReactComponent as PrayIcon } from './assets/pray.svg'
import { ReactComponent as ProfileIcon } from './assets/profile.svg'
import { ReactComponent as ReloadIcon } from './assets/reload.svg'
import { ReactComponent as SearchIcon } from './assets/search.svg'
import { ReactComponent as SendIcon } from './assets/send.svg'
import { ReactComponent as ShopIcon } from './assets/shop.svg'
import { ReactComponent as StoreIcon } from './assets/store.svg'
import { ReactComponent as TenantIcon } from './assets/tenant.svg'
import { ReactComponent as TipsIcon } from './assets/tips.svg'
import { ReactComponent as MisMatchIcon } from './assets/misMatch.svg'
import { ReactComponent as UserIcon } from './assets/user.svg'

export type IconName =
  | 'arrowDropDown'
  | 'bag'
  | 'calculator'
  | 'calendar'
  | 'camera'
  | 'check'
  | 'checked'
  | 'clap'
  | 'close'
  | 'closeWithCircle'
  | 'company'
  | 'cross'
  | 'eye'
  | 'delete'
  | 'edit'
  | 'email'
  | 'exclamation'
  | 'expandLess'
  | 'expandMore'
  | 'externalLink'
  | 'help'
  | 'home'
  | 'kirakira'
  | 'logo'
  | 'logout'
  | 'match'
  | 'matchPartial'
  | 'member'
  | 'memo'
  | 'minus'
  | 'more'
  | 'navigateNext'
  | 'note'
  | 'note2'
  | 'organization'
  | 'pointRight'
  | 'profile'
  | 'plus'
  | 'pray'
  | 'reload'
  | 'rotateLeft'
  | 'rotateRight'
  | 'search'
  | 'send'
  | 'shop'
  | 'store'
  | 'tenant'
  | 'tips'
  | 'misMatch'
  | 'user'

export type IconSize = 'inherit' | 'large' | 'medium' | 'small'

const icons = {
  arrowDropDown: ArrowDropDownIcon,
  bag: BagIcon,
  calculator: CalculatorIcon,
  calendar: CalendarIcon,
  camera: CameraIcon,
  check: CheckIcon,
  checked: CheckedIcon,
  clap: ClapIcon,
  close: CloseIcon,
  closeWithCircle: CloseWithCircle,
  company: CompanyIcon,
  cross: CrossIcon,
  delete: DeleteIcon,
  edit: EditIcon,
  email: EmailIcon,
  exclamation: Exclamation,
  expandLess: ExpandLessIcon,
  expandMore: ExpandMoreIcon,
  externalLink: ExternalLink,
  eye: EyeIcon,
  help: HelpIcon,
  home: HomeIcon,
  kirakira: KirakiraIcon,
  logo: LogoIcon,
  logout: LogoutIcon,
  match: MatchIcon,
  matchPartial: MatchPartialIcon,
  member: MemberIcon,
  memo: MemoIcon,
  minus: MinusIcon,
  more: MoreIcon,
  navigateNext: NavigateNextIcon,
  note: NoteIcon,
  note2: Note2Icon,
  organization: OrganizationIcon,
  plus: PlusIcon,
  pointRight: PointRightIcon,
  pray: PrayIcon,
  profile: ProfileIcon,
  rotateLeft: RotateLeftIcon,
  rotateRight: RotateRightIcon,
  reload: ReloadIcon,
  search: SearchIcon,
  send: SendIcon,
  shop: ShopIcon,
  store: StoreIcon,
  tenant: TenantIcon,
  tips: TipsIcon,
  misMatch: MisMatchIcon,
  user: UserIcon,
}

type Size = {
  width: number
  height: number
}

const defaultSizes = {
  arrowDropDown: { width: 16, height: 16 },
  bag: { width: 21, height: 18 },
  calculator: { width: 15, height: 13 },
  calendar: { width: 20, height: 19 },
  camera: { width: 42, height: 43 },
  check: { width: 22, height: 21 },
  checked: { width: 10, height: 8 },
  clap: { width: 56, height: 57 },
  close: { width: 19, height: 19 },
  closeWithCircle: { width: 27, height: 27 },
  company: { width: 22, height: 22 },
  cross: { width: 9, height: 9 },
  delete: { width: 20, height: 20 },
  edit: { width: 15, height: 15 },
  email: { width: 56, height: 57 },
  exclamation: { width: 16, height: 16 },
  expandLess: { width: 16, height: 16 },
  expandMore: { width: 16, height: 16 },
  externalLink: { width: 16, height: 16 },
  eye: { width: 16, height: 16 },
  help: { width: 16, height: 16 },
  home: { width: 21, height: 21 },
  kirakira: { width: 395, height: 394 },
  logo: { width: 64, height: 24 },
  logout: { width: 40, height: 40 },
  match: { width: 24, height: 24 },
  matchPartial: { width: 24, height: 24 },
  member: { width: 26, height: 18 },
  memo: { width: 17, height: 15 },
  minus: { width: 30, height: 30 },
  more: { width: 16, height: 16 },
  navigateNext: { width: 14, height: 14 },
  note: { width: 20, height: 19 },
  note2: { width: 16, height: 16 },
  organization: { width: 16, height: 17 },
  plus: { width: 16, height: 16 },
  pointRight: { width: 56, height: 57 },
  pray: { width: 42, height: 43 },
  profile: { width: 18, height: 19 },
  rotateLeft: { width: 40, height: 40 },
  rotateRight: { width: 40, height: 40 },
  reload: { width: 21, height: 21 },
  search: { width: 16, height: 16 },
  send: { width: 22, height: 22 },
  shop: { width: 22, height: 21 },
  store: { width: 24, height: 25 },
  tenant: { width: 24, height: 25 },
  tips: { width: 16, height: 16 },
  misMatch: { width: 24, height: 24 },
  user: { width: 16, height: 17 },
}

const viewBoxes = (size: Size): string => {
  return `0 0 ${size.width} ${size.height}`
}

const useStyles = makeStyles({
  ...defaultSizes,
  root: {
    fill: 'none',
  },
})

export type IconProps = {
  icon: IconName
  fontSize?: IconSize
} & React.StyledProps &
  StyledComponentProps

const Icon: React.StyledFC<IconProps> = ({
  icon,
  fontSize = 'inherit',
  className,
  classes,
}: IconProps) => {
  const Component = icons[icon]
  const iconClasses = useStyles()
  const viewBox = viewBoxes(defaultSizes[icon])

  return (
    <SvgIcon
      data-testid={icon}
      fontSize={fontSize}
      component={Component}
      className={clsx(
        fontSize === 'inherit' && iconClasses[icon],
        iconClasses.root,
        className
      )}
      classes={classes}
      viewBox={viewBox}
    />
  )
}

export default Icon
