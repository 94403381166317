import React, { memo } from 'react'
import { Box, Table, TableBody } from '@mui/material'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { AppTableHead, AppTableRow, SelectCell, InputCell } from '../../tables'
import styles from './styles.module.scss'
import {
  CODE_PLACEHOLDER,
  TABLE_UPDATE_COLUMNS,
  NAME_PLACEHOLDER,
  STATUS_OPTIONS,
  STATUS_PLACEHOLDER,
} from '../../../domain/tenant'
import { TenantStatusEnum } from '../../../slices/services/api'
import {
  TenantCodeRegex,
  TenantCodeRegexErrorMessage,
} from '../../../domain/tenantCode'

export type TenantRow = {
  index: number
  name: string
  code: string
  status: TenantStatusEnum
}

export type TenantFormType = {
  tenants: {
    name: string
    code: string
    status: TenantStatusEnum
  }[]
}

export type TenantUpdateTableProps = {
  tenants: TenantRow[]
  orgCode: string
} & React.StyledProps

const TenantUpdateTable: React.StyledFC<TenantUpdateTableProps> = memo(
  ({ className, style, tenants, orgCode }: TenantUpdateTableProps) => {
    const formMethods = useFormContext()
    return (
      <Box className={clsx(className, styles.root)} style={style}>
        <Box className={styles.tableWrapper}>
          <Table stickyHeader className={styles.table}>
            <AppTableHead columns={TABLE_UPDATE_COLUMNS} />
            <TableBody>
              {tenants.map((tenant) => (
                <AppTableRow key={tenant.index}>
                  <SelectCell
                    name={`tenants.${tenant.index}.status`}
                    options={STATUS_OPTIONS}
                    placeholder={STATUS_PLACEHOLDER}
                    defaultValue={
                      formMethods.getValues(`tenants.${tenant.index}.status`) ||
                      tenant.status
                    }
                    width={140}
                  />
                  <InputCell
                    name={`tenants.${tenant.index}.code`}
                    defaultValue={
                      formMethods.getValues(`tenants.${tenant.index}.code`) ||
                      tenant.code
                    }
                    placeholder={CODE_PLACEHOLDER}
                    width={180}
                    required
                    validations={[
                      {
                        regex: TenantCodeRegex(orgCode),
                        message: TenantCodeRegexErrorMessage(orgCode),
                      },
                    ]}
                  />
                  <InputCell
                    name={`tenants.${tenant.index}.name`}
                    defaultValue={
                      formMethods.getValues(`tenants.${tenant.index}.name`) ||
                      tenant.name
                    }
                    placeholder={NAME_PLACEHOLDER}
                    width={260}
                    required
                  />
                </AppTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    )
  }
)

TenantUpdateTable.displayName = 'TenantUpdateTable'
export default TenantUpdateTable
