import React, { useContext } from 'react'
import { Navigate, RouteProps } from 'react-router-dom'
import Loading from '../components/templates/Loading'
import { AuthContext } from '../context/auth.context'
import { useAppSelector } from '../store'

const LoginRoute: React.FC<RouteProps> = ({ children }: RouteProps) => {
  const authContext = useContext(AuthContext)
  const { token } = useAppSelector((state) => state.auth)

  // リロードしたときにFirebase Authでログイン中でも、auth.userの初期値がnullなので、
  // リダイレクトしてしまうのを防ぐため
  if (authContext.initializing) {
    return <Loading />
  }

  if (token) {
    return <Navigate replace to="/" />
  }

  return children
}

export default LoginRoute
