import makeStyles from '@mui/styles/makeStyles'

const ROOT_MARGIN_BOTTOM = 20
const TABLE_MARGIN_BOTTOM = 28

const useStyles = makeStyles({
  root: {
    height: `calc(100% - ${ROOT_MARGIN_BOTTOM}px)`,
  },
  guideRoot: {
    width: 'initial',
  },
  table: {
    width: 'fit-content',
    height: `calc(100% - ${ROOT_MARGIN_BOTTOM}px - ${TABLE_MARGIN_BOTTOM}px)`,
    overflowY: 'auto',
  },
  calcMethodWithLastUpdatedAt: {
    display: 'flex',
    width: '100%',
    height: 60,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  lastUpdatedAt: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  editButton: {
    position: 'absolute',
    zIndex: 10,
    right: 44,
    bottom: 30,
  },
})

export default useStyles
