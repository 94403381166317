import React from 'react'
import { TableRow } from '@mui/material'
import clsx from 'clsx'
import style from './styles.module.scss'
import CalculationTableBodyCell, {
  CellSize,
} from '../../atoms/CalculationTableBodyCell'

export type CalculationTableBodyRowProps = {
  isGray?: boolean
  cells: Cell[]
} & React.StyledProps

export type Cell = {
  index: number
  text: string
  size: CellSize
}

const CalculationTableBodyRow: React.StyledFC<CalculationTableBodyRowProps> = ({
  isGray = false,
  cells,
  className,
}: CalculationTableBodyRowProps) => {
  return (
    <TableRow className={clsx(className, style.row, isGray && style.grayRow)}>
      {cells.map((cell) => (
        <CalculationTableBodyCell
          key={`${cell.size}_${cell.index}`}
          text={cell.text}
          size={cell.size}
        />
      ))}
    </TableRow>
  )
}

export default CalculationTableBodyRow
