/* eslint-disable import/prefer-default-export */
export const SIDEBAR_WIDTH = 210

export const TABLE_DIVIDER_THICKNESS = 4

export const BREADCRUMB_HEIGHT = 62

export const TABS_WITH_LINE_HEIGHT = 51

export const TAB_CONTENT_HEIGHT = 'calc(100% - 205px)'
