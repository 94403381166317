import React from 'react'
import { TableRow, TableCell, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import color from '../../../styles/color'

export type TableBodyItem = {
  index: number
  align?: 'left' | 'center' | 'right'
  title: string
}

export type TableBodyRowProps = {
  items: TableBodyItem[]
} & React.StyledProps

const useStyles = makeStyles({
  root: {
    backgroundColor: color.white,
    whiteSpace: 'nowrap',
  },
  item: {
    height: 36,
    padding: '0 8px',
    border: `1px solid ${color.lightGray02}`,
  },
})

const TableBodyRow: React.StyledFC<TableBodyRowProps> = ({
  className,
  items,
}: TableBodyRowProps) => {
  const classes = useStyles()

  return (
    <TableRow className={clsx(classes.root, className)}>
      {items.map((item) => (
        <TableCell
          key={item.index}
          align={item.align ?? 'left'}
          className={classes.item}
        >
          <Typography variant="body1" component="span">
            {item.title}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  )
}

export default TableBodyRow
