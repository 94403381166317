import React, { useEffect, useState } from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import MessageBar from './components/atoms/MessageBar'
import { clear } from './slices/snackbar/snackbarSlice'
import AppRouter from './routes'
import { useAppDispatch, useAppSelector } from './store'
import theme from './theme'
import { deleteState } from './slices/confirmSalesReport/confirmSalesReportSlice'

const App: React.FC = () => {
  const dispatch = useAppDispatch()

  const { message, variant } = useAppSelector((state) => state.snackbar)
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setIsOpen(message !== null)
  }, [message])

  useEffect(() => {
    return () => {
      dispatch(deleteState())
    }
  }, [dispatch])

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleExited = () => {
    dispatch(clear())
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {message && variant && (
          <MessageBar
            title={message}
            isOpen={isOpen}
            variant={variant}
            onClose={handleClose}
            onExited={handleExited}
          />
        )}
        <AppRouter />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
