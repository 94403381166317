/* eslint-disable no-param-reassign */
import { Selector } from 'react-redux'
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit'
import { denormalize, normalize } from 'normalizr'
import {
  PaginatedRequestState,
  RequestState,
  RequestStatus,
} from '../../domain/request'
import { NormalizedTenant, tenantEntity } from '../../domain/schema'
// eslint-disable-next-line import/no-cycle
import { AppThunkConfig, RootState } from '../../store'
import { purge } from '../../store/action'
import toKey from '../../utils/key'
// eslint-disable-next-line import/no-cycle
import { getSalesReports } from '../salesReports/salesReportsSlice'
import { OrgTenant, Store, TenantStatusEnum } from '../services/api/api'
import serializeError from '../services/error'
import getTotalCount from '../services/handler'
import AuthenticatedApi from '../services/authenticatedApi'
// eslint-disable-next-line import/no-cycle
import { getTenantSalesReportSummaries } from '../tenantSalesReportSummaries/tenantSalesReportSummariesSlice'
// eslint-disable-next-line import/no-cycle
import { getTenantSalesReports } from '../tenantSalesReports/tenantSalesReportsSlice'

// Tenantを特定するためのCode
export type IndexCode = {
  orgCode: string
  storeCode: string
  tenantCode: string
}

interface TenantState extends EntityState<NormalizedTenant>, RequestState {
  // toKey(IndexCode)の値をTenant.idにマッピングする
  codeToId: {
    [code: string]: string
  }
  query: {
    [key: string]: PaginatedRequestState
  }
  status: RequestStatus
}

const tenantsAdapter = createEntityAdapter<NormalizedTenant>()

export const initialState: TenantState = tenantsAdapter.getInitialState({
  codeToId: {},
  query: {},
  status: 'idle',
})

// テナントを1件取得する
type GetTenantParams = IndexCode

export const getTenant = createAsyncThunk<
  NormalizedTenant,
  GetTenantParams,
  AppThunkConfig
>(
  'tenants/getTenant',
  async (params, { getState }) => {
    const { auth } = getState()
    const response = await new AuthenticatedApi(
      auth.token
    ).getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
      params.orgCode,
      params.storeCode,
      params.tenantCode
    )
    return response.data
  },
  { serializeError }
)

export type patchPrintParams = {
  orgCode: string
  tenantId: string
}

export const patchTenantPrintedAt = createAsyncThunk<
  void,
  patchPrintParams,
  AppThunkConfig
>(
  'tenants/patchTenant',
  async (params, { getState }) => {
    const { auth } = getState()
    await new AuthenticatedApi(
      auth.token
    ).patchOrganizationsOrganizationCodeTenants(params.orgCode, [
      { id: params.tenantId, isQrPrinted: true },
    ])
  },
  { serializeError }
)

// 店舗のテナント一覧を取得
type GetStoreTenantsParams = {
  orgCode: string
  storeCode: string
  page?: number
  perPage: number
  q?: string
  status?: TenantStatusEnum
  trained?: boolean
}

interface GetStoreTenantsEntities {
  tenants: Record<string, NormalizedTenant>
}

interface GetStoreTenantsReturned {
  entities: GetStoreTenantsEntities
  totalCount: number
}

export const getStoreTenants = createAsyncThunk<
  GetStoreTenantsReturned,
  GetStoreTenantsParams,
  AppThunkConfig
>(
  'tenants/getStoreTenants',
  async (params, { getState }) => {
    const { auth } = getState()
    const response = await new AuthenticatedApi(
      auth.token
    ).getOrganizationsOrganizationCodeStoresStoreCodeTenants(
      params.orgCode,
      params.storeCode,
      params.page,
      params.perPage,
      params.q,
      params.status,
      true, // NOTE: OCRの有無を指定するパラメータだが、全て True で固定
      params.trained
    )

    const totalCount = getTotalCount(response)
    if (totalCount === undefined) {
      // TODO Error handling
      const error = { message: '' }
      throw error
    }

    const normalized = normalize<NormalizedTenant, GetStoreTenantsEntities>(
      response.data,
      [tenantEntity]
    )
    return {
      entities: normalized.entities,
      totalCount,
    }
  },
  { serializeError }
)

// 組織のテナント一覧を取得
type GetOrgTenantsParams = {
  orgCode: string
  page: number
  perPage: number
  q?: string
  status?: TenantStatusEnum
  isOcrRequired?: boolean
  trained?: boolean
}

interface GetOrgTenantsEntities {
  tenants: Record<string, NormalizedTenant>
  stores: Record<string, Store>
}

interface GetOrgTenantsReturned {
  entities: GetOrgTenantsEntities
  totalCount: number
}

export const getOrgTenants = createAsyncThunk<
  GetOrgTenantsReturned,
  GetOrgTenantsParams,
  AppThunkConfig
>(
  'tenants/getOrgTenants',
  async (params, { getState }) => {
    const { auth } = getState()
    const response = await new AuthenticatedApi(
      auth.token
    ).getOrganizationsOrganizationCodeTenants(
      params.orgCode,
      params.page,
      params.perPage,
      params.q,
      params.status,
      true, // NOTE: OCRの有無を指定するパラメータだが、全て True で固定
      params.trained
    )

    const totalCount = getTotalCount(response)
    if (totalCount === undefined) {
      // TODO Error handling
      const error = { message: '' }
      throw error
    }

    const normalized = normalize<NormalizedTenant, GetOrgTenantsEntities>(
      response.data,
      [tenantEntity]
    )
    return {
      entities: normalized.entities,
      totalCount,
    }
  },
  { serializeError }
)

const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    revertTenantStatus: (state) => {
      return {
        ...state,
        status: 'idle',
      }
    },
    clearStoreTenants: (
      state,
      { payload }: PayloadAction<GetStoreTenantsParams>
    ): TenantState => {
      const key = toKey(payload)
      delete state.query[key]
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(getTenant.pending, (state, { meta }) => {
      const key = toKey(meta.arg)
      return {
        ...state,
        query: {
          ...state.query,
          [key]: {
            status: 'loading',
          },
        },
      }
    })
    builder.addCase(getTenant.fulfilled, (state, { meta, payload }) => {
      tenantsAdapter.upsertOne(state, payload)
      const key = toKey(meta.arg)
      state.codeToId[key] = payload.id
      state.query[key] = {
        status: 'succeeded',
      }
    })
    builder.addCase(getTenant.rejected, (state, { meta, error }) => {
      const key = toKey(meta.arg)
      return {
        ...state,
        query: {
          ...state.query,
          [key]: {
            status: 'failed',
            error,
          },
        },
      }
    })
    builder.addCase(patchTenantPrintedAt.pending, (state) => {
      return {
        ...state,
        status: 'loading',
      }
    })
    builder.addCase(patchTenantPrintedAt.fulfilled, () => {
      return {
        ...initialState,
        status: 'succeeded',
      }
    })
    builder.addCase(patchTenantPrintedAt.rejected, (state, { error }) => {
      return {
        ...state,
        status: 'failed',
        error,
      }
    })
    builder.addCase(getStoreTenants.pending, (state, { meta }) => {
      const key = toKey(meta.arg)
      return {
        ...state,
        query: {
          ...state.query,
          [key]: {
            status: 'loading',
          },
        },
      }
    })
    builder.addCase(getStoreTenants.fulfilled, (state, { meta, payload }) => {
      const { entities, totalCount } = payload
      const key = toKey(meta.arg)

      if (entities.tenants === undefined) {
        state.query[key] = {
          status: 'succeeded',
          totalCount,
        }
        return
      }

      tenantsAdapter.upsertMany(state, entities.tenants)

      Object.keys(entities.tenants).forEach((id) => {
        const indexCode: IndexCode = {
          orgCode: meta.arg.orgCode,
          storeCode: meta.arg.storeCode,
          tenantCode: entities.tenants[id].code,
        }
        state.codeToId[toKey(indexCode)] = id
      })

      state.query[key] = {
        status: 'succeeded',
        ids: Object.keys(entities.tenants),
        totalCount,
        currentPage: meta.arg.page,
        totalPage: Math.ceil(totalCount / meta.arg.perPage),
      }
    })
    builder.addCase(getStoreTenants.rejected, (state, { meta, error }) => {
      const key = toKey(meta.arg)
      return {
        ...state,
        query: {
          ...state.query,
          [key]: {
            status: 'failed',
            error,
          },
        },
      }
    })
    builder.addCase(getOrgTenants.pending, (state, { meta }) => {
      const key = toKey(meta.arg)
      return {
        ...state,
        query: {
          ...state.query,
          [key]: {
            status: 'loading',
          },
        },
      }
    })
    builder.addCase(getOrgTenants.fulfilled, (state, { meta, payload }) => {
      const { entities, totalCount } = payload
      const key = toKey(meta.arg)

      if (entities.tenants === undefined) {
        state.query[key] = {
          status: 'succeeded',
          totalCount,
        }
        return
      }

      tenantsAdapter.upsertMany(state, entities.tenants)

      Object.keys(entities.tenants).forEach((id) => {
        const storeId = entities.tenants[id].store
        if (!storeId) {
          return
        }
        const indexCode: IndexCode = {
          orgCode: meta.arg.orgCode,
          storeCode: entities.stores[storeId].code,
          tenantCode: entities.tenants[id].code,
        }
        state.codeToId[toKey(indexCode)] = id
      })

      state.query[key] = {
        status: 'succeeded',
        ids: Object.keys(entities.tenants),
        totalCount,
        currentPage: meta.arg.page,
        totalPage: Math.ceil(totalCount / meta.arg.perPage),
      }
    })
    builder.addCase(getOrgTenants.rejected, (state, { meta, error }) => {
      const key = toKey(meta.arg)
      return {
        ...state,
        query: {
          ...state.query,
          [key]: {
            status: 'failed',
            error,
          },
        },
      }
    })
    builder.addCase(getSalesReports.fulfilled, (state, { meta, payload }) => {
      const { tenants } = payload.entities
      if (tenants === undefined) {
        return state
      }

      tenantsAdapter.upsertMany(state, tenants)

      const { orgCode, storeCode } = meta.arg
      Object.values(tenants).forEach((tenant) => {
        const key = toKey({ orgCode, storeCode, tenantCode: tenant.code })
        state.codeToId[key] = tenant.id
      })
      return state
    })
    builder.addCase(
      getTenantSalesReportSummaries.fulfilled,
      (state, { meta, payload }) => {
        const { tenants } = payload.entities
        if (tenants === undefined) {
          return state
        }

        tenantsAdapter.upsertMany(state, tenants)

        const { orgCode, storeCode } = meta.arg

        Object.keys(tenants).forEach((id) => {
          const indexCode: IndexCode = {
            orgCode,
            storeCode,
            tenantCode: tenants[id].code,
          }
          state.codeToId[toKey(indexCode)] = id
        })
        return state
      }
    )
    builder.addCase(
      getTenantSalesReports.fulfilled,
      (state, { meta, payload }) => {
        const { tenant } = payload

        tenantsAdapter.upsertOne(state, tenant)
        const key = toKey(meta.arg)
        state.codeToId[key] = tenant.id
        state.query[key] = {
          status: 'succeeded',
        }
      }
    )
  },
})

export const { revertTenantStatus, clearStoreTenants } = tenantsSlice.actions
export default tenantsSlice.reducer

export const {
  selectById: selectTenantById,
  selectEntities: selectTenantEntities,
  selectAll: selectAllTenants,
} = tenantsAdapter.getSelectors<TenantState>((state) => state)

export const selectTenantByCode = (
  code: IndexCode
): Selector<RootState, NormalizedTenant | undefined> => {
  return createSelector([(state) => state.entities.tenants], (state) => {
    const key = toKey(code)
    const tenantId = state.codeToId[key]
    return selectTenantById(state, tenantId)
  })
}

export const selectTenantStateByCode = (
  code: IndexCode
): Selector<RootState, RequestState> => {
  return createSelector([(state) => state.entities.tenants], (state) => {
    return (
      state.query[toKey(code)] ?? {
        status: 'idle',
      }
    )
  })
}

export const selectTenantsByParams = (
  params: GetStoreTenantsParams
): Selector<RootState, NormalizedTenant[] | undefined> => {
  const key = toKey(params)
  return createSelector([(state) => state.entities.tenants], (state) => {
    if (!state.query[key]) {
      return undefined
    }
    const entities = selectTenantEntities(state)
    return state.query[key].ids
      ?.map((id: any) => entities[id]) // eslint-disable-line @typescript-eslint/no-explicit-any
      .filter((tenant: any): tenant is NormalizedTenant => tenant !== undefined) // eslint-disable-line @typescript-eslint/no-explicit-any
  })
}

export const selectTenantStateByParams = (
  params: GetStoreTenantsParams | GetOrgTenantsParams
): Selector<RootState, PaginatedRequestState> => {
  return createSelector([(state) => state.entities.tenants], (state) => {
    return (
      state.query[toKey(params)] ?? {
        status: 'idle',
      }
    )
  })
}

export const selectOrgTenantsByParams = (
  params: GetOrgTenantsParams
): Selector<RootState, OrgTenant[] | undefined> => {
  return createSelector(
    [
      (state) => state.entities.tenants,
      (state) => state.entities.stores.entities,
    ],
    (state, storesEntities) => {
      const tenantIds = state.query[toKey(params)]?.ids
      if (!tenantIds) {
        return undefined
      }
      return denormalize(tenantIds, [tenantEntity], {
        tenants: state.entities,
        stores: storesEntities,
      })
    }
  )
}
