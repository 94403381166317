import React from 'react'
import { SubmitHandler } from 'react-hook-form'
import { Box } from '@mui/material'
import styles from './styles.module.scss'
import LoginForm, { LoginData } from '../../organisms/LoginForm'
import Icon from '../../atoms/Icon'

export type LoginProps = {
  disabledSubmit?: boolean
  onSubmit: SubmitHandler<LoginData>
  isEmailExistence?: boolean
}

const Login: React.FC<LoginProps> = ({
  disabledSubmit,
  onSubmit,
  isEmailExistence,
}: LoginProps) => {
  return (
    <Box className={styles.root}>
      <Box className={styles.body}>
        <Icon icon="logo" className={styles.logo} />
        <LoginForm
          className={styles.form}
          disabledSubmit={disabledSubmit}
          onSubmit={onSubmit}
          isEmailExistence={isEmailExistence}
        />
      </Box>
    </Box>
  )
}

export default Login
