import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import BreadcrumbsPipe, { Path } from '../../molecules/BreadcrumbsPipe'
import { Cell as HeaderCell } from '../../molecules/CalculationTableHeaderRow'
import CenteringLoading from '../../molecules/CenteringLoading'
import Modal from '../../molecules/Modal'
import { Option } from '../../molecules/SelectWithOption'
import styles from './styles.module.scss'
import EditOCRFormatTable, { BodyRow } from '../../organisms/EditOCRFormatTable'

export type EditOCRFormatProps = {
  breadcrumbs: Path[]
  isLoading: boolean
  columns: HeaderCell[]
  rows: BodyRow[]
  showModal: boolean
  onClickGoForward: () => void
  onClickGoBack: () => void
  onClickClose: () => void
  onClickConfirmClose: () => void
  onClickCancelClose: () => void
  onChangeSelect: (option: Option, rowIndex: number, name: string) => void
}

const EditOCRFormat: React.StyledFC<EditOCRFormatProps> = ({
  breadcrumbs,
  isLoading,
  columns,
  rows,
  showModal,
  onClickGoForward,
  onClickGoBack,
  onClickClose,
  onClickConfirmClose,
  onClickCancelClose,
  onChangeSelect,
}: EditOCRFormatProps) => {
  const renderContent = () => {
    return (
      <Box className={styles.content}>
        <Typography className={styles.title} variant="h1">
          {`レシート読み取りの準備です。\n各項目の数値の詳細情報を設定しましょう。`}
        </Typography>
        <EditOCRFormatTable
          className={styles.table}
          columns={columns}
          rows={rows}
          onChangeSelect={onChangeSelect}
        />
        <Box className={styles.buttonWrapper}>
          <Button
            title="つぎへ"
            onClick={onClickGoForward}
            color="primary"
            size="large"
          />
          <Typography
            color="primary"
            className={styles.previousTypography}
            onClick={onClickGoBack}
            component="a"
            variant="subtitle1"
          >
            前ページに戻る
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box className={styles.root}>
      <Box className={styles.header} component="header">
        <BreadcrumbsPipe className={styles.breadcrumb} paths={breadcrumbs} />
        <Box className={styles.closeButtonWrapper}>
          <IconButton
            className={styles.closeButton}
            onClick={onClickClose}
            size="large"
          >
            <Icon icon="close" />
          </IconButton>
        </Box>
      </Box>
      <Box className={styles.contentWrapper}>
        {isLoading ? <CenteringLoading /> : renderContent()}
        <Modal // `disableBackdropClick` is removed by codemod.
          // You can find more details about this breaking change in [the migration guide](https://mui.com/guides/migration-v4/#modal)

          open={showModal}
          title={`今中断するとこれまでの設定内容は\n失われてしまいますがよろしいですか？`}
          agreeButtonTitle="このまま中断する"
          disagreeButtonTitle="設定に戻る"
          onClickAgree={onClickConfirmClose}
          onClickDisagree={onClickCancelClose}
        />
      </Box>
    </Box>
  )
}

export default EditOCRFormat
